<template>
<div class="container mt-5 mb-5">
    <div class="card main-card-form pb-4">
        <div v-if="!showSearch">
            <form>
                <div class="row justify-content-center gx-3 gy-0 pt-2">
                    <div class="col col-sm-8">
                        <BaseInputEnter label="Enter Ticker (e.g. AAPL or aapl)" v-model="tickerName" @focus="tickerName=''; warningMsg='';" />
                    </div>
                    <div class="col-3 col-sm-2">
                        <button type="submit" @click.prevent="calledByGetFin=false; showOptional = false; showCalculations = false; checkFinancials(tickerName)" class="btn custom-btn-form get-btn1 btn3d btn-primar">Get Data</button>
                    </div>
                </div>
            </form>

            <div class="row justify-content-center search-toggle">
                <div class="col col-sm-10">
                    Not sure about the ticker? <span @click.prevent="showFinancials=false; showSearch=true; showEnterTicker=false; nameSearch=''; searchWarn=''; warningMsg=''; tickerName=''; assetName='';">&nbsp;&nbsp;<u><b>Search</b></u></span>
                </div>
            </div>
        </div>
        <div v-else>
            <form>
                <div class="row justify-content-center gx-3 gy-0 pt-2">
                    <div class="col col-sm-8">
                        <BaseInputEnter label="Search stock" v-model="nameSearch" @focus="searchWarn=''; nameSearch=''; this.tickers=[]; warningMsg='';" />
                    </div>
                    <div class="col-3 col-sm-2">
                        <button type="submit" @click.prevent="showFinancials=false; searchApi()" class="btn custom-btn-form src-btn btn3d btn-primar">Search</button>
                    </div>
                </div>

                <div v-if="searchWarn" class="row justify-content-center mt-2">
                    <div class="col col-sm-10 search-warn">
                        {{searchWarn}}
                    </div>
                </div>
            </form>

            <div class="row justify-content-center gx-2 gy-0 mt-3">
                <div class="col col-sm-8">
                    <!-- <BaseInputSelect label="Select stock" v-model="assetName" :options="tickers" @change="calledByGetFin=false; checkFinancials($event.target.value);" /> -->
                    <BaseInputSelect label="Select stock" v-model="assetName" :options="tickers" @change="calledByGetFin=false; " />
                </div>
                <div class="col-3 col-sm-2">
                    <button type="submit" @click.prevent="calledByCheckFin=false; showOptional = false; showCalculations = false; getFinancials(assetName);" class="btn custom-btn-form get-btn btn3d btn-primar">Get Data</button>
                </div>
            </div>
            <div class="row justify-content-center search-toggle">
                <div class="col col-sm-10">
                    Stock not in the list? <span @click.prevent="showFinancials=false; showSearch=false; showEnterTicker=false; tickerName=''; searchWarn=''; warningMsg=''; assetName='';">&nbsp;&nbsp;<u><b>Enter ticker</b></u></span>
                </div>
            </div>
        </div>
        <div v-if="warningMsg" class="row justify-content-center comment-loading">
            <div class="col col-sm-10 comment-col">
                {{ warningMsg }}
            </div>
        </div>
    </div>

    <div v-show="this.showFinancials">
        <!-- <div> -->
        <div class="row justify-content-center gx-4 top-ticker-row mt-2">
            <div class="col-xs-6 col-sm-5">
                <div class="row">
                    <div v-if="yfDATA && !yfCall2" class="col-8 col-item">
                        <b>Ticker:</b>
                    </div>
                    <div v-else-if="yfDATA && yfCall2" class="col-8 col-item">
                        <b>Ticker</b>:
                    </div>
                    <div v-else class="col-8 col-item">
                        Ticker:
                    </div>
                    <div class="col col-curr">
                        &nbsp;&nbsp;<b>{{ symbol }}</b>
                    </div>

                </div>
            </div>
        </div>
        <div class="row justify-content-center gx-4 currency-row">
            <div class="col-xs-6 col-sm-5">
                <div class="row">
                    <div class="col-3 col-xs-4 col-sm-4 col-item">
                        Name:
                    </div>
                    <div v-if="mbQU" class="col col-name">
                        <b>{{ shortName }}</b>
                    </div>
                    <div v-else class="col col-name">
                        {{ shortName }}
                    </div>
                    <div class="col-1 col-sm-2">

                    </div>
                </div>
            </div>
        </div>
        <div class="row justify-content-center gx-4 currency-row">
            <div class="col-xs-6 col-sm-5">
                <div class="row">
                    <div class="col-8 col-item">
                        Exchange:
                    </div>
                    <div v-if="mbBS" class="col col-curr">
                        &nbsp;&nbsp;<b>{{ exchange }}</b>
                    </div>
                    <div v-else class="col col-curr">
                        &nbsp;&nbsp;{{ exchange }}
                    </div>
                </div>
            </div>
        </div>
        <div class="row justify-content-center gx-4 currency-row">
            <div class="col-xs-6 col-sm-5">
                <div class="row">
                    <div class="col-8 col-item">
                        TTM, ending:
                    </div>
                    <div v-if="mbIS" class="col col-curr">
                        &nbsp;&nbsp;<b>{{ TTMDate }}</b>
                    </div>
                    <div v-else class="col col-curr">
                        &nbsp;&nbsp;{{ TTMDate }}
                    </div>
                </div>
            </div>
        </div>

        <div class="row justify-content-center gx-4 currency-row">
            <div class="col-xs-6 col-sm-5">
                <div class="row">
                    <div class="col-8 col-item">
                        Financial currency:
                    </div>
                    <div v-if="mbCF" class="col col-curr">
                        &nbsp;&nbsp;<b>{{ financialCurrency }} (MM)</b>
                    </div>
                    <div v-else class="col col-curr">
                        &nbsp;&nbsp;{{ financialCurrency }} (MM)
                    </div>
                </div>
            </div>
        </div>

        <div class="card main-card-form py-1 mt-2">
            <div class="row justify-content-center gx-4 data-row mt-1">
                <div class="col-xs-6 col-sm-5">
                    <div class="row">
                        <div class="col-8 col-item">
                            Cash:
                        </div>
                        <div class="col col-data">
                            <BaseInputEnter v-model="cash2" type="text" @drop.prevent="false" @paste.prevent="false" @keyup="(event) => checkNum(event)" @blur="validInput($event.target.value); calculateValue()" class="fin-box" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center gx-4 data-row">
                <div class="col-xs-6 col-sm-5">
                    <div class="row">
                        <div class="col-8 col-item">
                            Current Assets:
                        </div>
                        <div class="col col-data">
                            <BaseInputEnter v-model="currentAssets2" type="text" @drop.prevent="false" @paste.prevent="false" @keyup="(event) => checkNum(event)" @blur="validInput($event.target.value); prefillParameters();" class="fin-box" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center gx-4 data-row">
                <div class="col-xs-6 col-sm-5">
                    <div class="row">
                        <div class="col-8 col-item">
                            Total Assets:
                        </div>
                        <div class="col col-data">
                            <BaseInputEnter v-model="totalAssets2" type="text" @drop.prevent="false" @paste.prevent="false" @keyup="(event) => checkNum(event)" @blur="validInput($event.target.value); prefillParameters();" class="fin-box" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center gx-4 data-row ">
                <div class="col-xs-6 col-sm-5">
                    <div class="row">
                        <div class="col-8 col-item">
                            Current Liabilities:
                        </div>
                        <div class="col col-data">
                            <BaseInputEnter v-model="currentLiabilities2" type="text" @drop.prevent="false" @paste.prevent="false" @keyup="(event) => checkNum(event)" @blur="validInput($event.target.value); calculateValue()" class="fin-box" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center gx-4 data-row">
                <div class="col-xs-6 col-sm-5">
                    <div class="row">
                        <div class="col-8 col-item">
                            Total Liabilities:
                        </div>
                        <div class="col col-data">
                            <BaseInputEnter v-model="totalLiabilities2" type="text" @drop.prevent="false" @paste.prevent="false" @keyup="(event) => checkNum(event)" @blur="validInput($event.target.value); prefillParameters();" class="fin-box" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center gx-4 data-row mb-2">
                <div class="col-xs-6 col-sm-5">
                    <div class="row">
                        <div class="col-8 col-item">
                            Stockholders Equity:
                        </div>
                        <div class="col col-data">
                            <BaseInputEnter v-model="stockholderEquity2" type="text" @drop.prevent="false" @paste.prevent="false" @keyup="(event) => checkNum(event)" @blur="validInput($event.target.value); calculateValue()" class="fin-box" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="row ">
                <div class="divider sep-line"></div>
            </div>
            <div class="row justify-content-center gx-4 data-row mt-2">
                <div class="col-xs-6 col-sm-5">
                    <div class="row">
                        <div class="col-8 col-item">
                            Revenues:
                        </div>
                        <div class="col col-data">
                            <BaseInputEnter v-model="totalRevenue2" type="text" @drop.prevent="false" @paste.prevent="false" @keyup="(event) => checkNum(event)" @blur="validInput($event.target.value); prefillParameters();" class="fin-box" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center gx-4 data-row">
                <div class="col-xs-6 col-sm-5">
                    <div class="row">
                        <div class="col-8 col-item">
                            Gross Profit:
                        </div>
                        <div class="col col-data">
                            <BaseInputEnter v-model="grossProfit2" type="text" @drop.prevent="false" @paste.prevent="false" @keyup="(event) => checkNum(event)" @blur="validInput($event.target.value); calculateValue()" class="fin-box" />
                        </div>
                    </div>
                </div>
            </div>

            <div class="row justify-content-center gx-4 data-row">
                <div class="col-xs-6 col-sm-5">
                    <div class="row">
                        <div class="col-8 col-item">
                            Operating Income:
                        </div>
                        <div class="col col-data">
                            <BaseInputEnter v-model="operatingIncome2" type="text" @drop.prevent="false" @paste.prevent="false" @keyup="(event) => checkNum(event)" @blur="validInput($event.target.value); calculateValue()" class="fin-box" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center gx-4 data-row">
                <div class="col-xs-6 col-sm-5">
                    <div class="row">
                        <div class="col-8 col-item">
                            Net Income to c. shares:
                        </div>
                        <div class="col col-data">
                            <BaseInputEnter v-model="netIncomeCommonShares2" type="text" @drop.prevent="false" @paste.prevent="false" @keyup="(event) => checkNum(event)" @blur="validInput($event.target.value); calculateValue()" class="fin-box" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center gx-4 data-row mb-2">
                <div class="col-xs-6 col-sm-5">
                    <div class="row">
                        <div class="col-8 col-item">
                            Depreciation:
                        </div>
                        <div class="col col-data">
                            <BaseInputEnter v-model="depreciation2" type="text" @drop.prevent="false" @paste.prevent="false" @keyup="(event) => checkNum(event)" @blur="validInput($event.target.value); calculateValue()" class="fin-box" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="row ">
                <div class="divider sep-line"></div>
            </div>
            <div class="row justify-content-center gx-4 data-row mt-2 mb-1">
                <div class="col-xs-6 col-sm-5">
                    <div class="row">
                        <div class="col-8 col-item">
                            Shares Outstanding (MM):
                        </div>
                        <div class="col col-data">
                            <BaseInputEnter v-model="sharesOutstanding2" type="text" @drop.prevent="false" @paste.prevent="false" @keyup="(event) => checkNum(event)" @blur="validInput($event.target.value); calculateValue()" class="fin-box" />
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <div class="row justify-content-center gx-4 ticker-row mt-3 mb-1">
            <div class="col-xs-6 col-sm-5">
                <div class="row">
                    <div class="col-8 col-item">
                        Key indicators (TTM):
                    </div>
                    <div class="col col-data">

                    </div>

                </div>
            </div>
        </div>

        <div class="card main-card-form py-1">
            <div class="row justify-content-center gx-4 data-row mt-1">
                <div class="col-xs-6 col-sm-5">
                    <div class="row">
                        <div class="col-8 col-item">
                            Market Cap ({{priceCurrency}} MM):
                        </div>
                        <div class="col col-data">
                            <BaseInputEnter v-model="this.marketCap" type="text" class="fin-box" disabled />
                        </div>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center gx-4 data-row">
                <div class="col-xs-6 col-sm-5">
                    <div class="row">
                        <div class="col-8 col-item">
                            Net Debt ({{financialCurrency}} MM):
                        </div>
                        <div class="col col-data">
                            <BaseInputEnter v-model="netDebtIndicator" type="text" class="fin-box" disabled />
                        </div>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center gx-4 data-row ">
                <div class="col-xs-6 col-sm-5">
                    <div class="row">
                        <div class="col-8 col-item">
                            Net Working Cap. ({{financialCurrency}} MM):
                        </div>
                        <div class="col col-data">
                            <BaseInputEnter v-model="netWorkingCap" type="text" class="fin-box" disabled />
                        </div>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center gx-4 data-row">
                <div class="col-xs-6 col-sm-5">
                    <div class="row">
                        <div class="col-8 col-item">
                            P/E ratio:
                        </div>
                        <div class="col col-data">
                            <BaseInputEnter v-model="PeRatio" type="text" class="fin-box" disabled />
                        </div>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center gx-4 data-row">
                <div class="col-xs-6 col-sm-5">
                    <div class="row">
                        <div class="col-8 col-item">
                            EV/EBITDA multiple:
                        </div>
                        <div class="col col-data">
                            <BaseInputEnter v-model="EbitdaRatio" type="text" class="fin-box" disabled />
                        </div>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center gx-4 data-row">
                <div class="col-xs-6 col-sm-5">
                    <div class="row">
                        <div class="col-8 col-item">
                            Gross Margin:
                        </div>
                        <div class="col col-data">
                            <BaseInputEnter v-model="grossMargin" type="text" class="fin-box" disabled />
                        </div>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center gx-4 data-row">
                <div class="col-xs-6 col-sm-5">
                    <div class="row">
                        <div class="col-8 col-item">
                            Operating Margin:
                        </div>
                        <div class="col col-data">
                            <BaseInputEnter v-model="operatingMargin" type="text" class="fin-box" disabled />
                        </div>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center gx-4 data-row">
                <div class="col-xs-6 col-sm-5">
                    <div class="row">
                        <div class="col-8 col-item">
                            Profit Margin:
                        </div>
                        <div class="col col-data">
                            <BaseInputEnter v-model="profitMargin" type="text" class="fin-box" disabled />
                        </div>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center gx-4 data-row">
                <div class="col-xs-6 col-sm-5">
                    <div class="row">
                        <div class="col-8 col-item">
                            ROIC:
                        </div>
                        <div class="col col-data">
                            <BaseInputEnter v-model="roic" type="text" class="fin-box" disabled />
                        </div>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center gx-4 data-row">
                <div class="col-xs-6 col-sm-5">
                    <div class="row">
                        <div class="col-8 col-item">
                            ROE:
                        </div>
                        <div class="col col-data">
                            <BaseInputEnter v-model="roe" type="text" class="fin-box" disabled />
                        </div>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center gx-4 data-row">
                <div class="col-xs-6 col-sm-5">
                    <div class="row">
                        <div class="col-8 col-item">
                            Invested Cap / Revenues:
                        </div>
                        <div class="col col-data">
                            <BaseInputEnter v-model="investedCapToRev" type="text" class="fin-box" disabled />
                        </div>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center gx-4 data-row">
                <div class="col-xs-6 col-sm-5">
                    <div class="row">
                        <div class="col-8 col-item">
                            Net Debt / Invested Cap:
                        </div>
                        <div class="col col-data">
                            <BaseInputEnter v-model="netDebtToInvestedCap" type="text" class="fin-box" disabled />
                        </div>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center gx-4 data-row">
                <div class="col-xs-6 col-sm-5">
                    <div class="row">
                        <div class="col-8 col-item">
                            Short Ratio (avg. days):
                        </div>
                        <div class="col col-data">
                            <BaseInputEnter v-model="shortDays" type="text" class="fin-box" disabled />
                        </div>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center gx-4 data-row mb-1">
                <div class="col-xs-6 col-sm-5">
                    <div class="row">
                        <div class="col-8 col-item">
                            Short Interest as % of Float:
                        </div>
                        <div class="col col-data">
                            <BaseInputEnter v-model="shortFloat" type="text" class="fin-box" disabled />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row g-0 row-separator1">
            <div class="col col-divider-line1">
                <div class="divider sep-line-light"></div>
            </div>
            <div class="col-8 col-sm-2 col-divider-text">
                <b>VALUATION PARAMETERS</b>
            </div>
            <div class="col col-divider-line2">
                <div class="divider sep-line-light"></div>
            </div>
        </div>

        <div class="row justify-content-center gx-4 ticker-row mt-3 mb-1">
            <div class="col-xs-6 col-sm-5">
                <div class="row">
                    <div class="col-8 col-item">
                        Common Parameters:
                    </div>
                    <div class="col col-data">
                    </div>
                </div>
            </div>
        </div>
        <div class="card main-card-form py-1">
            <!-- <div class="row justify-content-center gx-4 data-row mt-1">
                <div class="col-xs-6 col-sm-5">
                    <div class="row">
                        <div class="col-8 col-item">
                            10Yr Risk-free Yield p.a. (%):
                        </div>
                        <div class="col col-data">
                            <BaseInputEnter v-model="TreasuryYield" type="number" step=0.01 @drop.prevent="false" @paste.prevent="false" @focus="tempValue=TreasuryYield; TreasuryYield=''" @keyup="(event) => checkNum(event)" @blur="(TreasuryYield=='') ? (TreasuryYield=tempValue) : validInput($event.target.value)" class="fin-box" />
                        </div>
                    </div>
                </div>
            </div> -->
            <div class="row justify-content-center gx-4 data-row">
                <div class="col-xs-6 col-sm-5">
                    <div class="row">
                        <div class="col-8 col-item">
                            Corporate Tax Rate (%):
                        </div>
                        <div class="col col-data">
                            <BaseInputEnter v-model="taxRate" type="number" step=0.01 @drop.prevent="false" @paste.prevent="false" @focus="tempValue=taxRate; taxRate=''" @keyup="(event) => checkNum(event)" @blur="(taxRate=='') ? (taxRate=tempValue) : validInput($event.target.value); calculateValue();" class="fin-box" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center gx-4 data-row">
                <div class="col-xs-6 col-sm-5">
                    <div class="row">
                        <div class="col-8 col-item">
                            Av. Borrowing Cost (%):
                        </div>
                        <div class="col col-data">
                            <!-- <BaseInputEnter v-model="borrowRateVsTreasury" type="number" step=0.01 @drop.prevent="false" @paste.prevent="false" @focus="tempValue=borrowRateVsTreasury; borrowRateVsTreasury=''" @keyup="(event) => checkNum(event)" @blur="(borrowRateVsTreasury=='') ? (borrowRateVsTreasury=tempValue) : validInput($event.target.value)" class="fin-box" /> -->
                            <BaseInputEnter v-model="avBorrowRate" type="number" step=0.01 @drop.prevent="false" @paste.prevent="false" @focus="tempValue=avBorrowRate; avBorrowRate=''" @keyup="(event) => checkNum(event)" @blur="(avBorrowRate=='') ? (avBorrowRate=tempValue) : validInput($event.target.value); calculateValue()" class="fin-box" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center gx-4 data-row">
                <div class="col-xs-6 col-sm-5">
                    <div class="row">
                        <div class="col-8 col-item">
                            Discount Rate (%):
                        </div>
                        <div class="col col-data">
                            <!-- <BaseInputEnter v-model="discountRateVsTreasury" type="number" step=0.01 @drop.prevent="false" @paste.prevent="false" @focus="tempValue=discountRateVsTreasury; discountRateVsTreasury=''" @keyup="(event) => checkNum(event)" @blur="(discountRateVsTreasury=='') ? (discountRateVsTreasury=tempValue) : validInput($event.target.value)" class="fin-box" /> -->
                            <BaseInputEnter v-model="myDiscountRate" type="number" step=0.01 @drop.prevent="false" @paste.prevent="false" @focus="tempValue=myDiscountRate; myDiscountRate=''" @keyup="(event) => checkNum(event)" @blur="(myDiscountRate=='') ? (myDiscountRate=tempValue) : validInput($event.target.value); calculateValue()" class="fin-box" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center gx-4 data-row">
                <div class="col-xs-6 col-sm-5">
                    <div class="row">
                        <div class="col-8 col-item">
                            Terminal EBITDA multiple:
                        </div>
                        <div class="col col-data">
                            <BaseInputEnter v-model="terminalEbitdaMult" type="number" step=0.01 @drop.prevent="false" @paste.prevent="false" @focus="tempValue=terminalEbitdaMult; terminalEbitdaMult=''" @keyup="(event) => checkNum(event)" @blur="(terminalEbitdaMult=='') ? (terminalEbitdaMult=tempValue) : validInput($event.target.value); calculateValue()" class="fin-box" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center gx-4 data-row  mb-1">
                <div class="col-xs-6 col-sm-5">
                    <div class="row">
                        <div class="col-8 col-item">
                            Terminal P/E multiple:
                        </div>
                        <div class="col col-data">
                            <BaseInputEnter v-model="terminalPEMult" type="number" step=0.01 @drop.prevent="false" @paste.prevent="false" @focus="tempValue=terminalPEMult; terminalPEMult=''" @keyup="(event) => checkNum(event)" @blur="(terminalPEMult=='') ? (terminalPEMult=tempValue) : validInput($event.target.value); calculateValue()" class="fin-box" />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row justify-content-center gx-4 input-row mb-1 mt-1">
            <div class="col">
                <div class="row">
                    <div class="col col-item">
                        Revenue growth p.a. (%):
                    </div>
                </div>
            </div>
        </div>
        <div class="card inputs-card">
            <div class="row justify-content-center gx-4">
                <div class="col col-history col-avg">
                    3Yr-Av
                </div>
                <div class="col col-history">
                    {{revYear[0]}}
                </div>
                <div class="col col-history">
                    {{revYear[1]}}
                </div>
                <div class="col col-history">
                    {{revYear[2]}}
                </div>
                <!-- <div class="col col-history">
                </div> -->
            </div>
            <div class="row justify-content-center gx-4">
                <div class="col col-history2 col-avg2">
                    <div v-if="!isNaN(revGrowth[4])">
                        {{revGrowth[4]}}
                    </div>
                    <div v-else>
                        n.a.
                    </div>
                </div>
                <div class="col col-history2">
                    <div v-if="!isNaN(revGrowth[1]) && revGrowth[1] >= -99">
                        {{revGrowth[1]}}
                    </div>
                    <div v-else>
                        n.a.
                    </div>
                </div>
                <div class="col col-history2">
                    <div v-if="!isNaN(revGrowth[2]) && revGrowth[2] >= -99">
                        {{revGrowth[2]}}
                    </div>
                    <div v-else>
                        n.a.
                    </div>
                </div>
                <div class="col col-history2">
                    <div v-if="!isNaN(revGrowth[3]) && revGrowth[3] >= -99">
                        {{revGrowth[3]}}
                    </div>
                    <div v-else>
                        n.a.
                    </div>
                </div>
                <!-- <div class="col col-history2">
                </div> -->
            </div>
            <div class="divider sep-line-history "></div>
            <div class="row justify-content-center gx-4">
                <div class="col">
                    <label class="inp-label d-flex justify-content-center">Year 1</label>
                    <BaseInputEnter v-model="rvG[1]" type="number" step=0.01 @drop.prevent="false" @focus="tempValue=rvG[1]; rvG[1]=''" @keyup="(event) => checkNum2(event, 'rvG')" @blur="(rvG[1]=='') ? (rvG[1]=tempValue) : validInput($event.target.value); fillRight('rvG'); showCalculations=false; calculateValue()" @paste.prevent="false" class="inp-box" />
                </div>
                <div class="col">
                    <label class="inp-label d-flex justify-content-center">Year 5</label>
                    <BaseInputEnter v-model="rvG[5]" type="number" step=0.01 @drop.prevent="false" @focus="tempValue=rvG[5]; rvG[5]=''" @keyup="(event) => checkNum(event)" @blur="(rvG[5]=='') ? (rvG[5]=tempValue) : validInput($event.target.value); showCalculations=false; calculateValue()" @paste.prevent="false" class="inp-box" />
                </div>
                <div class="col">
                    <label class="inp-label d-flex justify-content-center">Year 10</label>
                    <BaseInputEnter v-model="rvG[10]" type="number" step=0.01 @drop.prevent="false" @focus="tempValue=rvG[10]; rvG[10]=''" @keyup="(event) => checkNum(event)" @blur="(rvG[10]=='') ? (rvG[10]=tempValue) : validInput($event.target.value); showCalculations=false; calculateValue()" @paste.prevent="false" class="inp-box" />
                </div>
                <div class="col">
                    <label class="inp-label d-flex justify-content-center">Perp.</label>
                    <BaseInputEnter v-model="rvG[11]" type="number" step=0.01 @drop.prevent="false" @focus="tempValue=rvG[11]; rvG[11]=''" @keyup="(event) => checkNum(event)" @blur="(rvG[11]=='') ? (rvG[11]=tempValue) : validInput($event.target.value); showCalculations=false; calculateValue()" @paste.prevent="false" class="inp-box" />
                </div>
            </div>
        </div>

        <div class="row justify-content-center gx-4 input-row mb-1 mt-1">
            <div class="col">
                <div class="row">
                    <div class="col col-item">
                        Operating margin (%):
                    </div>
                </div>
            </div>
        </div>
        <div class="card inputs-card">
            <div class="row justify-content-center gx-2">
                <div class="col col-history col-avg">
                    4Yr-Avg
                </div>
                <div class="col col-history">
                    {{revYear[0]}}
                </div>
                <div class="col col-history">
                    {{revYear[1]}}
                </div>
                <div class="col col-history">
                    {{revYear[2]}}
                </div>
                <div class="col col-history">
                    {{revYear[3]}}
                </div>
            </div>
            <div class="row justify-content-center gx-2">
                <div class="col col-history2 col-avg2">
                    <div v-if="!isNaN(opMarg[4])">
                        {{opMarg[4]}}
                    </div>
                    <div v-else>
                        n.a.
                    </div>
                </div>
                <div class="col col-history2">
                    <div v-if="!isNaN(opMarg[0])">
                        {{opMarg[0]}}
                    </div>
                    <div v-else>
                        n.a.
                    </div>
                </div>
                <div class="col col-history2">
                    <div v-if="!isNaN(opMarg[1])">
                        {{opMarg[1]}}
                    </div>
                    <div v-else>
                        n.a.
                    </div>
                </div>
                <div class="col col-history2">
                    <div v-if="!isNaN(opMarg[2])">
                        {{opMarg[2]}}
                    </div>
                    <div v-else>
                        n.a.
                    </div>
                </div>
                <div class="col col-history2">
                    <div v-if="!isNaN(opMarg[3])">
                        {{opMarg[3]}}
                    </div>
                    <div v-else>
                        n.a.
                    </div>
                </div>
            </div>

            <div class="divider sep-line-history "></div>

            <div class="row justify-content-center gx-4">
                <div class="col">
                    <label class="inp-label d-flex justify-content-center">Year 1</label>
                    <BaseInputEnter v-model="opM[1]" type="number" step=0.01 @drop.prevent="false" @focus="tempValue=opM[1]; opM[1]=''" @keyup="(event) => checkNum2(event, 'opM')" @blur="(opM[1]=='') ? (opM[1]=tempValue) : validInput($event.target.value); fillRight('opM'); showCalculations=false; calculateValue()" @paste.prevent="false" class="inp-box" />
                </div>
                <div class="col">
                    <label class="inp-label d-flex justify-content-center">Year 5</label>
                    <BaseInputEnter v-model="opM[5]" type="number" step=0.01 @drop.prevent="false" @focus="tempValue=opM[5]; opM[5]=''" @keyup="(event) => checkNum(event)" @blur="(opM[5]=='') ? (opM[5]=tempValue) : validInput($event.target.value); showCalculations=false; calculateValue()" @paste.prevent="false" class="inp-box" />
                </div>
                <div class="col">
                    <label class="inp-label d-flex justify-content-center">Year 10</label>
                    <BaseInputEnter v-model="opM[10]" type="number" step=0.01 @drop.prevent="false" @focus="tempValue=opM[10]; opM[10]=''" @keyup="(event) => checkNum(event)" @blur="(opM[10]=='') ? (opM[10]=tempValue) : validInput($event.target.value); showCalculations=false; calculateValue()" @paste.prevent="false" class="inp-box" />
                </div>
                <div class="col">
                    <label class="inp-label d-flex justify-content-center">Perp.</label>
                    <BaseInputEnter v-model="opM[11]" type="number" step=0.01 @drop.prevent="false" @focus="tempValue=opM[11]; opM[11]=''" @keyup="(event) => checkNum(event)" @blur="(opM[11]=='') ? (opM[11]=tempValue) : validInput($event.target.value); showCalculations=false; calculateValue()" @paste.prevent="false" class="inp-box" />
                </div>
            </div>
        </div>
        <div class="row justify-content-center gx-4 input-row mb-1 mt-1">
            <div class="col">
                <div class="row">
                    <div class="col col-item">
                        Profit margin (%):
                    </div>
                </div>
            </div>
        </div>
        <div class="card inputs-card">
            <div class="row justify-content-center gx-2">
                <div class="col col-history col-avg">
                    4Yr-Av
                </div>
                <div class="col col-history">
                    {{revYear[0]}}
                </div>
                <div class="col col-history">
                    {{revYear[1]}}
                </div>
                <div class="col col-history">
                    {{revYear[2]}}
                </div>
                <div class="col col-history">
                    {{revYear[3]}}
                </div>
            </div>
            <div class="row justify-content-center gx-2">
                <div class="col col-history2 col-avg2">
                    <div v-if="!isNaN(profMarg[4])">
                        {{profMarg[4]}}
                    </div>
                    <div v-else>
                        n.a.
                    </div>
                </div>
                <div class="col col-history2">
                    <div v-if="!isNaN(profMarg[0])">
                        {{profMarg[0]}}
                    </div>
                    <div v-else>
                        n.a.
                    </div>
                </div>
                <div class="col col-history2">
                    <div v-if="!isNaN(profMarg[1])">
                        {{profMarg[1]}}
                    </div>
                    <div v-else>
                        n.a.
                    </div>
                </div>
                <div class="col col-history2">
                    <div v-if="!isNaN(profMarg[2])">
                        {{profMarg[2]}}
                    </div>
                    <div v-else>
                        n.a.
                    </div>
                </div>
                <div class="col col-history2">
                    <div v-if="!isNaN(profMarg[3])">
                        {{profMarg[3]}}
                    </div>
                    <div v-else>
                        n.a.
                    </div>
                </div>
            </div>
            <div class="divider sep-line-history "></div>
            <div class="row justify-content-center gx-4">
                <div class="col">
                    <label class="inp-label d-flex justify-content-center">Year 1</label>
                    <BaseInputEnter v-model="pfM[1]" type="number" step=0.01 @drop.prevent="false" @focus="tempValue=pfM[1]; pfM[1]=''" @keyup="(event) => checkNum2(event, 'pfM')" @blur="(pfM[1]=='') ? (pfM[1]=tempValue) : validInput($event.target.value); fillRight('pfM'); showCalculations=false; calculateValue()" @paste.prevent="false" class="inp-box" />
                </div>
                <div class="col">
                    <label class="inp-label d-flex justify-content-center">Year 5</label>
                    <BaseInputEnter v-model="pfM[5]" type="number" step=0.01 @drop.prevent="false" @focus="tempValue=pfM[5]; pfM[5]=''" @keyup="(event) => checkNum(event)" @blur="(pfM[5]=='') ? (pfM[5]=tempValue) : validInput($event.target.value); showCalculations=false; calculateValue()" @paste.prevent="false" class="inp-box" />
                </div>
                <div class="col">
                    <label class="inp-label d-flex justify-content-center">Year 10</label>
                    <BaseInputEnter v-model="pfM[10]" type="number" step=0.01 @drop.prevent="false" @focus="tempValue=pfM[10]; pfM[10]=''" @keyup="(event) => checkNum(event)" @blur="(pfM[10]=='') ? (pfM[10]=tempValue) : validInput($event.target.value); showCalculations=false; calculateValue()" @paste.prevent="false" class="inp-box" />
                </div>
                <div class="col">
                    <label class="inp-label d-flex justify-content-center">Perp.</label>
                    <BaseInputEnter v-model="pfM[11]" type="number" step=0.01 @drop.prevent="false" @focus="tempValue=pfM[11]; pfM[11]=''" @keyup="(event) => checkNum(event)" @blur="(pfM[11]=='') ? (pfM[11]=tempValue) : validInput($event.target.value); showCalculations=false; calculateValue()" @paste.prevent="false" class="inp-box" />
                </div>
            </div>
        </div>

        <div v-if="!showOptional" class="row justify-content-center gx-4 input-row mb-1 mt-2 optional-link1">
            <div class="col">
                <div class="row">
                    <div class="col col-item">
                        <a @click.prevent="showOptional=!showOptional"><u>Optional parameters</u></a>
                    </div>
                </div>
            </div>
        </div>
        <div v-else class="row justify-content-center gx-4 input-row mb-1 mt-2 optional-link2">
            <div class="col">
                <div class="row">
                    <div class="col col-item">
                        <a @click.prevent="showOptional=!showOptional"><u>Hide optional parameters</u></a>
                    </div>
                </div>
            </div>
        </div>

        <div v-show="showOptional">

            <div class="row justify-content-center gx-4 input-row mb-1 mt-1">
                <div class="col">
                    <div class="row">
                        <div class="col col-item">
                            Share dilution p.a. (%):
                        </div>
                    </div>
                </div>
            </div>
            <div class="card inputs-card">

                <div class="row justify-content-center gx-4">
                    <div class="col col-history col-avg">
                        3Yr-Av
                    </div>
                    <div class="col col-history">
                        {{revYear[0]}}
                    </div>
                    <div class="col col-history">
                        {{revYear[1]}}
                    </div>
                    <div class="col col-history">
                        {{revYear[2]}}
                    </div>
                    <!-- <div class="col col-history">
                </div> -->
                </div>
                <div class="row justify-content-center gx-4">
                    <div class="col col-history2 col-avg2">
                        <div v-if="!isNaN(sharesGrowth[4])">
                            {{sharesGrowth[4]}}
                        </div>
                        <div v-else>
                            n.a.
                        </div>
                    </div>
                    <div class="col col-history2">
                        <div v-if="!isNaN(sharesGrowth[1])">
                            {{sharesGrowth[1]}}
                        </div>
                        <div v-else>
                            n.a.
                        </div>
                    </div>
                    <div class="col col-history2">
                        <div v-if="!isNaN(sharesGrowth[2])">
                            {{sharesGrowth[2]}}
                        </div>
                        <div v-else>
                            n.a.
                        </div>
                    </div>
                    <div class="col col-history2">
                        <div v-if="!isNaN(sharesGrowth[3])">
                            {{sharesGrowth[3]}}
                        </div>
                        <div v-else>
                            n.a.
                        </div>
                    </div>
                    <!-- <div class="col col-history2">
                </div> -->
                </div>
                <div class="divider sep-line-history "></div>

                <div class="row justify-content-center gx-4">
                    <div class="col">
                        <label class="inp-label d-flex justify-content-center">Year 1</label>
                        <BaseInputEnter v-model="shD[1]" type="number" step=0.01 @drop.prevent="false" @focus="tempValue=shD[1]; shD[1]=''" @keyup="(event) => checkNum2(event, 'shD')" @blur="(shD[1]=='') ? (shD[1]=tempValue) : validInput($event.target.value); fillRight('shD'); showCalculations=false; calculateValue()" @paste.prevent="false" class="inp-box" />
                    </div>
                    <div class="col">
                        <label class="inp-label d-flex justify-content-center">Year 5</label>
                        <BaseInputEnter v-model="shD[5]" type="number" step=0.01 @drop.prevent="false" @focus="tempValue=shD[5]; shD[5]=''" @keyup="(event) => checkNum(event)" @blur="(shD[5]=='') ? (shD[5]=tempValue) : validInput($event.target.value); showCalculations=false; calculateValue()" @paste.prevent="false" class="inp-box" />
                    </div>
                    <div class="col">
                        <label class="inp-label d-flex justify-content-center">Year 10</label>
                        <BaseInputEnter v-model="shD[10]" type="number" step=0.01 @drop.prevent="false" @focus="tempValue=shD[10]; shD[10]=''" @keyup="(event) => checkNum(event)" @blur="(shD[10]=='') ? (shD[10]=tempValue) : validInput($event.target.value); showCalculations=false; calculateValue()" @paste.prevent="false" class="inp-box" />
                    </div>
                    <div class="col">
                        <label class="inp-label d-flex justify-content-center">Perp.</label>
                        <BaseInputEnter v-model="shD[11]" type="number" step=0.01 @drop.prevent="false" @focus="tempValue=shD[11]; shD[11]=''" @keyup="(event) => checkNum(event)" @blur="(shD[11]=='') ? (shD[11]=tempValue) : validInput($event.target.value); showCalculations=false; calculateValue()" @paste.prevent="false" class="inp-box" />
                    </div>
                </div>
            </div>

            <div class="row justify-content-center gx-4 input-row mb-1 mt-1">
                <div class="col">
                    <div class="row">
                        <div class="col col-item">
                            Reinvested Capital / New Revenues (%):
                        </div>
                    </div>
                </div>
            </div>
            <div class="card inputs-card">
                <div class="row justify-content-center gx-2">
                    <div class="col col-history col-avg">
                        4Yr-Av
                    </div>
                    <div class="col col-history">
                        {{revYear[0]}}
                    </div>
                    <div class="col col-history">
                        {{revYear[1]}}
                    </div>
                    <div class="col col-history">
                        {{revYear[2]}}
                    </div>
                    <div class="col col-history">
                        {{revYear[3]}}
                    </div>
                </div>
                <div class="row justify-content-center gx-2">
                    <div class="col col-history2 col-avg2">
                        <div v-if="!isNaN(ICtRev[4])">
                            {{ICtRev[4]}}
                        </div>
                        <div v-else>
                            n.a.
                        </div>
                    </div>
                    <div class="col col-history2">
                        <div v-if="!isNaN(ICtRev[0])">
                            {{ICtRev[0]}}
                        </div>
                        <div v-else>
                            n.a.
                        </div>
                    </div>
                    <div class="col col-history2">
                        <div v-if="!isNaN(ICtRev[1])">
                            {{ICtRev[1]}}
                        </div>
                        <div v-else>
                            n.a.
                        </div>
                    </div>
                    <div class="col col-history2">
                        <div v-if="!isNaN(ICtRev[2])">
                            {{ICtRev[2]}}
                        </div>
                        <div v-else>
                            n.a.
                        </div>
                    </div>
                    <div class="col col-history2">
                        <div v-if="!isNaN(ICtRev[3])">
                            {{ICtRev[3]}}
                        </div>
                        <div v-else>
                            n.a.
                        </div>
                    </div>
                </div>
                <div class="divider sep-line-history "></div>
                <div class="row justify-content-center gx-4">
                    <div class="col">
                        <label class="inp-label d-flex justify-content-center">Year 1</label>
                        <BaseInputEnter v-model="ICtR[1]" type="number" step=0.01 @drop.prevent="false" @focus="tempValue=ICtR[1]; ICtR[1]=''" @keyup="(event) => checkNum(event)" @blur="(ICtR[1]=='') ? (ICtR[1]=tempValue) : validInput($event.target.value); showCalculations=false; calculateValue()" @paste.prevent="false" class="inp-box" />
                    </div>
                    <div class="col">
                        <label class="inp-label d-flex justify-content-center">Year 5</label>
                        <BaseInputEnter v-model="ICtR[5]" type="number" step=0.01 @drop.prevent="false" @focus="tempValue=ICtR[5]; ICtR[5]=''" @keyup="(event) => checkNum(event)" @blur="(ICtR[5]=='') ? (ICtR[5]=tempValue) : validInput($event.target.value); showCalculations=false; calculateValue()" @paste.prevent="false" class="inp-box" />
                    </div>
                    <div class="col">
                        <label class="inp-label d-flex justify-content-center">Year 10</label>
                        <BaseInputEnter v-model="ICtR[10]" type="number" step=0.01 @drop.prevent="false" @focus="tempValue=ICtR[10]; ICtR[10]=''" @keyup="(event) => checkNum(event)" @blur="(ICtR[10]=='') ? (ICtR[10]=tempValue) : validInput($event.target.value); showCalculations=false; calculateValue()" @paste.prevent="false" class="inp-box" />
                    </div>
                    <div class="col">
                        <label class="inp-label d-flex justify-content-center">Perp.</label>
                        <BaseInputEnter v-model="ICtR[11]" type="number" step=0.01 @drop.prevent="false" @focus="tempValue=ICtR[11]; ICtR[11]=''" @keyup="(event) => checkNum(event)" @blur="(ICtR[11]=='') ? (ICtR[11]=tempValue) : validInput($event.target.value); showCalculations=false; calculateValue()" @paste.prevent="false" class="inp-box" />
                    </div>
                </div>
            </div>
            <div class="row justify-content-center gx-4 input-row mb-1 mt-1">
                <div class="col">
                    <div class="row">
                        <div class="col col-item">
                            Net Debt / Invested Capital (%):
                        </div>
                    </div>
                </div>
            </div>
            <div class="card inputs-card netdebt-card">
                <div class="row justify-content-center gx-2">
                    <div class="col col-history col-avg">
                        4Yr-Av
                    </div>
                    <div class="col col-history">
                        {{revYear[0]}}
                    </div>
                    <div class="col col-history">
                        {{revYear[1]}}
                    </div>
                    <div class="col col-history">
                        {{revYear[2]}}
                    </div>
                    <div class="col col-history">
                        {{revYear[3]}}
                    </div>
                </div>
                <div class="row justify-content-center gx-2">
                    <div class="col col-history2 col-avg2">
                        <div v-if="!isNaN(DtICap[4])">
                            {{DtICap[4]}}
                        </div>
                        <div v-else>
                            n.a.
                        </div>
                    </div>
                    <div class="col col-history2">
                        <div v-if="!isNaN(DtICap[0])">
                            {{DtICap[0]}}
                        </div>
                        <div v-else>
                            n.a.
                        </div>
                    </div>
                    <div class="col col-history2">
                        <div v-if="!isNaN(DtICap[1])">
                            {{DtICap[1]}}
                        </div>
                        <div v-else>
                            n.a.
                        </div>
                    </div>
                    <div class="col col-history2">
                        <div v-if="!isNaN(DtICap[2])">
                            {{DtICap[2]}}
                        </div>
                        <div v-else>
                            n.a.
                        </div>
                    </div>
                    <div class="col col-history2">
                        <div v-if="!isNaN(DtICap[3])">
                            {{DtICap[3]}}
                        </div>
                        <div v-else>
                            n.a.
                        </div>
                    </div>
                </div>
                <div class="divider sep-line-history "></div>
                <div class="row justify-content-center gx-4">
                    <div class="col">
                        <label class="inp-label d-flex justify-content-center">Year 1</label>
                        <BaseInputEnter v-model="DtIC[1]" type="number" step=0.01 @drop.prevent="false" @focus="tempValue=DtIC[1]; DtIC[1]=''" @keyup="(event) => checkNum(event)" @blur="(DtIC[1]=='') ? (DtIC[1]=tempValue) : validInput($event.target.value); showCalculations=false; calculateValue()" @paste.prevent="false" class="inp-box" />
                    </div>
                    <div class="col">
                        <label class="inp-label d-flex justify-content-center">Year 5</label>
                        <BaseInputEnter v-model="DtIC[5]" type="number" step=0.01 @drop.prevent="false" @focus="tempValue=DtIC[5]; DtIC[5]=''" @keyup="(event) => checkNum(event)" @blur="(DtIC[5]=='') ? (DtIC[5]=tempValue) : validInput($event.target.value); showCalculations=false; calculateValue()" @paste.prevent="false" class="inp-box" />
                    </div>
                    <div class="col">
                        <label class="inp-label d-flex justify-content-center">Year 10</label>
                        <BaseInputEnter v-model="DtIC[10]" type="number" step=0.01 @drop.prevent="false" @focus="tempValue=DtIC[10]; DtIC[10]=''" @keyup="(event) => checkNum(event)" @blur="(DtIC[10]=='') ? (DtIC[10]=tempValue) : validInput($event.target.value); showCalculations=false; calculateValue()" @paste.prevent="false" class="inp-box" />
                    </div>
                    <div class="col">
                        <label class="inp-label d-flex justify-content-center">Perp.</label>
                        <BaseInputEnter v-model="DtIC[11]" type="number" step=0.01 @drop.prevent="false" @focus="tempValue=DtIC[11]; DtIC[11]=''" @keyup="(event) => checkNum(event)" @blur="(DtIC[11]=='') ? (DtIC[11]=tempValue) : validInput($event.target.value); showCalculations=false; calculateValue()" @paste.prevent="false" class="inp-box" />
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="row justify-content-center gx-3 gy-0 pt-0 pb-2 mb-4 mt-2">
            <div class="col col-sm-4">
            </div>
            <div class="col-7 col-sm-4">
                <button type="submit" @click.prevent="calculateValue()" class="btn custom-btn-form calc-btn btn3d btn-primar" id="calculate-button">Calculate valuation</button>
            </div>
            <div class="col col-sm-4">
            </div>
        </div> -->

        <!-- <div class="row my-4"></div> -->

        <div class="row g-0 row-separator2">
            <div class="col col-divider-line1">
                <div class="divider sep-line-light"></div>
            </div>
            <div class="col-6 col-sm-2 col-divider-text">
                <b>VALUATION RESULTS</b>
            </div>
            <div class="col col-divider-line2">
                <div class="divider sep-line-light"></div>
            </div>
        </div>

        <!-- <div v-show="showCalculations"> -->
        <div class="card bookvalue-card py-1 mt-3">
            <div class="row justify-content-center gx-4 data-row">
                <div class="col-xs-6 col-sm-5">
                    <div class="row">
                        <div class="col-8 col-item">
                            Book value per share ({{financialCurrency}}):
                        </div>
                        <div class="col col-data">
                            <BaseInputEnter v-model="this.bookValueFinCurr" type="text" class="fin-box" disabled />
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="priceCurrency!=financialCurrency" class="row justify-content-center gx-4 data-row mb-1">
                <div class="col-xs-6 col-sm-5">
                    <div class="row">
                        <div class="col-8 col-item">
                            Book value / share ({{priceCurrency}}):
                        </div>
                        <div class="col col-data">
                            <BaseInputEnter v-model="this.bookValuePriCurr" type="text" class="fin-box" disabled />
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <!-- <div class="row justify-content-center gx-4 ticker-row mt-3 mb-0">
                <div class="col-xs-6 col-sm-5">
                    <div class="row">
                        <div class="col-8 col-item">
                            Valuation results:
                        </div>
                        <div class="col col-curr">
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ financialCurrency }}
                        </div>
                    </div>
                </div>
            </div> -->
        <div class="card main-card-results py-1 mt-2">
            <!-- <div class="row justify-content-center gx-4 data-row">
                <div class="col-xs-6 col-sm-5">
                    <div class="row">
                        <div class="col-8 col-item">
                            Min (incl. other methods):
                        </div>
                        <div class="col col-data">
                            <BaseInputEnter v-model="minSV" type="text" class="fin-box" disabled />
                            !-- {{ minSV }} --
                        </div>
                    </div>
                </div>
            </div> -->
            <!-- <div class="row justify-content-center gx-4 data-row">
                    <div class="col-xs-6 col-sm-5">
                        <div class="row">
                            <div class="col-8 col-item">
                                IV-perp/mult:
                            </div>
                            <div class="col col-data">
                                <BaseInputEnter v-model="AvUfcfSV" type="text" class="fin-box" disabled />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row justify-content-center gx-4 data-row">
                    <div class="col-xs-6 col-sm-5">
                        <div class="row">
                            <div class="col-8 col-item">
                                DCF-perp/mult:
                            </div>
                            <div class="col col-data">
                                <BaseInputEnter v-model="AvFcFeSV" type="text" class="fin-box" disabled />
                            </div>
                        </div>
                    </div>
                </div> -->
            <!-- <div class="row justify-content-center gx-4 data-row mb-2">
                <div class="col-xs-6 col-sm-5">
                    <div class="row">
                        <div class="col-8 col-item">
                            Max (incl. other methods):
                        </div>
                        <div class="col col-data">
                            <BaseInputEnter v-model="maxSV" type="text" class="fin-box" disabled />
                            !-- {{ maxSV }} --
                        </div>
                    </div>
                </div>
            </div> -->
            <!-- </div> -->
            <!-- <div class="divider sep-line-results"></div> -->
            <!-- <div class="card main-card-form py-1 mt-1"> -->

            <!-- <div class="row justify-content-center gx-4 data-row mt-2"> -->
            <div class="row justify-content-center gx-4 data-row">
                <div class="col-xs-6 col-sm-5">
                    <div class="row">
                        <div class="col-8 col-item">
                            UFCF - Perpetual growth:
                        </div>
                        <div class="col col-data">
                            <BaseInputEnter v-model="PerpNewUfcf" type="text" class="fin-box" disabled />
                        </div>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center gx-4 data-row ">
                <div class="col-xs-6 col-sm-5">
                    <div class="row">
                        <div class="col-8 col-item">
                            UFCF - EBITDA multiple:
                        </div>
                        <div class="col col-data">
                            <BaseInputEnter v-model="MultNewUfcf" type="text" class="fin-box" disabled />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card main-card-results py-1 mt-2">
            <div class="row justify-content-center gx-4 data-row">
                <div class="col-xs-6 col-sm-5">
                    <div class="row">
                        <div class="col-8 col-item">
                            FCFE - Perpetual growth:
                        </div>
                        <div class="col col-data">
                            <BaseInputEnter v-model="PerpNewFcfe" type="text" class="fin-box" disabled />
                        </div>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center gx-4 data-row mb-2">
                <div class="col-xs-6 col-sm-5">
                    <div class="row">
                        <div class="col-8 col-item">
                            FCFE - Net Income multiple:
                        </div>
                        <div class="col col-data">
                            <BaseInputEnter v-model="MultNewFcfe" type="text" class="fin-box" disabled />
                        </div>
                    </div>
                </div>
            </div>

            <div class="divider sep-line-results"></div>
            <!-- <div class="row ">
                    <div class="divider sep-line"></div>
                </div> -->

            <div class="row justify-content-center gx-4 data-row average-row py-1 mt-1">
                <div class="col-xs-6 col-sm-5">
                    <div class="row">
                        <div class="col-8 col-item">
                            <b>FCFE Avg. Valuation ({{ financialCurrency }}):</b>
                        </div>
                        <div class="col col-data">
                            <!-- <b>{{ fairValue }}</b> -->
                            <BaseInputEnter v-model="fairValue" type="text" class="fin-box" disabled />
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="priceCurrency!=financialCurrency">
                <div class="divider sep-line-results mt-1"></div>
                <div class="row justify-content-center gx-4 data-row py-1 mt-1 mb-0">
                    <div class="col-xs-6 col-sm-5">
                        <div class="row">
                            <div class="col-8 col-item">
                                <b>FCFE Avg. Valuation ({{ priceCurrency }}):</b>
                            </div>
                            <div class="col col-data">
                                <!-- <b>{{ (Math.round(fairValue/fx*10)/10).toFixed(1) }}</b> -->
                                <BaseInputEnter v-model="fairValueCurr" type="text" class="fin-box" disabled />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row justify-content-center gx-4 data-row last-price-row pt-1 mt-1">
            <div class="col-xs-6 col-sm-5">
                <div class="row">
                    <div class="col-8 col-item ps-0">
                        Latest Price ({{ priceCurrency }}):
                    </div>
                    <div class="col col-curr">
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ Number(lastPrice).toLocaleString('en-US') }}
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="row my-3"></div> -->
        <!-- </div> -->

    </div>
</div>
</template>

<script>
import yfapiCalls from '@/services/yfapiCalls.js'
import mbapiCalls from '@/services/mbapiCalls.js'
import apiCalls from '@/services/apiCalls.js'
import store from '@/store'
import {
    mapActions,
} from 'vuex'
// import router from '@/router'
export default {
    name: 'FairValue',
    data() {
        return {
            tempValue: '',
            TreasuryYield: 4,
            taxRate: 21,
            terminalEbitdaMult: 7,
            terminalPEMult: 10,
            borrowRateVsTreasury: 2,
            discountRateVsTreasury: 5,
            fx: 1,

            avBorrowRate: 0,
            myDiscountRate: 0,
            showEnterTicker: false,
            showSearch: false,
            searchWarn: '',
            warningMsg: '',
            tickerName: '',
            assetName: '',
            tickers: [],
            nameSearch: '',
            region: 'any',
            regions: [
                'any',
                'US',
                'GB',
                'DE',
                'FR',
                'IT',
                'ES',
                'AU',
                'CA',
                'HK',
                'IN',
            ],
            calledByGetFin: false,
            calledByCheckFin: false,
            symbol: '',
            shortName: '',
            exchange: '',
            balanceSheet: {},
            incomeStatement: {},
            cashFlow: {},
            marketQuotes: {},
            stockData: {},
            TTMDate: null,
            financialsId: null,
            cash: null,
            currentAssets: null,
            totalAssets: null,
            currentLiabilities: null,
            totalLiabilities: null,
            totalRevenue: null,
            grossProfit: null,
            operatingIncome: null,
            netIncomeCommonShares: null,
            depreciation: null,
            totalEquity: null,
            stockholderEquity: null,
            minorityInterest: null,
            sharesOutstanding: null,

            financialCurrency: '',
            priceCurrency: '',
            lastPrice: 0,
            showFinancials: false,
            showCalculations: false,

            cash2: null,
            currentAssets2: null,
            totalAssets2: null,
            currentLiabilities2: null,
            totalLiabilities2: null,
            totalRevenue2: null,
            grossProfit2: null,
            operatingIncome2: null,
            netIncomeCommonShares2: null,
            depreciation2: null,
            stockholderEquity2: null,
            sharesOutstanding2: null,
            historyFilled: false,

            rvG_red: 0,
            opM_red: 0,
            pfM_red: 0,
            shD_red: 0,

            rvG: [], // These are the values for the calculation
            opM: [],
            pfM: [],
            shD: [],
            ICtR: [], // Invested Capital to Revenues
            DtIC: [], // Net Debt to Invested Capital

            netCurrentAssets: 0,
            netDebt: 0,

            SV_AvTermUfcf: 0,
            SV_MultTermUfcf: 0,
            SV_PerpTermNewUfcf: 0,
            SV_MultTermNewUfcf: 0,
            SV_AvTermFcfe: 0,
            SV_MultTermFcfe: 0,
            SV_PerpTermNewFcfe: 0,
            SV_MultTermNewFcfe: 0,

            maxSV: 0,
            minSV: 0,
            AvUfcfSV: 0,
            AvFcFeSV: 0,
            AvNewUfcfSV: 0,
            AvNewFcfeSV: 0,
            PerpNewUfcf: 0,
            MultNewUfcf: 0,
            PerpNewFcfe: 0,
            MultNewFcfe: 0,

            fairValue: 0,
            fairValueCurr: 0,

            histRevenues: [],
            histOpIncome: [],
            histNetIncome: [],
            histShares: [],
            histTotalLiabilities: [],
            histTotalAssets: [],
            histCurrentAssets: [],
            histTotalEquity: [],
            histStockholderEquity: [],
            histMinorityInterest: [],
            histCurrentLiabilities: 0,
            histGrossProfit: 0,
            histCash: 0,
            histDepreciation: 0,
            histBsDate: '',
            histCfDate: '',

            opMarg: [], // These are the historical values
            profMarg: [],
            revGrowth: [],
            revDate: [],
            revYear: [],
            sharesGrowth: [],
            ICtRev: [], // Invested Capital to Revenues
            DtICap: [], // Net Debt to Invested Capital

            FinancialData: null,

            marketCap: 0,
            PeRatio: 0,
            EbitdaRatio: 0,
            grossMargin: 0,
            operatingMargin: 0,
            profitMargin: 0,
            roic: 0,
            roe: 0,
            investedCapToRev: 0,
            netDebtToInvestedCap: 0,
            netDebtIndicator: 0,
            netWorkingCap: 0,
            bookValueFinCurr: 0,
            bookValuePriCurr: 0,
            dataMissing: '',
            histMissing: '',
            infoMissing: '',
            additionalWarning_1: '',
            depreciationMissing: false,
            dontSave: false,
            depreciationWarn: false,
            showOptional: false,
            mbQU: false,
            mbBS: false,
            mbIS: false,
            mbCF: false,
            yfDATA: false,
            yfCall2: false,
            shortFloat: '',
            shortDays: '',
            tryTimeseries: false,
            prefillCalledByFunction: false,
        }
    },
    methods: {
        ...mapActions(['setAlertMsg']),
        async searchApi() {
            console.log('In searchApi: ', this.nameSearch)
            // this.region = 'US'
            this.showEnterTicker = true
            this.warningMsg = '' // Hides the warning message if there is one open
            if (!this.nameSearch) {
                return this.searchWarn = 'Please enter a stock to search!'
            }
            try {
                let param = {}
                if (store.state.apiUrl == 'yf') {
                    param = {
                        lang: 'en',
                        query: this.nameSearch,
                    }
                } else {
                    param = {
                        // lang: 'en',
                        q: this.nameSearch,
                    }
                }
                let regio = {
                    region: this.region
                }
                let params = {}
                if (this.region != 'any') {
                    params = {
                        ...regio,
                        ...param,
                    }
                } else {
                    params = {
                        ...param,
                    }
                }
                let resp = null
                let primaryExchanges = ['NYSE', 'NASDAQ', 'London', 'XETRA', 'Swiss', 'Paris', 'Milan', 'Madrid', 'Amsterdam', 'Brussels', 'Frankfurt', 'Vienna', 'Australian', 'Toronto']
                if (store.state.apiUrl == 'yf') {
                    let dropdownTickers = await yfapiCalls.searchYF(params)
                    console.log('dropdownTickers YF: ', dropdownTickers)
                    if (dropdownTickers.data.ResultSet.Result && dropdownTickers.data.ResultSet.Result.length > 0) {
                        resp = dropdownTickers.data.ResultSet.Result
                    } else if (dropdownTickers.data.quotes && dropdownTickers.data.quotes.length > 0) {
                        resp = dropdownTickers.data.quotes
                    } else {
                        this.assetName = ''
                        this.tickerName = ''
                        this.nameSearch = ''
                        this.tickers = []
                        return this.searchWarn = 'No stock found matching your search!'
                    }
                    resp.forEach(asset => {
                        let exch = asset.exchDisp.split(' ')[0]
                        if (primaryExchanges.indexOf(exch) > -1) {
                            asset['sortIndex'] = primaryExchanges.indexOf(exch)
                        } else if (exch.indexOf('OTC') !== -1) {
                            asset['sortIndex'] = 30
                        } else {
                            asset['sortIndex'] = 20
                        }
                    })
                    resp.sort(function (a, b) {
                        return a.sortIndex - b.sortIndex
                    })
                    console.log('sorted resp: ', resp)
                    if (dropdownTickers.data.ResultSet) {
                        this.tickers = resp.map(a => a.symbol + ' - ' + a.exchDisp.split(' ')[0] + ' - ' + a.name)
                    } else if (dropdownTickers.data.quotes) {
                        this.tickers = resp.map(a => a.symbol + ' - ' + a.exchDisp.split(' ')[0] + ' - ' + a.shortname)
                    }
                } else {
                    const dropdownTickers = await yfapiCalls.searchRapid(params)
                    console.log('dropdownTickers Rapid: ', dropdownTickers)
                    if (dropdownTickers.data.quotes && dropdownTickers.data.quotes.length > 0) {
                        resp = dropdownTickers.data.quotes
                    } else {
                        this.assetName = ''
                        this.tickerName = ''
                        this.tickers = []
                        return this.searchWarn = 'No stock found matching your search!'
                    }
                    resp.forEach(asset => {
                        let exch = asset.exchDisp.split(' ')[0]
                        if (primaryExchanges.indexOf(exch) > -1) {
                            asset['sortIndex'] = primaryExchanges.indexOf(exch)
                        } else if (exch.indexOf('OTC') !== -1) {
                            asset['sortIndex'] = 30
                        } else {
                            asset['sortIndex'] = 20
                        }
                    })
                    resp.sort(function (a, b) {
                        return a.sortIndex - b.sortIndex
                    })
                    console.log('sorted resp: ', resp)
                    this.tickers = resp.map(a => a.symbol + ' - ' + a.exchDisp.split(' ')[0] + ' - ' + a.shortname)
                }
                this.assetName = this.tickers[0]
            } catch (error) {
                return console.log('error in Search Asset: ', error)
            }
        },
        async checkFinancials(asset) {
            try { // check if already available in database
                console.log('in checkFinancials1')
                console.log('asset: ', asset == '')
                console.log('asset: ', !asset)
                this.depreciationWarn = false
                this.showFinancials = false
                this.financialsId = null // this gets assigned if the Financials are already available but too old, whereby they need to be overwritten
                this.mbQU = false
                this.mbBS = false
                this.mbIS = false
                this.mbCF = false
                this.yfDATA = false
                this.yfCall2 = false
                if (!asset || asset == '') {
                    console.log('triggered 1')
                    this.tickers = []
                    if (this.showSearch) {
                        return this.warningMsg = 'Please select a stock to get the Data.'
                    } else {
                        return this.warningMsg = 'Please enter a ticker to get the Data.'
                    }
                }

                let params = {}
                if (this.tickerName != '') { // came directly from inserting the ticker
                    this.symbol = this.tickerName.toUpperCase()
                    params = {
                        symbol: this.symbol,
                    }
                } else { // came from selecting in dropdown
                    this.symbol = asset.split(' ')[0]
                    console.log('this.symbol: ', this.symbol)
                    params = {
                        symbol: this.symbol,
                    }
                }
                this.FinancialData = null
                this.balanceSheet = {}
                this.incomeStatement = {}
                this.cashFlow = {}
                this.histRevenues = []
                this.histOpIncome = []
                this.histNetIncome = []
                this.histShares = []
                this.histTotalLiabilities = []
                this.histTotalAssets = []
                this.histCurrentAssets = []
                this.histTotalEquity = []
                this.histStockholderEquity = []
                this.histMinorityInterest = []
                this.histCurrentLiabilities = 0
                this.histGrossProfit = 0
                this.histCash = 0
                this.histDepreciation = 0
                this.histBsDate = ''
                this.histCfDate = ''

                console.log('in checkFinancials2')
                // check if already available in database
                console.log('symbol for financials: ', params.symbol)
                let Financials = await apiCalls.getEntries('/financials/', {
                    symbol: this.symbol,
                })
                console.log('Financials downloaded from db: ', Financials)
                console.log('in checkFinancials3')

                if (Financials && Financials.data && Financials.data != null && Financials.data.length > 0) { // the stock is already in the database
                    console.log('in checkFinancials4')
                    this.warningMsg = 'Loading data, please wait...'
                    this.FinancialData = Financials.data[0]
                    console.log('this.FinancialData1: ', this.FinancialData)
                    this.count = Number(this.FinancialData.count) + 1
                    this.shortName = this.FinancialData.shortname
                    this.exchange = this.FinancialData.exchange
                    this.TTMDate = this.FinancialData.ttmdate
                    this.cash = Number(this.FinancialData.cash)
                    this.currentAssets = Number(this.FinancialData.currentassets)
                    this.totalAssets = Number(this.FinancialData.totalassets)
                    this.currentLiabilities = Number(this.FinancialData.currentliabilities)
                    this.totalLiabilities = Number(this.FinancialData.totalliabilities)
                    this.totalEquity = Number(this.totalAssets) - Number(this.totalLiabilities)
                    this.stockholderEquity = Number(this.FinancialData.stockholderequity)
                    this.totalRevenue = Number(this.FinancialData.totalrevenue)
                    this.grossProfit = Number(this.FinancialData.grossprofit)
                    this.operatingIncome = Number(this.FinancialData.operatingincome)
                    this.netIncomeCommonShares = Number(this.FinancialData.netincome)
                    this.depreciation = Number(this.FinancialData.depreciation)
                    if (this.depreciation == 0) {
                        this.depreciationWarn = true
                    }
                    this.sharesOutstanding = Number(this.FinancialData.sharesoutstanding)
                    this.financialCurrency = this.FinancialData.fincurrency
                    this.lastPrice = Number(this.FinancialData.lastcloseprice)
                    this.priceCurrency = this.FinancialData.pricurrency
                    this.revDate[0] = this.FinancialData.histdate1
                    this.histRevenues[0] = Number(this.FinancialData.histrevenues1)
                    this.histOpIncome[0] = Number(this.FinancialData.histopincome1)
                    this.histNetIncome[0] = Number(this.FinancialData.histnetincome1)
                    this.histTotalLiabilities[0] = Number(this.FinancialData.histtotalliabilities1)
                    this.histTotalAssets[0] = Number(this.FinancialData.histtotalassets1)
                    this.histCurrentAssets[0] = Number(this.FinancialData.histcurrentassets1)
                    this.histTotalEquity[0] = Number(this.FinancialData.histtotalassets1) - Number(this.FinancialData.histtotalliabilities1)
                    this.histStockholderEquity[0] = Number(this.FinancialData.histstockholderequity1)
                    this.histShares[0] = Number(this.FinancialData.histshares1)
                    this.revDate[1] = this.FinancialData.histdate2
                    this.histRevenues[1] = Number(this.FinancialData.histrevenues2)
                    this.histOpIncome[1] = Number(this.FinancialData.histopincome2)
                    this.histNetIncome[1] = Number(this.FinancialData.histnetincome2)
                    this.histTotalLiabilities[1] = Number(this.FinancialData.histtotalliabilities2)
                    this.histTotalAssets[1] = Number(this.FinancialData.histtotalassets2)
                    this.histCurrentAssets[1] = Number(this.FinancialData.histcurrentassets2)
                    this.histTotalEquity[1] = Number(this.FinancialData.histtotalassets2) - Number(this.FinancialData.histtotalliabilities2)
                    this.histStockholderEquity[1] = Number(this.FinancialData.histstockholderequity2)
                    this.histShares[1] = Number(this.FinancialData.histshares2)
                    this.revDate[2] = this.FinancialData.histdate3
                    this.histRevenues[2] = Number(this.FinancialData.histrevenues3)
                    this.histOpIncome[2] = Number(this.FinancialData.histopincome3)
                    this.histNetIncome[2] = Number(this.FinancialData.histnetincome3)
                    this.histTotalLiabilities[2] = Number(this.FinancialData.histtotalliabilities3)
                    this.histTotalAssets[2] = Number(this.FinancialData.histtotalassets3)
                    this.histCurrentAssets[2] = Number(this.FinancialData.histcurrentassets3)
                    this.histTotalEquity[2] = Number(this.FinancialData.histtotalassets3) - Number(this.FinancialData.histtotalliabilities3)
                    this.histStockholderEquity[2] = Number(this.FinancialData.histstockholderequity3)
                    this.histShares[2] = Number(this.FinancialData.histshares3)
                    this.revDate[3] = this.FinancialData.histdate4
                    this.histRevenues[3] = Number(this.FinancialData.histrevenues4)
                    this.histOpIncome[3] = Number(this.FinancialData.histopincome4)
                    this.histNetIncome[3] = Number(this.FinancialData.histnetincome4)
                    this.histTotalLiabilities[3] = Number(this.FinancialData.histtotalliabilities4)
                    this.histTotalAssets[3] = Number(this.FinancialData.histtotalassets4)
                    this.histCurrentAssets[3] = Number(this.FinancialData.histcurrentassets4)
                    this.histTotalEquity[3] = Number(this.FinancialData.histtotalassets4) - Number(this.FinancialData.histtotalliabilities4)
                    this.histStockholderEquity[3] = Number(this.FinancialData.histstockholderequity4)
                    this.histShares[3] = Number(this.FinancialData.histshares4)

                    this.marketCap = Math.round(this.sharesOutstanding * this.lastPrice).toLocaleString('en-US')
                    // this.PeRatio = (Math.round(this.sharesOutstanding * this.lastPrice / this.netIncomeCommonShares * 10) / 10).toLocaleString('en-US') // in parentheses is MarketCap
                    this.grossMargin = (Math.round(this.grossProfit / this.totalRevenue * 100 * 10) / 10).toLocaleString('en-US') + ' %'
                    this.operatingMargin = (Math.round(this.operatingIncome / this.totalRevenue * 100 * 10) / 10).toLocaleString('en-US') + ' %'
                    this.profitMargin = (Math.round(this.netIncomeCommonShares / this.totalRevenue * 100 * 10) / 10).toLocaleString('en-US') + ' %'
                    this.netDebtIndicator = Math.round(this.totalLiabilities - this.currentAssets).toLocaleString('en-US')
                    this.roic = (Math.round(this.netIncomeCommonShares / ((this.totalLiabilities - this.currentAssets) + this.stockholderEquity) * 100 * 10) / 10).toLocaleString('en-US') + ' %' // in parentheses is NetDebtIndicator
                    this.roe = (Math.round(this.netIncomeCommonShares / this.stockholderEquity * 100 * 10) / 10).toLocaleString('en-US') + ' %'
                    this.investedCapToRev = (Math.round((this.totalLiabilities - this.currentAssets + this.totalEquity) / this.totalRevenue * 1000) / 10).toLocaleString('en-US') + ' %'
                    this.netDebtToInvestedCap = (Math.round((this.totalLiabilities - this.currentAssets) / (this.totalLiabilities - this.currentAssets + this.totalEquity) * 1000) / 10).toLocaleString('en-US') + ' %'
                    if (Number(this.netDebtToInvestedCap) < 0) {
                        this.netDebtToInvestedCap = `0`.toLocaleString('en-US') + ' %'
                    }
                    this.netWorkingCap = Math.round(this.currentAssets - this.currentLiabilities).toLocaleString('en-US')
                    this.EbitdaRatio = (Math.round(((this.totalLiabilities - this.currentAssets) + this.totalEquity) / (this.operatingIncome + this.depreciation) * 10) / 10).toLocaleString('en-US') // in parentheses is NetDebtIndicator
                    if (this.stockholderEquity != 0) {
                        this.bookValueFinCurr = Math.round(this.stockholderEquity / this.sharesOutstanding).toFixed(1)
                    } else {
                        this.bookValueFinCurr = Math.round(this.totalEquity / this.sharesOutstanding).toFixed(1)
                    }

                    let params = {}
                    let yfParams = {}
                    let currencySymbol = ''
                    this.fx = 1

                    if (this.priceCurrency != this.financialCurrency) {
                        if (this.priceCurrency == 'USD') {
                            currencySymbol = this.financialCurrency.toUpperCase() + '=X'
                        } else {
                            currencySymbol = this.priceCurrency.toUpperCase() + this.financialCurrency.toUpperCase() + '=X'
                        }

                        params = {
                            symbol: this.symbol + ',' + currencySymbol,
                        }
                        yfParams = {
                            symbols: this.symbol + ',' + currencySymbol,
                        }
                    } else {
                        params = {
                            symbol: this.symbol,
                        }
                        yfParams = {
                            symbols: this.symbol,
                        }
                    }
                    console.log(params, yfParams)
                    console.log('in checkFinancials5')

                    let MarketQuotes = {}
                    let yfQuotes = {}
                    console.log('Calling yfQuotes in checkF')
                    yfQuotes = await yfapiCalls.getRapid(yfParams)
                    if (yfQuotes && yfQuotes.data) {
                        console.log('yfQuotes.data: ', yfQuotes.data)
                    } else {
                        console.log('TRYING TO CALL yfQuotes A SECOND TIME in checkF')
                        yfQuotes = await yfapiCalls.getRapid(yfParams)
                        if (yfQuotes && yfQuotes.data) {
                            console.log('MANAGED yfQuotes.data2: ', yfQuotes.data)
                        } else {
                            console.log('FAILED AGAIN TO RETRIEVE DATA FROM yfQuotes in checkF')
                        }
                    }

                    if (yfQuotes && yfQuotes.data && yfQuotes.data.quoteResponse && yfQuotes.data.quoteResponse.error == null && yfQuotes.data.quoteResponse.result && yfQuotes.data.quoteResponse.result.length > 0) {
                        console.log('yfQuotes PASSED in checkF')
                    } else {
                        console.log('yfQuotes NOT PASSED in checkF')
                    }

                    if (yfQuotes && yfQuotes.data && yfQuotes.data.quoteResponse && yfQuotes.data.quoteResponse.error == null && yfQuotes.data.quoteResponse.result && yfQuotes.data.quoteResponse.result.length > 0) {
                        console.log('Assigning variables from yfQuotes in checkF: ', yfQuotes.data)
                        console.log('yfQuotes.data.quoteResponse.error==null1: ', yfQuotes.data.quoteResponse.error == null)
                        MarketQuotes['data'] = []
                        MarketQuotes['data'][0] = {}
                        // MarketQuotes['data'][0]['financialCurrency'] = yfQuotes.data.earnings.financialCurrency
                        // MarketQuotes['data'][0]['currency'] = yfQuotes.data.price.currency
                        // MarketQuotes['data'][0]['regularMarketPrice'] = yfQuotes.data.price.regularMarketPrice
                        // MarketQuotes['data'][0]['sharesOutstanding'] = yfQuotes.data.data.annualDilutedAverageShares[3]

                        if (yfQuotes.data.quoteResponse.result[0].quoteSummary && yfQuotes.data.quoteResponse.result[0].quoteSummary.earnings) {
                            MarketQuotes['data'][0]['financialCurrency'] = yfQuotes.data.quoteResponse.result[0].quoteSummary.earnings.financialCurrency
                        } else {
                            MarketQuotes['data'][0]['financialCurrency'] = ''
                        }
                        MarketQuotes['data'][0]['currency'] = yfQuotes.data.quoteResponse.result[0].currency
                        MarketQuotes['data'][0]['regularMarketPrice'] = yfQuotes.data.quoteResponse.result[0].regularMarketPrice
                        MarketQuotes['data'][0]['sharesOutstanding'] = yfQuotes.data.quoteResponse.result[0].sharesOutstanding
                        if (yfQuotes.data.quoteResponse.result[0].shortRatio > 0) {
                            MarketQuotes['data'][0]['shortRatio'] = yfQuotes.data.quoteResponse.result[0].shortRatio
                        } else {
                            MarketQuotes['data'][0]['shortRatio'] = 0
                        }
                        if (yfQuotes.data.quoteResponse.result[0].shortPercentFloat > 0) {
                            MarketQuotes['data'][0]['shortPercentFloat'] = yfQuotes.data.quoteResponse.result[0].shortPercentFloat
                        } else {
                            MarketQuotes['data'][0]['shortPercentFloat'] = 0
                        }

                        if (yfQuotes.data.quoteResponse.result.length > 1 && yfQuotes.data.quoteResponse.result[1]) {
                            console.log('Currency exists in checkF 1, assigning price to variable')
                            // MarketQuotesCurr['data'] = []
                            MarketQuotes['data'][1] = {}
                            MarketQuotes['data'][1]['regularMarketPrice'] = yfQuotes.data.quoteResponse.result[1].regularMarketPrice
                            console.log("CC MarketQuotes['data'][1]['regularMarketPrice']: ", MarketQuotes['data'][1]['regularMarketPrice'])
                        }

                        // } else if (!yfQuotes || !yfQuotes.data || (yfQuotes.data && yfQuotes.data.quoteResponse && (yfQuotes.data.quoteResponse.error!=null || !yfQuotes.data.quoteResponse.result ||(yfQuotes.data.quoteResponse.result && yfQuotes.data.quoteResponse.result.length==0)))) {
                    } else {
                        // console.log('yfQuotes.data.quoteResponse.error==null2: ', yfQuotes.data.quoteResponse.error==null)
                        console.log(`Could not download Quotes from YH Finance get-quotes`)
                    }

                    if (!yfQuotes || (yfQuotes && !yfQuotes.data) || (yfQuotes.data && yfQuotes.data.quoteResponse && (yfQuotes.data.quoteResponse.error != null || !yfQuotes.data.quoteResponse.result || (yfQuotes.data.quoteResponse.result && yfQuotes.data.quoteResponse.result.length == 0))) || !MarketQuotes || MarketQuotes == null || (MarketQuotes && !MarketQuotes.data)) {
                        // console.log('yfQuotes.data.quoteResponse.error==null3: ', yfQuotes.data.quoteResponse.error==null)
                        console.log('ATTENTION: Calling mb-api to get Quotes for Stock and Currency (if needed) (checkF)')
                        this.mbQU = true
                        MarketQuotes = await mbapiCalls.getMarketQuotes(params) // TODO: Find a way to use these data and not make a new identical call in getFinancials, in case that the data are found out to be old (see dateDifference further down)

                        if (MarketQuotes && MarketQuotes.data) {
                            console.log('mb MarketQuotes.data: ', MarketQuotes.data)
                        }
                    }

                    if (!MarketQuotes || !MarketQuotes.data || MarketQuotes.data.error != null) {
                        // console.log('MarketQuotes.data.error==null: ', MarketQuotes.data.error==null)
                        this.calledByGetFin = false // restores to initial, in case
                        return this.warningMsg = `Stock not found! Please re-check the ticker and try again.` // ToDo: let the valuation still happen, but with data retrieved from the DB
                    } else {
                        this.marketQuotes = MarketQuotes.data // Actually never used except for console.log
                        console.log('this.marketQuotes: ', this.marketQuotes)
                    }
                    if (MarketQuotes.data[0].financialCurrency.toLowerCase() != this.financialCurrency) {
                        this.calledByGetFin = false // restores to initial, in case
                        return this.warningMsg = `Stock not found! Please re-check the ticker and try again.` // ToDo: let the valuation still happen, but with data retrieved from the DB
                    }

                    console.log('ABOUT TO ASSIGN FX IN CHECK F')
                    console.log('MarketQuotes: ', MarketQuotes)
                    console.log('MarketQuotes.data: ', MarketQuotes.data)
                    console.log('MarketQuotes.data.length: ', MarketQuotes.data.length)

                    if (MarketQuotes && MarketQuotes.data && MarketQuotes.data.length > 1) {
                        if (!MarketQuotes.data[1].regularMarketPrice || MarketQuotes.data[1].regularMarketPrice == null || MarketQuotes.data[1].regularMarketPrice == 0) {
                            console.log('TRIGGERED 1')
                            console.log('could not retrieve fx although is needed, leaving as 1 just to continue') // ToDo: let the valuation still happen, but with data retrieved from the DB
                        } else {
                            console.log('TRIGGERED 2')
                            this.fx = MarketQuotes.data[1].regularMarketPrice
                            console.log('fx: ', this.fx)
                        }

                    }
                    this.sharesOutstanding = (MarketQuotes.data[0].sharesOutstanding) / 1000000
                    this.priceCurrency = MarketQuotes.data[0].currency.toLowerCase()
                    this.lastPrice = MarketQuotes.data[0].regularMarketPrice
                    if (MarketQuotes.data[0].shortRatio > 0) {
                        this.shortDays = MarketQuotes.data[0].shortRatio
                    } else {
                        this.shortDays = 'n.a.'
                    }
                    if (MarketQuotes.data[0].shortPercentFloat > 0) {
                        this.shortFloat = MarketQuotes.data[0].shortPercentFloat
                    } else {
                        this.shortFloat = 'n.a.'
                    }

                    if (MarketQuotes.data[0].currency == 'GBp') {
                        this.lastPrice = this.lastPrice / 100
                    }

                    this.PeRatio = (Math.round(this.sharesOutstanding * this.lastPrice / this.netIncomeCommonShares * this.fx * 10) / 10).toLocaleString('en-US') // in parentheses is MarketCap
                    if (this.stockholderEquity != 0) {
                        this.bookValuePriCurr = Math.round((this.stockholderEquity / this.sharesOutstanding) / this.fx).toFixed(1)
                    } else {
                        this.bookValuePriCurr = Math.round((this.totalEquity / this.sharesOutstanding) / this.fx).toFixed(1)
                    }

                    // let histCounter = 0
                    for (let i = 0; i < 4; i++) { // Historical values
                        if (this.revDate[i] && this.revDate[i] != null) { // restore 0220
                            // histCounter += 1
                            this.revYear[i] = this.revDate[i].slice(0, 4)
                            console.log('tttaaa', i, this.revYear[i])

                            this.opMarg[i] = Math.round((this.histOpIncome[i] / this.histRevenues[i]) * 1000) / 10
                            this.profMarg[i] = Math.round((this.histNetIncome[i] / this.histRevenues[i]) * 1000) / 10
                            this.ICtRev[i] = (Math.round((this.histTotalLiabilities[i] - this.histCurrentAssets[i] + this.histTotalEquity[i]) / this.histRevenues[i] * 1000) / 10)
                            if ((this.histTotalLiabilities[i] - this.histCurrentAssets[i] + this.histTotalEquity[i]) != 0) {
                                this.DtICap[i] = (Math.round((this.histTotalLiabilities[i] - this.histCurrentAssets[i]) / (this.histTotalLiabilities[i] - this.histCurrentAssets[i] + this.histTotalEquity[i]) * 1000) / 10)
                            } else {
                                this.DtICap[i] = 0
                            }

                            if (i > 0) {
                                this.revGrowth[i] = Math.round(((this.histRevenues[i - 1] - this.histRevenues[i]) / this.histRevenues[i]) * 1000) / 10 // 0 is the latest
                                if (this.histShares[i - 1] && this.histShares[i]) { // 0 is the oldest
                                    this.sharesGrowth[i] = Math.round(((this.histShares[i] - this.histShares[i - 1]) / this.histShares[i - 1]) * 1000) / 10
                                } else {
                                    this.sharesGrowth[i] = 0
                                }
                            }
                        }
                    }

                    console.log('in checkFinancials6')
                    // These are the averages - replaced by below to ignore NaN
                    // this.revGrowth[4] = Math.round(((this.revGrowth[1] + this.revGrowth[2] + this.revGrowth[3]) / 3) * 10) / 10
                    // this.opMarg[4] = Math.round(((this.opMarg[0] + this.opMarg[1] + this.opMarg[2] + this.opMarg[3]) / 4) * 10) / 10
                    // this.profMarg[4] = Math.round(((this.profMarg[0] + this.profMarg[1] + this.profMarg[2] + this.profMarg[3]) / 4) * 10) / 10
                    // this.sharesGrowth[4] = Math.round(((this.sharesGrowth[1] + this.sharesGrowth[2] + this.sharesGrowth[3]) / 3) * 10) / 10
                    // this.ICtRev[4] = Math.round(((this.ICtRev[0] + this.ICtRev[1] + this.ICtRev[2] + this.ICtRev[3]) / 4) * 10) / 10
                    // this.DtICap[4] = Math.round(((this.DtICap[0] + this.DtICap[1] + this.DtICap[2] + this.DtICap[3]) / 4) * 10) / 10


                    // Calculate averages, excluding non-available data
                    this.revGrowth[4] = Math.round(this.calcAverage([this.revGrowth[1], this.revGrowth[2], this.revGrowth[3]]) * 10) / 10
                    this.opMarg[4] = Math.round(this.calcAverage([this.opMarg[0], this.opMarg[1], this.opMarg[2], this.opMarg[3]]) * 10) / 10
                    this.profMarg[4] = Math.round(this.calcAverage([this.profMarg[0], this.profMarg[1], this.profMarg[2], this.profMarg[3]]) * 10) / 10
                    this.sharesGrowth[4] = Math.round(this.calcAverage([this.sharesGrowth[1], this.sharesGrowth[2], this.sharesGrowth[3]]) * 10) / 10
                    this.ICtRev[4] = Math.round(this.calcAverage([this.ICtRev[0], this.ICtRev[1], this.ICtRev[2], this.ICtRev[3]]) * 10) / 10
                    this.DtICap[4] = Math.round(this.calcAverage([this.DtICap[0], this.DtICap[1], this.DtICap[2], this.DtICap[3]]) * 10) / 10

                    let [year, month, day] = this.TTMDate.split('-')
                    const lastTTM = new Date(+year, +month - 1, +day)
                    let myDate = new Date()
                    const dateDifference = Math.round((myDate - lastTTM) / (1000 * 60 * 60 * 24))
                    console.log('Date difference: ', dateDifference)

                    if (dateDifference > 110) {
                        this.financialsId = this.FinancialData.id
                        console.log(`Financials from DB TOO OLD (${this.TTMDate}) - must call for NEW FINANCIALS`)
                        console.log('this.financialsId: ', this.financialsId)
                        this.FinancialData = null // setting null, so that once in getFinancials it will try get new updated Financial Data from API (but might find out there aren't yet, then it will use all the data just downloaded)
                    }
                    console.log('in checkFinancials7')
                    // if (this.tickerName != '') { // came directly from inserting the ticker, and Financial data were already in the database
                    //     console.log('in checkFinancials8')
                    //     await this.getFinancials(this.symbol)
                    // }
                    if (!this.calledByGetFin) { // came directly from inserting the ticker or from selecting, and Financial data were already in the database
                        console.log('in checkFinancials8')
                        this.calledByCheckFin = true
                        await this.getFinancials(this.symbol)
                    }
                } else {
                    // if (this.tickerName != '') { // came directly from inserting the ticker, but Financial data were not found in the database then it goes to get them
                    //     console.log('in checkFinancials9')
                    //     await this.getFinancials(this.symbol)
                    // } else {

                    if (!this.calledByGetFin) { // came directly from inserting the ticker or from selecting, but Financial data were not found in the database then it goes to get them
                        console.log('in checkFinancials9')
                        this.calledByCheckFin = true
                        await this.getFinancials(this.symbol)
                        // when getFinancials returns, it restarts from here
                    } else {
                        // Means it was called by getFinancials, therefore just returns to getFinancials
                        this.calledByGetFin = false // restores to initial state
                        return
                    }
                    console.log('in checkFinancials10')
                }
                if (this.depreciation == 0 && this.dataMissing == '' && this.infoMissing == '' && this.histMissing == '') { //VERY RECENT: added  && this.warningMsg == ''
                    this.warningMsg = `Depreciation missing from the Financial Data available for this stock. This may not have large effect on the valuation in some sectors, however to increase accuracy you may want to retrieve the 12-month Depreciation form other sources and enter below, before calculating the Valuation.`
                }
            } catch (error) {
                this.FinancialData = null
                this.calledByGetFin = false
                this.calledByCheckFin = false
                return console.log('error in checkFinancials: ', error)
            }

        },
        async getFinancials(asset) {
            try {
                this.warningMsg = ''
                this.dataMissing = ''
                this.histMissing = ''
                this.infoMissing = ''
                this.mbQU = false
                this.mbBS = false
                this.mbIS = false
                this.mbCF = false
                this.yfDATA = false
                this.yfCall2 = false
                this.depreciationMissing = false
                this.additionalWarning_1 = ''
                this.dontSave = false
                this.tryTimeseries = false
                this.historyFilled = false
                this.prefillCalledByFunction = false
                console.log('in getFinancials0')
                if (!asset || asset == '') {
                    this.tickers = []
                    return this.searchWarn = 'Please enter a stock to get the Data!'
                }
                console.log('in getFinancials1')
                if (!this.calledByCheckFin) {
                    console.log('go to checkFinancials')
                    this.calledByGetFin = true
                    await this.checkFinancials(asset)
                }
                console.log('in getFinancials2')
                console.log('this.FinancialData == null: ', this.FinancialData == null)
                console.log('this.financialsId != null: ', this.financialsId != null)
                if (this.FinancialData == null || this.financialsId != null) { // if FinancialData are null, then either the available Financials are too old, or not present
                    console.log('asset: ', asset)
                    console.log('asset_old: ', this.assetName.split(' ')[0])
                    this.showFinancials = false
                    this.showCalculations = false
                    this.balanceSheet = {}
                    this.incomeStatement = {}
                    this.cashFlow = {}
                    this.symbol = asset.split(' ')[0]
                    if (this.financialsId != null) {
                        this.histRevenues = []
                        this.histOpIncome = []
                        this.histNetIncome = []
                        this.histShares = []
                        this.histTotalLiabilities = []
                        this.histTotalAssets = []
                        this.histCurrentAssets = []
                        this.histTotalEquity = []
                        this.histStockholderEquity = []
                        this.histMinorityInterest = []
                        this.histCurrentLiabilities = 0
                        this.histGrossProfit = 0
                        this.histCash = 0
                        this.histDepreciation = 0
                        this.histBsDate = ''
                        this.histCfDate = ''
                    }
                    let finParams = {
                        symbol: asset.split(' ')[0],
                    }
                    console.log(finParams)
                    let yfQuoteParams = {}
                    let mbQuoteParams = {}

                    console.log('params for Financials in getFinancials: ', finParams)
                    let BalanceSheet = {}
                    let IncomeStatement = {}
                    let CashFlow = {}
                    let MarketQuotes = {}
                    MarketQuotes['data'] = []
                    let MarketQuotesCurr = {}
                    MarketQuotesCurr['data'] = []

                    this.warningMsg = 'Loading data, please wait...'
                    console.log('Calling yfFinancials in getF')
                    this.yfDATA = true
                    let yfFinancials = {}

                    let yh_data = {}
                    let yhFinancials = ''

                    yh_data = await apiCalls.getYhFinancials('/get-financials/' + asset.split(' ')[0] + '/')
                    if (yh_data && yh_data.data && yh_data.data.data) {
                        yhFinancials = this.assignYhFinancials(yh_data.data.data)
                        console.log('yhFinancials:', yhFinancials)
                    }

                    // let yhQuotes = {}
                    // yhQuotes = await apiCalls.getYhQuotes('/get-quotes/' + asset.split(' ')[0] + '/')
                    // if (yhQuotes) {
                    //     console.log('1:  ', yhQuotes)
                    // }

                    // let yhSearch = {}
                    // yhSearch = await apiCalls.getYhSearch('/get-search/' + 'amarin' + '/')
                    // if (yhSearch) {
                    //     console.log('2:  ', yhSearch)
                    // }

                    // let yhStats = {}
                    // yhStats = await apiCalls.getYhStats('/get-stats/' + asset.split(' ')[0] + '/')
                    // if (yhStats) {
                    //     console.log('3:  ', yhStats)
                    // }

                    // /* eslint-disable */
                    // return

                    yfFinancials = await yfapiCalls.getFinancials(finParams)
                    if (yfFinancials && yfFinancials.data) {
                        console.log('yfFinancials.data 01: ', yfFinancials.data) // this is the object with incomeStatementsHistoryQuarterly and incomeStatementsHistory, same for BS and CFS
                    } else {
                        console.log('TRYING TO CALL yfFinancials A SECOND TIME in getF')
                        this.yfCall2 = true
                        yfFinancials = await yfapiCalls.getFinancials(finParams)
                        if (yfFinancials && yfFinancials.data) {
                            console.log('MANAGED yfFinancials.data 02: ', yfFinancials.data)
                        } else {
                            console.log('FAILED AGAIN TO RETRIEVE DATA FROM yfFinancials in getF')
                        }
                    }
                    console.log('yfFinancials1: ', yfFinancials)
                    console.log('yfFinancials==true: ', yfFinancials == true) // When YF not called, this is true
                    // /* eslint-disable */
                    // return
                    if (yfFinancials) {
                        console.log('yfFinancials.data1: ', yfFinancials.data) // When YF not called, this is "undefined" (which means null), even though it doesn't exist at all
                        console.log('yfFinancials.data==true: ', yfFinancials.data == true) // When YF not called, this is false (so being undefined also triggers not true)
                        console.log('!yfFinancials.data: ', !yfFinancials.data) // When YF not called, this is true (see above, so being null it still triggers !yfFinancials.data as if it were false)
                        console.log('yfFinancials.data==null: ', yfFinancials.data == null) // When YF not called, this is true
                    }
                    if (yfFinancials && yfFinancials.data && yfFinancials.data.errorList && yfFinancials.data.errorList.length == 0 && yfFinancials.data.price && yfFinancials.data.price.quoteType == 'EQUITY') {
                        console.log('yfFinancials PASSED')
                    } else {
                        console.log('yfFinancials NOT PASSED')
                    }

                    let finCurr = null
                    let priCurr = null

                    // If the yfApiCall worked it goes on to assign the sub-objects of the Financials
                    if (yfFinancials && yfFinancials.data && yfFinancials.data.errorList && yfFinancials.data.errorList.length == 0 && yfFinancials.data.price && yfFinancials.data.price.quoteType == 'EQUITY') {
                        console.log('yfFinancials exist, assigning to variables: ', yfFinancials.data)
                        BalanceSheet['data'] = {}
                        BalanceSheet['data']['balanceSheetHistoryQuarterly'] = {}
                        BalanceSheet['data']['balanceSheetHistory'] = {}
                        IncomeStatement['data'] = {}
                        IncomeStatement['data']['incomeStatementHistoryQuarterly'] = {}
                        IncomeStatement['data']['incomeStatementHistory'] = {}
                        CashFlow['data'] = {}
                        CashFlow['data']['cashflowStatementHistoryQuarterly'] = {}
                        CashFlow['data']['cashflowStatementHistory'] = {}
                        // MarketQuotes['data'] = []
                        // MarketQuotes['data'][0] = {}
                        BalanceSheet['data']['balanceSheetHistoryQuarterly'] = yfFinancials.data.balanceSheetHistoryQuarterly
                        BalanceSheet['data']['balanceSheetHistory'] = yfFinancials.data.balanceSheetHistory
                        IncomeStatement['data']['incomeStatementHistoryQuarterly'] = yfFinancials.data.incomeStatementHistoryQuarterly
                        IncomeStatement['data']['incomeStatementHistory'] = yfFinancials.data.incomeStatementHistory
                        CashFlow['data']['cashflowStatementHistoryQuarterly'] = yfFinancials.data.cashflowStatementHistoryQuarterly
                        CashFlow['data']['cashflowStatementHistory'] = yfFinancials.data.cashflowStatementHistory

                        // replaced! -->
                        // if (yfFinancials && yfFinancials.data.timeSeries && yfFinancials.data.timeSeries.annualDilutedAverageShares) {
                        //     for (let i = 0; i < 4; i++) {
                        //         if (yfFinancials.data.timeSeries.annualDilutedAverageShares[i] && yfFinancials.data.timeSeries.annualDilutedAverageShares[i].reportedValue && yfFinancials.data.timeSeries.annualDilutedAverageShares[i].reportedValue.raw) {
                        //             this.histShares[i] = yfFinancials.data.timeSeries.annualDilutedAverageShares[i].reportedValue.raw / 1000000
                        //         }
                        //     }
                        // }
                        // <-->

                        if ('annualDilutedAverageShares' in yhFinancials.hist) {
                            for (let i = 0; i < yhFinancials.hist.periods; i++) {
                                if (yhFinancials.hist.annualDilutedAverageShares[i]) {
                                    this.histShares[i] = yhFinancials.hist.annualDilutedAverageShares[i] / 1000000
                                }
                            }
                        }
                        // <--

                        if (yfFinancials.data.earnings.financialCurrency) {
                            finCurr = yfFinancials.data.earnings.financialCurrency.toLowerCase()
                        } else {
                            finCurr = 'usd' //20231230
                            this.additionalWarning_1 = `ATTENTION: The currency for the Financial Statements of this stock was missing, therefore we have arbitrarily assumed it to be USD in order to proceed with the calculation, but it is important to verify that assumption.` //20231230
                            // 20231230 ATTENTION!!! the 2 lines above have replaced the ones below
                            // return this.warningMsg = 'The currency for the Financial Statements of this stock is missing. We are not able to perform the valuation.'
                        }

                        if (yfFinancials.data.price.currency) {
                            priCurr = yfFinancials.data.price.currency.toLowerCase()
                        } else {
                            return this.warningMsg = 'The currency relative to the price of this stock is missing. We are not able to perform the valuation.'
                        }

                        console.log('priCurr: ', priCurr)
                        console.log('finCurr: ', finCurr)

                        // financialCurr = yfFinancials.data.earnings.financialCurrency
                        // priceCurr = yfFinancials.data.price.currency
                        // priceCurr2 = yfFinancials.data.summaryDetail.currency
                        // price = yfFinancials.data.price.regularMarketPrice.raw  // DELAYED!
                        // quoteType = yfFinancials.data.price.quoteType
                        // quoteType2 = yfFinancials.data.quoteType.quoteType

                        // MarketQuotes['data'][0]['financialCurrency'] = yfFinancials.data.earnings.financialCurrency
                        // MarketQuotes['data'][0]['currency'] = yfFinancials.data.price.currency
                        // MarketQuotes['data'][0]['regularMarketPrice'] = yfFinancials.data.price.regularMarketPrice
                        // MarketQuotes['data'][0]['sharesOutstanding'] = yfFinancials.data.timeSeries.annualDilutedAverageShares[2].reportedValue.raw

                        // } else if (!yfFinancials || !yfFinancials.data || (yfFinancials.data && yfFinancials.data.error)) {
                    } else {
                        console.log(`Could not download Financials from YH Finance get-financials`)
                    }

                    // this.fx = 1
                    if (priCurr != finCurr) {
                        let currencySymbol = ''
                        if (priCurr == 'usd') {
                            currencySymbol = finCurr.toUpperCase() + '=X'
                        } else {
                            currencySymbol = priCurr.toUpperCase() + finCurr.toUpperCase() + '=X'
                        }
                        mbQuoteParams = {
                            symbol: `${asset.split(' ')[0]},${currencySymbol}`
                        }
                        yfQuoteParams = {
                            symbols: `${asset.split(' ')[0]},${currencySymbol}`
                        }

                    } else {
                        mbQuoteParams = {
                            symbol: asset.split(' ')[0],
                        }
                        yfQuoteParams = {
                            symbols: asset.split(' ')[0],
                        }
                    }
                    console.log(yfQuoteParams, mbQuoteParams)
                    console.log(yfQuoteParams)
                    let yfQuotes = {}
                    console.log('Calling yfQuotes in getF')
                    yfQuotes = await yfapiCalls.getRapid(yfQuoteParams)
                    console.log('yfQuotes1: ', yfQuotes)
                    console.log('yfQuotes=true: ', yfQuotes == true)
                    if (yfQuotes && yfQuotes.data) {
                        console.log('yfQuotes.data1: ', yfQuotes.data)
                        console.log('yfQuotes.data==true: ', yfQuotes.data == true)
                        console.log('yfQuotes.data==null: ', yfQuotes.data == null)
                    } else {
                        console.log('TRYING TO CALL yfQuotes A SECOND TIME in getF')
                        yfQuotes = await yfapiCalls.getRapid(yfQuoteParams)
                        if (yfQuotes && yfQuotes.data) {
                            console.log('MANAGED yfQuotes.data2: ', yfQuotes.data)
                        } else {
                            console.log('FAILED AGAIN TO RETRIEVE DATA FROM yfQuotes in getF')
                        }
                    }

                    if (yfQuotes && yfQuotes.data && yfQuotes.data.quoteResponse && yfQuotes.data.quoteResponse.error == null && yfQuotes.data.quoteResponse && yfQuotes.data.quoteResponse.result && yfQuotes.data.quoteResponse.result.length > 0) {
                        console.log('yfQuotes PASSED')
                    } else {
                        console.log('yfQuotes NOT PASSED')
                    }

                    if (yfQuotes && yfQuotes.data && yfQuotes.data.quoteResponse && yfQuotes.data.quoteResponse.error == null && yfQuotes.data.quoteResponse && yfQuotes.data.quoteResponse.result && yfQuotes.data.quoteResponse.result.length > 0) {
                        console.log('yfQuotes data exist, assigning to variables: ', yfQuotes.data)
                        // MarketQuotes['data'] = []
                        MarketQuotes['data'][0] = {}
                        if (yfQuotes.data.quoteResponse.result[0].quoteSummary && yfQuotes.data.quoteResponse.result[0].quoteSummary.earnings) {
                            MarketQuotes['data'][0]['financialCurrency'] = yfQuotes.data.quoteResponse.result[0].quoteSummary.earnings.financialCurrency
                        } else {
                            MarketQuotes['data'][0]['financialCurrency'] = ''
                        }
                        MarketQuotes['data'][0]['currency'] = yfQuotes.data.quoteResponse.result[0].currency
                        MarketQuotes['data'][0]['regularMarketPrice'] = yfQuotes.data.quoteResponse.result[0].regularMarketPrice
                        MarketQuotes['data'][0]['sharesOutstanding'] = yfQuotes.data.quoteResponse.result[0].sharesOutstanding
                        MarketQuotes['data'][0]['fullExchangeName'] = yfQuotes.data.quoteResponse.result[0].fullExchangeName
                        MarketQuotes['data'][0]['shortName'] = yfQuotes.data.quoteResponse.result[0].shortName

                        MarketQuotes['data'][0]['shortRatio'] = yfQuotes.data.quoteResponse.result[0].shortRatio
                        MarketQuotes['data'][0]['shortPercentFloat'] = yfQuotes.data.quoteResponse.result[0].shortPercentFloat

                        console.log('MarketQuotes.data1: ', MarketQuotes.data) // This is an object built by the program based on api response

                        if (yfQuotes.data.quoteResponse.result.length > 1 && yfQuotes.data.quoteResponse.result[1]) {
                            console.log('Currency exists, assigning price to variable')
                            // MarketQuotesCurr['data'] = []
                            MarketQuotesCurr['data'][0] = {}
                            MarketQuotesCurr['data'][0]['regularMarketPrice'] = yfQuotes.data.quoteResponse.result[1].regularMarketPrice
                            console.log("AA MarketQuotesCurr['data'][0]['regularMarketPrice']: ", MarketQuotesCurr['data'][0]['regularMarketPrice'])
                        }

                        // } else if (!yfQuotes || !yfQuotes.data || (yfQuotes.data && yfQuotes.data.quoteResponse && yfQuotes.data.quoteResponse.error!=null)) {
                    } else {
                        console.log(`Could not download Quotes from YH Finance get-quotes`)
                    }

                    if (!yfFinancials || (yfFinancials && !yfFinancials.data) || (yfFinancials.data && yfFinancials.data.errorList && yfFinancials.data.errorList.length != 0) || !BalanceSheet || BalanceSheet == null || (BalanceSheet && !BalanceSheet.data)) {

                        console.log('!yfFinancials: ', !yfFinancials)
                        if (yfFinancials && yfFinancials.data) {
                            console.log('!yfFinancials.data: ', !yfFinancials.data)
                            if (yfFinancials.data) {
                                console.log('yfFinancials.data.errorList && yfFinancials.data.errorList.length!=0: ', yfFinancials.data.errorList && yfFinancials.data.errorList.length != 0)
                            }
                        }
                        console.log('BalanceSheet: ', BalanceSheet)
                        if (BalanceSheet) {
                            console.log('BalanceSheet.data: ', BalanceSheet.data)
                        }
                        console.log('!BalanceSheet: ', !BalanceSheet)
                        console.log('BalanceSheet == null: ', BalanceSheet == null)
                        console.log('BalanceSheet == true: ', BalanceSheet == true)
                        console.log('!BalanceSheet.data: ', !BalanceSheet.data)
                        console.log('ATTENTION: Calling mb-api to get Balance Sheet (getF)')
                        this.mbBS = true
                        BalanceSheet = await mbapiCalls.getBalanceSheet(finParams)
                        if (BalanceSheet && BalanceSheet.data) {
                            console.log('BalanceSheet.data: ', BalanceSheet.data)
                        }
                    }
                    if (!BalanceSheet || !BalanceSheet.data || BalanceSheet.data.error != null) {
                        this.calledByCheckFin = false // restores to initial, in case
                        this.calledByGetFin = false // restores to initial, in case
                        return this.warningMsg = `Stock not found! Please re-check the ticker and try again.`
                    } else {
                        // this.warningMsg = 'Loading data, please wait...'
                        this.balanceSheet = BalanceSheet.data
                        if (this.financialsId != null) {
                            if (BalanceSheet.data.balanceSheetHistoryQuarterly.balanceSheetStatements[0].endDate.fmt != this.TTMDate) { // In this case it empties the previous lists and will patch the existing financials in the database
                                this.FinancialData = null
                                this.histRevenues = []
                                this.histOpIncome = []
                                this.histNetIncome = []
                                this.histShares = [] ////// NEW /////////////
                                this.histTotalLiabilities = []
                                this.histTotalAssets = []
                                this.histCurrentAssets = []
                                this.histTotalEquity = []
                                this.histStockholderEquity = []
                                this.histMinorityInterest = []
                                this.histCurrentLiabilities = 0
                                this.histGrossProfit = 0
                                this.histCash = 0
                                this.histDepreciation = 0
                                this.histBsDate = ''
                                this.histCfDate = ''

                                console.log('Proceeding to download all financials and patch in database)')
                                // continuing the execution....
                            } else {
                                console.log('DATE is the same as existing record! No need to download the same data again, ...except for latest price???)')
                                this.calledByGetFin = false
                                this.calledByCheckFin = false
                                this.prefillCalledByFunction = true // VERY RECENT 20231106
                                return await this.prefillParameters()
                            }
                        }
                    }
                    if (!yfQuotes || (yfQuotes && !yfQuotes.data) || (yfQuotes.data && yfQuotes.data.quoteResponse && yfQuotes.data.quoteResponse.error != null) || !MarketQuotes || MarketQuotes == null || (MarketQuotes && !MarketQuotes.data)) {
                        console.log(!yfQuotes)
                        if (yfQuotes) {
                            console.log(!yfQuotes.data)
                            console.log(yfQuotes.data)
                            if (yfQuotes.data && yfQuotes.data.quoteResponse) {
                                console.log('yfQuotes.data.quoteResponse.error==null: ', yfQuotes.data.quoteResponse.error == null)
                            }
                        }
                        console.log(!MarketQuotes)
                        console.log('MarketQuotes == null: ', MarketQuotes == null)
                        console.log('MarketQuotes == true: ', MarketQuotes == true)
                        console.log('MarketQuotes.data: ', MarketQuotes.data)
                        console.log('ATTENTION: Calling mb-api to get Quotes for STOCK (getF)')
                        this.mbQU = true

                        MarketQuotes = await mbapiCalls.getMarketQuotes(mbQuoteParams)

                        // Check if it shold call it a second time
                        if (MarketQuotes && MarketQuotes.data && MarketQuotes.data.length > 0) {
                            priCurr = MarketQuotes.data[0].currency.toLowerCase()
                            finCurr = MarketQuotes.data[0].financialCurrency.toLowerCase()

                            if (priCurr != finCurr) {
                                let currencySymbol2 = ''
                                if (priCurr == 'usd') {
                                    currencySymbol2 = finCurr.toUpperCase() + '=X'
                                } else {
                                    currencySymbol2 = priCurr.toUpperCase() + finCurr.toUpperCase() + '=X'
                                }
                                let mbQuoteParams2 = {
                                    symbol: currencySymbol2,
                                }
                                console.log('ATTENTION: Calling mb-api to get Quotes for CURRENCY (getF)')
                                this.mbQU = true
                                let mbMarketQuotesCurr = await mbapiCalls.getMarketQuotes(mbQuoteParams2)
                                console.log('mbMarketQuotesCurr: ', mbMarketQuotesCurr)
                                if (mbMarketQuotesCurr && mbMarketQuotesCurr.data) {
                                    MarketQuotesCurr['data'][0] = {}
                                    MarketQuotesCurr['data'][0]['regularMarketPrice'] = mbMarketQuotesCurr.data[0].regularMarketPrice
                                    console.log("mb: MarketQuotesCurr['data'][0]['regularMarketPrice']: ", MarketQuotesCurr['data'][0]['regularMarketPrice'])
                                }
                            }

                        }
                    }

                    if (!MarketQuotes || !MarketQuotes.data) {
                        this.calledByCheckFin = false // restores to initial, in case
                        this.calledByGetFin = false // restores to initial, in case
                        return this.warningMsg = `Stock not found! Please re-check the ticker and try again.`
                    } else if (MarketQuotes && MarketQuotes.data && MarketQuotes.data != null && MarketQuotes.data[0].financialCurrency == null) {
                        if (this.showSearch == true) {
                            this.calledByCheckFin = false // restores to initial, in case
                            this.calledByGetFin = false // restores to initial, in case
                            return this.warningMsg = `The asset you have selected does not seem to be a stock. Please re-check and try again.`
                        } else {
                            this.calledByCheckFin = false // restores to initial, in case
                            this.calledByGetFin = false // restores to initial, in case
                            return this.warningMsg = `The ticker you have entered does not seem to be a stock. Please re-check the ticker and try again.`
                        }
                    } else {
                        this.marketQuotes = MarketQuotes.data // Not used, except for console.log

                        // BELOW IS REDUNDANT, IT WOULD NEVER TRIGGER since currency is assigned to marketQuotesCurr.data.[0]
                        // if (MarketQuotes.data.length > 1 && MarketQuotes.data[1]) {
                        //     // MarketQuotesCurr['data'] = []
                        //     MarketQuotesCurr['data'][0] = {}
                        //     MarketQuotesCurr['data'][0]['regularMarketPrice'] = yfQuotes.data.quoteResponse.result[0].regularMarketPrice
                        //     console.log("BB MarketQuotesCurr['data'][0]['regularMarketPrice']: ", MarketQuotesCurr['data'][0]['regularMarketPrice'])
                        // }

                    }

                    if (!yfFinancials || (yfFinancials && !yfFinancials.data) || (yfFinancials.data && yfFinancials.data.errorList && yfFinancials.data.errorList.length != 0) || !IncomeStatement || IncomeStatement == null || (IncomeStatement && !IncomeStatement.data)) {
                        console.log('ATTENTION: Calling mb-api to get Income Statement (getF)')
                        this.mbIS = true
                        IncomeStatement = await mbapiCalls.getIncomeStatement(finParams)
                    }

                    if (!IncomeStatement || !IncomeStatement.data || IncomeStatement.data.error != null) {
                        this.calledByCheckFin = false // restores to initial, in case
                        this.calledByGetFin = false // restores to initial, in case
                        return this.warningMsg = `Stock not found! Please re-check the ticker and try again.`
                    } else {
                        this.incomeStatement = IncomeStatement.data
                    }

                    if (!yfFinancials || (yfFinancials && !yfFinancials.data) || (yfFinancials.data && yfFinancials.data.errorList && yfFinancials.data.errorList.length != 0) || !CashFlow || CashFlow == null || (CashFlow && !CashFlow.data)) {
                        console.log('ATTENTION: Calling mb-api to get Cash Flow (getF)')
                        this.mbCF = true
                        CashFlow = await mbapiCalls.getCashFlow(finParams)
                    }

                    if (!CashFlow || !CashFlow.data || CashFlow.data.error != null) {
                        this.calledByCheckFin = false // restores to initial, in case
                        this.calledByGetFin = false // restores to initial, in case
                        return this.warningMsg = `Stock not found! Please re-check the ticker and try again.`
                    } else {
                        this.cashFlow = CashFlow.data
                    }
                    console.log('this.balanceSheet: ', this.balanceSheet)
                    console.log('this.incomeStatement: ', this.incomeStatement)
                    console.log('this.cashFlow: ', this.cashFlow)
                    console.log('this.marketQuotes: ', this.marketQuotes)

                    // NOW ASSIGNS ALL THE TTM VALUES!!!
                    if ('TTMDate' in yhFinancials.ttm) {
                        this.TTMDate = yhFinancials.ttm.TTMDate
                        // console.log('TRRRRRRRRRRRR1', this.TTMDate, this.tryTimeseries)
                    } else {
                        // console.log('TRRRRRRRRRRRR2')
                        this.TTMDate = ''
                        this.tryTimeseries = true
                        // this.calledByGetFin = false
                        // this.calledByCheckFin = false
                        // return this.warningMsg = 'The dates relative to the Financials of this stock are missing. Not able to do the valuation.'
                    }
                    if ('CashFinancial' in yhFinancials.ttm) {
                        this.cash = yhFinancials.ttm.CashFinancial / 1000000
                    } else if ('CashAndCashEquivalents' in yhFinancials.ttm) {
                        this.cash = yhFinancials.ttm.CashAndCashEquivalents / 1000000
                    } else {
                        this.cash = 0
                        this.infoMissing = this.infoMissing + 'Cash,'
                    }
                    if ('CurrentAssets' in yhFinancials.ttm) {
                        this.currentAssets = yhFinancials.ttm.CurrentAssets / 1000000
                    } else {
                        this.currentAssets = 0
                        this.dataMissing = this.dataMissing + 'Current Assets,'
                    }
                    if ('TotalAssets' in yhFinancials.ttm) {
                        this.totalAssets = yhFinancials.ttm.TotalAssets / 1000000
                        console.log('NEWTEST0) this.totalAssets:', this.totalAssets)
                    } else {
                        this.totalAssets = 0
                        this.dataMissing = this.dataMissing + 'Total Assets,'
                    }
                    if ('CurrentLiabilities' in yhFinancials.ttm) {
                        this.currentLiabilities = yhFinancials.ttm.CurrentLiabilities / 1000000
                    } else {
                        this.currentLiabilities = 0
                        this.dataMissing = this.dataMissing + 'Current Liabilities,'
                    }
                    if ('TotalLiabilitiesNetMinorityInterest' in yhFinancials.ttm) {
                        this.totalLiabilities = yhFinancials.ttm.TotalLiabilitiesNetMinorityInterest / 1000000
                        console.log('NEWTEST0) this.totalLiabilities:', this.totalLiabilities)
                    } else {
                        this.totalLiabilities = 0
                        this.dataMissing = this.dataMissing + 'Total Liabilities,'
                    }
                    // if (this.totalAssets && this.totalLiabilities) {
                    //     this.totalEquity = this.totalAssets - this.totalLiabilities //NOTICE: below is the Stockholder equity, i.e. the equity in the company, including the sahres of minority shareholders in controlled (consolidated) subsidiaries. That could be retrieved (and stored) separately from  balanceSheetHistory/balanceSheetHistoryQuarterly + balanceSheetStatements[i].minorityInterest, but it seems more relevant to use the entire equity. NetIncome is anyway provided for Common stockholders.
                    //     // For book value, it might be more appropriate to use stockholder's equity, so it's best to keep storing it, and simply calculate total equity from totalAssets-totalLiabilities
                    // } else {
                    //     this.totalEquity = 0
                    // }
                    if ('StockholdersEquity' in yhFinancials.ttm) {
                        this.stockholderEquity = yhFinancials.ttm.StockholdersEquity / 1000000
                    } else {
                        this.stockholderEquity = 0
                        this.dataMissing = this.dataMissing + 'Stockholders Equity,' // it is not an essential parameter
                    }
                    if ('MinorityInterest' in yhFinancials.ttm) {
                        this.minorityInterest = yhFinancials.ttm.MinorityInterest / 1000000
                    } else {
                        this.minorityInterest = 0
                        // this.dataMissing = this.dataMissing + 'Stockholders Equity,' // it is not an essential parameter
                    }
                    if ('TotalRevenue' in yhFinancials.ttm) {
                        this.totalRevenue = yhFinancials.ttm.TotalRevenue / 1000000
                    } else {
                        this.totalRevenue = 0
                        this.dataMissing = this.dataMissing + 'Revenues,'
                    }
                    if ('GrossProfit' in yhFinancials.ttm) {
                        this.grossProfit = yhFinancials.ttm.GrossProfit / 1000000
                    } else {
                        this.grossProfit = 0
                        this.infoMissing = this.infoMissing + 'Gross Profit,'
                    }
                    if ('OperatingIncome' in yhFinancials.ttm) {
                        this.operatingIncome = yhFinancials.ttm.OperatingIncome / 1000000
                    } else {
                        this.operatingIncome = 0
                        this.dataMissing = this.dataMissing + 'Operating Income,'
                    }
                    if ('NetIncomeCommonStockholders' in yhFinancials.ttm) {
                        this.netIncomeCommonShares = yhFinancials.ttm.NetIncomeCommonStockholders / 1000000
                    } else {
                        this.netIncomeCommonShares = 0
                        this.dataMissing = this.dataMissing + 'Net Income,'
                    }
                    if ('Depreciation' in yhFinancials.ttm) {
                        this.depreciation = yhFinancials.ttm.Depreciation / 1000000
                    } else if (yhFinancials.ttm.DepreciationAndAmortization) {
                        this.depreciation = yhFinancials.ttm.DepreciationAndAmortization / 1000000
                    } else {
                        this.depreciation = 0
                        this.depreciationMissing = true
                    }
                    //<--

                    //////////////////////////////////// CALLING TIMESERIES //////////////////////////////////////
                    // const date1 = new Date(493590046000).toLocaleDateString("en-US")
                    // const date2 = new Date(1685645090000).toLocaleDateString("en-US")
                    // console.log('Date 1: ', date1)
                    // console.log('Date 2: ', date2)
                    if (this.tryTimeseries == true || this.dataMissing != '') { // IN CASE THAT ITT FAILED TRIES TO CALL TIME SERIES AND OVERWRITE THE TTM DATA
                        console.log('TRRRRRRRRRRRR1', this.TTMDate, this.tryTimeseries, this.dataMissing)

                        this.dataMissing = ''
                        this.infoMissing = ''
                        this.additionalWarning_1 = ''
                        this.depreciationMissing = false
                        this.tryTimeseries = false

                        const currentTime = Math.floor(Date.now() / 1000) + (3600 * 24) // gives the unix time in seconds of tomorrow. Notice that Date.now() gives the Unix timestamp of current time in milliseconds
                        console.log('Current Time: ', currentTime)

                        const date3 = new Date(currentTime * 1000).toLocaleDateString("en-US")
                        console.log('Date 3: ', date3)
                        // const dims = ['quarterlyTotalAssets', 'quarterlyGrossProfit', 'quarterlyCashAndCashEquivalents', 'quarterlyDepreciationAndAmortization',
                        //         'quarterlyEndCashPosition', 'quarterlyNetIncomeCommonStockholders', 'quarterlyDilutedAverageShares', 'quarterlyStockholdersEquity',
                        //         'quarterlyBasicAverageShares', 'quarterlyCurrentAssets', 'quarterlyOperatingIncome', 'quarterlyTotalRevenue', 'quarterlyCurrentLiabilities']

                        const dims = ['quarterlyNetIncomeCommonStockholders', 'quarterlyTotalAssets', 'quarterlyStockholdersEquity',
                            'quarterlyCurrentAssets', 'quarterlyCashAndCashEquivalents', 'quarterlyGrossProfit', 'quarterlyCurrentLiabilities',
                            'quarterlyDepreciationAndAmortization', 'quarterlyOperatingIncome', 'quarterlyTotalRevenue', 'quarterlyTotalLiabilitiesNetMinorityInterest',
                            'quarterlyEndCashPosition', 'trailingEndCashPosition', 'quarterlyMinorityInterests', 'trailingMinorityInterests'
                        ]

                        // 'quarterlyDilutedAverageShares', 'quarterlyBasicAverageShares'

                        let type = dims.join(',')

                        let tsParams = {
                            symbol: asset.split(' ')[0],
                            period1: 493590046,
                            period2: currentTime,
                            type: type
                        }
                        let qResult = null
                        let qData = await yfapiCalls.getTimeSeries(tsParams)

                        if (qData && qData.data && qData.data.timeseries && qData.data.timeseries.result) {
                            console.log('qData.data 01: ', qData.data.timeseries.result)
                        } else {
                            console.log('TRYING TO CALL qData A SECOND TIME in getF')
                            this.qData2 = true
                            qData = await yfapiCalls.getTimeSeries(tsParams)
                            if (qData && qData.data && qData.data.timeseries && qData.data.timeseries.result) {
                                console.log('MANAGED qData.data 02: ', qData.data.timeseries.result)
                            } else {
                                console.log('FAILED AGAIN TO RETRIEVE DATA FROM qData in getF')
                            }
                        }
                        if (qData && qData.data && qData.data.timeseries && qData.data.timeseries.result && qData.data.timeseries.result != null && !qData.data.timeseries.error) {
                            console.log('qData PASSED')
                            qResult = qData.data.timeseries.result
                        } else {
                            console.log('qData NOT PASSED')
                        }
                        let matchedType = null
                        let dataObj = {}
                        let latestDate = ''
                        if (qResult != null) {
                            dims.forEach(item => {
                                matchedType = qResult.find(obj => obj.meta.type == item)
                                let amount = 0
                                if (matchedType && matchedType != null && matchedType[item] && matchedType[item] != null && matchedType[item].length > 3) {
                                    let latestIndex = matchedType[item].length - 1
                                    for (let i = 0; i < 4; ++i) {
                                        // console.log('i is: ', i)
                                        // console.log('latestIndex: ', latestIndex)
                                        // console.log('matchedType[item][latestIndex-i]: ', matchedType[item][latestIndex-i])
                                        if (matchedType[item][latestIndex - i] && matchedType[item][latestIndex - i]['reportedValue'] && matchedType[item][latestIndex - i]['reportedValue']['raw'] && matchedType[item][latestIndex - i]['reportedValue']['raw'] != null && matchedType[item][latestIndex - i]['reportedValue']['raw'] != 0) {
                                            amount = amount + Number(matchedType[item][latestIndex - i]['reportedValue']['raw'])
                                            // console.log('item: ', item)
                                            // console.log('i: ', i)

                                            if (item == 'quarterlyNetIncomeCommonStockholders' && i == 0) {
                                                // console.log("matchedType[item]: ", matchedType[item])
                                                // console.log("matchedType[item][latestIndex]: ", matchedType[item][latestIndex])
                                                // console.log("matchedType[item][latestIndex]['asOfDate']: ", matchedType[item][latestIndex]['asOfDate'])
                                                latestDate = matchedType[item][latestIndex]['asOfDate']
                                                console.log('latestDate: ', latestDate)
                                            } else if (i == 0) {
                                                if (matchedType[item][latestIndex]['asOfDate'] != latestDate) {
                                                    console.log('ATTENTION: Different Dates')
                                                }
                                            }
                                        }
                                        if (item == 'quarterlyTotalAssets' || item == 'quarterlyStockholdersEquity' || item == 'quarterlyCurrentAssets' || item == 'quarterlyCashAndCashEquivalents' || item == 'quarterlyCurrentLiabilities' || item == 'quarterlyTotalLiabilitiesNetMinorityInterest') {
                                            break
                                        }
                                    }
                                }
                                dataObj[item] = amount
                            })
                            console.log('dataObj: ', dataObj)
                            console.log('latestDate: ', latestDate)
                        }
                        let timeSeriesSum = null
                        if (dataObj) {
                            // let timeSeriesSum = 0
                            for (const key in dataObj) {
                                if (key in dataObj) {
                                    const value = dataObj[key]
                                    if (!isNaN(value) && typeof value === 'number') {
                                        timeSeriesSum += value
                                    }
                                }
                            }
                        }
                        if (latestDate != '' && timeSeriesSum != 0) {
                            this.TTMDate = latestDate
                        } else if (this.tryHistoryData()) {
                            this.historyFilled = true
                        } else { //it failed
                            this.TTMDate = ''
                            this.calledByGetFin = false
                            this.calledByCheckFin = false

                            // let example
                            // console.log('example')
                            // console.log(!example)
                            // example = null
                            // console.log('null')
                            // console.log(!example)
                            // example = 0
                            // console.log('0')
                            // console.log(!example)
                            // example = false
                            // console.log('false')
                            // console.log(!example)
                            // example = []
                            // console.log('[]')
                            // console.log(!example)
                            // example = {}
                            // console.log('{}')
                            // console.log(!example)
                            return this.warningMsg = 'Some key financial data for this stock are missing. Not able to do the valuation.'
                        }
                        if (!this.historyFilled) {
                            if (dataObj['quarterlyCashAndCashEquivalents'] != 0) {
                                this.cash = dataObj['quarterlyCashAndCashEquivalents'] / 1000000
                            } else {
                                this.cash = 0
                                this.infoMissing = this.infoMissing + 'Cash,'
                            }
                            if (dataObj['quarterlyCurrentAssets'] != 0) {
                                this.currentAssets = dataObj['quarterlyCurrentAssets'] / 1000000
                            } else {
                                this.currentAssets = 0
                                this.dataMissing = this.dataMissing + 'Current Assets,'
                            }
                            if (dataObj['quarterlyTotalAssets'] != 0) {
                                this.totalAssets = dataObj['quarterlyTotalAssets'] / 1000000
                            } else {
                                this.totalAssets = 0
                                this.dataMissing = this.dataMissing + 'Total Assets,'
                            }
                            if (dataObj['quarterlyCurrentLiabilities'] != 0) {
                                this.currentLiabilities = dataObj['quarterlyCurrentLiabilities'] / 1000000
                            } else {
                                this.currentLiabilities = 0
                                this.dataMissing = this.dataMissing + 'Current Liabilities,'
                            }
                            if (dataObj['quarterlyTotalLiabilitiesNetMinorityInterest'] != 0) {
                                // console.log('AGAIN totalLiabilities:',this.totalLiabilities)
                                // console.log('AGAIN dataObj[quarterlyTotalLiabilitiesNetMinorityInterest] :',dataObj['quarterlyTotalLiabilitiesNetMinorityInterest'] )
                                // console.log('AGAIN totalAssets:',this.totalAssets)
                                // console.log(dataObj['quarterlyTotalLiabilitiesNetMinorityInterest'] / 1000000==this.totalAssets)
                                if (dataObj['quarterlyTotalLiabilitiesNetMinorityInterest'] / 1000000 == this.totalAssets && this.totalLiabilities != null) {
                                    // THIS IS IN CASE THAT TOTALLIABILITIES INCLUDES EQUITY BY MISTAKE (HAPPENED WITH CVX STOCK FOR EXAMPLE)
                                } else {
                                    this.totalLiabilities = dataObj['quarterlyTotalLiabilitiesNetMinorityInterest'] / 1000000
                                }

                            } else {
                                this.totalLiabilities = 0
                                this.dataMissing = this.dataMissing + 'Total Liabilities,'
                            }
                            // if (this.totalAssets && this.totalLiabilities) {
                            //     this.totalEquity = this.totalAssets - this.totalLiabilities //NOTICE: below is the Stockholder equity, i.e. the equity in the company, including the sahres of minority shareholders in controlled (consolidated) subsidiaries. That could be retrieved (and stored) separately from  balanceSheetHistory/balanceSheetHistoryQuarterly + balanceSheetStatements[i].minorityInterest, but it seems more relevant to use the entire equity. NetIncome is anyway provided for Common stockholders.
                            //     // For book value, it might be more appropriate to use stockholder's equity, so it's best to keep storing it, and simply calculate total equity from totalAssets-totalLiabilities
                            // } else {
                            //     this.totalEquity = 0
                            // }
                            if (dataObj['quarterlyStockholdersEquity'] != 0) {
                                this.stockholderEquity = dataObj['quarterlyStockholdersEquity'] / 1000000
                            } else {
                                this.stockholderEquity = 0
                                this.dataMissing = this.dataMissing + 'Stockholders Equity,' // it is not an essential parameter
                            }
                            if (dataObj['quarterlyTotalLiabilitiesNetMinorityInterest'] != 0) {
                                this.minorityInterest = 0
                            } else {
                                this.minorityInterest = 0
                                // this.dataMissing = this.dataMissing + 'Stockholders Equity,' // it is not an essential parameter
                            }
                            if (dataObj['quarterlyTotalRevenue'] != 0) {
                                this.totalRevenue = dataObj['quarterlyTotalRevenue'] / 1000000
                            } else {
                                this.totalRevenue = 0
                                this.dataMissing = this.dataMissing + 'Revenues,'
                            }
                            if (dataObj['quarterlyGrossProfit'] != 0) {
                                this.grossProfit = dataObj['quarterlyGrossProfit'] / 1000000
                            } else {
                                this.grossProfit = 0
                                this.infoMissing = this.infoMissing + 'Gross Profit,'
                            }
                            if (dataObj['quarterlyOperatingIncome'] != 0) {
                                this.operatingIncome = dataObj['quarterlyOperatingIncome'] / 1000000
                            } else {
                                this.operatingIncome = 0
                                this.dataMissing = this.dataMissing + 'Operating Income,'
                            }
                            if (dataObj['quarterlyNetIncomeCommonStockholders'] != 0) {
                                this.netIncomeCommonShares = dataObj['quarterlyNetIncomeCommonStockholders'] / 1000000
                            } else {
                                this.netIncomeCommonShares = 0
                                this.dataMissing = this.dataMissing + 'Net Income,'
                            }
                            if (dataObj['quarterlyDepreciationAndAmortization'] != 0) {
                                this.depreciation = dataObj['quarterlyDepreciationAndAmortization'] / 1000000
                            } else {
                                this.depreciation = 0
                                this.depreciationMissing = true
                            }
                        }
                    }
                    console.log('TRRRRRRRRR NO')
                    console.log('NEWTEST NO) this.totalLiabilities:', this.totalLiabilities)

                    //////////////////////////////////// END CALLING TIMESERIES //////////////////////////////////////
                    if (MarketQuotes.data[0].sharesOutstanding) {
                        this.sharesOutstanding = (MarketQuotes.data[0].sharesOutstanding) / 1000000
                    } else {
                        this.sharesOutstanding = 0
                        this.dataMissing = this.dataMissing + 'Shares Outstanding,'
                    }
                    if (MarketQuotes.data[0].shortRatio) {
                        this.shortDays = (MarketQuotes.data[0].shortRatio)
                    } else {
                        this.shortDays = 'n.a.'
                    }
                    if (MarketQuotes.data[0].shortPercentFloat) {
                        this.shortFloat = (MarketQuotes.data[0].shortPercentFloat)
                    } else {
                        this.shortFloat = 'n.a.'
                    }
                    if (MarketQuotes.data[0].financialCurrency) {
                        this.financialCurrency = MarketQuotes.data[0].financialCurrency.toLowerCase()
                    } else {
                        this.financialCurrency = 'usd' // 20231230
                        this.additionalWarning_1 = `ATTENTION: The currency for the Financial Statements of this stock was missing, therefore we have arbitrarily assumed it to be USD in order to proceed with the calculation, but it is important to verify that assumption.` // 20231230
                        // 20231230 ATTENTION!!! the 2 lines above have replaced the ones below
                        // this.financialCurrency = ''
                        // this.calledByGetFin = false
                        // this.calledByCheckFin = false
                        // return this.warningMsg = 'The currency for the Financial Statements of this stock is missing. We are not able to perform the valuation.'
                    }
                    if (MarketQuotes.data[0].currency) {
                        this.priceCurrency = MarketQuotes.data[0].currency.toLowerCase()
                    } else {
                        this.priceCurrency = ''
                        this.calledByGetFin = false
                        this.calledByCheckFin = false
                        return this.warningMsg = 'The currency for the price of this stock is missing. Not able to do the valuation.'
                    }
                    if (MarketQuotes.data[0].regularMarketPrice) {
                        this.lastPrice = MarketQuotes.data[0].regularMarketPrice
                        if (MarketQuotes.data[0].currency == 'GBp') {
                            this.lastPrice = this.lastPrice / 100
                        }
                    } else {
                        this.lastPrice = '0'
                        this.infoMissing = this.infoMissing + 'Latest Price,'
                    }
                    if (MarketQuotes.data[0].shortName) {
                        this.shortName = MarketQuotes.data[0].shortName
                    } else {
                        this.shortName = ''
                    }
                    if (MarketQuotes.data[0].fullExchangeName) {
                        this.exchange = MarketQuotes.data[0].fullExchangeName
                    } else {
                        this.exchange = ''
                    }
                    this.fx = 1
                    if (this.priceCurrency != this.financialCurrency) {
                        if (!MarketQuotesCurr.data[0].regularMarketPrice || MarketQuotesCurr.data[0].regularMarketPrice == null || MarketQuotesCurr.data[0].regularMarketPrice == 0) {
                            console.log('could not retrieve fx although is needed, leaving as 1 just to continue')
                        } else {
                            this.fx = MarketQuotesCurr.data[0].regularMarketPrice
                            console.log('fx: ', this.fx)
                        }
                    }

                    // NOW ASSIGNS ALL THE HISTORY VALUES!!!
                    for (let i = 0; i < 3; i++) {
                        if ('TotalRevenue' in yhFinancials.hist && yhFinancials.hist.TotalRevenue[i]) {
                            this.histRevenues[i] = yhFinancials.hist.TotalRevenue[i] / 1000000
                        } else {
                            this.histRevenues[i] = 0
                            this.histMissing = this.histMissing + 'Revenues,'
                        }

                        if ('OperatingIncome' in yhFinancials.hist && yhFinancials.hist.OperatingIncome[i]) {
                            this.histOpIncome[i] = yhFinancials.hist.OperatingIncome[i] / 1000000
                        } else {
                            this.histOpIncome[i] = 0
                            this.histMissing = this.histMissing + 'Operating Income,'
                        }
                        if ('NetIncomeCommonStockholders' in yhFinancials.hist && yhFinancials.hist.NetIncomeCommonStockholders[i]) {
                            this.histNetIncome[i] = yhFinancials.hist.NetIncomeCommonStockholders[i] / 1000000
                        } else {
                            this.histNetIncome[i] = 0
                            this.histMissing = this.histMissing + 'Net Income,'
                        }
                        ////////// NEW //////////
                        if ('CurrentAssets' in yhFinancials.hist && yhFinancials.hist.CurrentAssets[i]) {
                            this.histCurrentAssets[i] = yhFinancials.hist.CurrentAssets[i] / 1000000
                        } else {
                            this.histCurrentAssets[i] = 0
                            this.histMissing = this.histMissing + 'Current Assets,'
                        }
                        if (i == 0) {
                            if ('CurrentLiabilities' in yhFinancials.hist && yhFinancials.hist.CurrentLiabilities[i]) {
                                this.histCurrentLiabilities = yhFinancials.hist.CurrentLiabilities[i] / 1000000
                            } else {
                                this.histCurrentLiabilities = 0
                                // This is here just to replace current values in case they are mnissing. The historical value will not be stored in the database
                            }
                            if ('CashFinancial' in yhFinancials.hist && yhFinancials.hist.CashFinancial[i]) {
                                this.histCash = yhFinancials.hist.CashFinancial[i] / 1000000
                            } else if ('CashAndCashEquivalents' in yhFinancials.hist && yhFinancials.hist.CashAndCashEquivalents[i]) {
                                this.histCash = yhFinancials.hist.CashAndCashEquivalents[i] / 1000000
                            } else {
                                this.histCash = 0
                                // This is here just to replace current values in case they are missing. The historical value will not be stored in the database
                            }
                            if ('GrossProfit' in yhFinancials.hist && yhFinancials.hist.GrossProfit[i]) {
                                this.histGrossProfit = yhFinancials.hist.GrossProfit[i] / 1000000
                            } else {
                                this.histGrossProfit = 0
                                // This is here just to replace current values in case they are missing. The historical value will not be stored in the database
                            }
                            if ('Depreciation' in yhFinancials.hist && yhFinancials.hist.Depreciation[i]) {
                                this.histDepreciation = yhFinancials.hist.Depreciation[i] / 1000000
                            } else if ('DepreciationAndAmortization' in yhFinancials.hist && yhFinancials.hist.DepreciationAndAmortization[i]) {
                                this.histDepreciation = yhFinancials.hist.DepreciationAndAmortization[i] / 1000000
                            } else {
                                this.histDepreciation = 0
                                // This is here just to replace current values in case they are missing. The historical value will not be stored in the database
                            }
                            if ('TTMDate' in yhFinancials.hist && yhFinancials.hist.TTMDate[i]) {
                                this.histBsDate = yhFinancials.hist.TTMDate[i]
                            } else {
                                this.histBsDate = ''
                                // This is here just to replace current values in case they are missing. The historical value will not be stored in the database
                            }
                            if ('TTMDate' in yhFinancials.hist && yhFinancials.hist.TTMDate[i]) {
                                this.histCfDate = yhFinancials.hist.TTMDate[i]
                            } else {
                                this.histCfDate = ''
                                // This is here just to replace current values in case they are missing. The historical value will not be stored in the database
                            }
                        }
                        if ('TotalLiabilitiesNetMinorityInterest' in yhFinancials.hist && yhFinancials.hist.TotalLiabilitiesNetMinorityInterest[i]) {
                            this.histTotalLiabilities[i] = yhFinancials.hist.TotalLiabilitiesNetMinorityInterest[i] / 1000000
                        } else {
                            this.histTotalLiabilities[i] = 0
                            this.histMissing = this.histMissing + 'Total Liabilities,'
                        }
                        if ('TotalAssets' in yhFinancials.hist && yhFinancials.hist.TotalAssets[i]) {
                            this.histTotalAssets[i] = yhFinancials.hist.TotalAssets[i] / 1000000
                        } else {
                            this.histTotalAssets[i] = 0
                            this.histMissing = this.histMissing + 'Total Assets,'
                        }
                        if (this.histTotalAssets[i] && this.histTotalLiabilities[i]) {
                            this.histTotalEquity[i] = this.histTotalAssets[i] - this.histTotalLiabilities[i]
                        } else {
                            this.histTotalEquity[i] = 0
                            // this.histMissing = this.histMissing + 'Total Equity,'
                        }
                        if ('StockholdersEquity' in yhFinancials.hist && yhFinancials.hist.StockholdersEquity[i]) {
                            this.histStockholderEquity[i] = yhFinancials.hist.StockholdersEquity[i] / 1000000
                        } else {
                            this.histStockholderEquity[i] = 0
                            this.histMissing = this.histMissing + 'Stockholders Equity,'
                        }
                        if ('MinorityInterest' in yhFinancials.hist && yhFinancials.hist.MinorityInterest[i]) {
                            this.histMinorityInterest[i] = yhFinancials.hist.MinorityInterest[i] / 1000000
                        } else {
                            this.histMinorityInterest[i] = 0
                            // this.histMissing = this.histMissing + 'Equity,'
                        }
                        console.log('TTTT Difference between totalEquity and (stockholderEquity+minorityInterest 2: )', i, this.histTotalAssets[i] - this.histTotalLiabilities[i] - (this.histStockholderEquity[i] + this.histMinorityInterest[i]))
                        ////////// NEW //////////
                        if ('TTMDate' in yhFinancials.hist && yhFinancials.hist.TTMDate[i]) {
                            this.revDate[i] = yhFinancials.hist.TTMDate[i]
                        } else {
                            this.revDate[i] = ''
                            this.histMissing = this.histMissing + 'Financials dates,'
                        }
                        if ('TTMDate' in yhFinancials.hist && yhFinancials.hist.TTMDate[i]) {
                            this.revYear[i] = yhFinancials.hist.TTMDate[i].slice(0, 4)
                        } else {
                            this.revYear[i] = ''
                            this.histMissing = this.histMissing + 'Financial years,'
                        }
                        this.opMarg[i] = Math.round((this.histOpIncome[i] / this.histRevenues[i]) * 1000) / 10
                        this.profMarg[i] = Math.round((this.histNetIncome[i] / this.histRevenues[i]) * 1000) / 10
                        this.ICtRev[i] = (Math.round((this.histTotalLiabilities[i] - this.histCurrentAssets[i] + this.histTotalEquity[i]) / this.histRevenues[i] * 1000) / 10)
                        if ((this.histTotalLiabilities[i] - this.histCurrentAssets[i] + this.histTotalEquity[i]) != 0) {
                            this.DtICap[i] = (Math.round((this.histTotalLiabilities[i] - this.histCurrentAssets[i]) / (this.histTotalLiabilities[i] - this.histCurrentAssets[i] + this.histTotalEquity[i]) * 1000) / 10)
                        } else {
                            this.DtICap[i] = 0
                        }
                        if (i > 0) {
                            this.revGrowth[i] = Math.round(((this.histRevenues[i - 1] - this.histRevenues[i]) / this.histRevenues[i]) * 1000) / 10 // 0 is the latest
                            if (this.histShares[i - 1] && this.histShares[i]) { // 0 is the oldest
                                this.sharesGrowth[i] = Math.round(((this.histShares[i] - this.histShares[i - 1]) / this.histShares[i - 1]) * 1000) / 10
                            } else {
                                this.sharesGrowth[i] = 0
                            }
                        }
                    }
                    // <--

                    // These are the averages. If any of them is not available, then it is taken out of the average (this is achieved by replacing with block below)
                    // this.revGrowth[4] = Math.round(((this.revGrowth[1] + this.revGrowth[2] + this.revGrowth[3]) / 3) * 10) / 10
                    // this.opMarg[4] = Math.round(((this.opMarg[0] + this.opMarg[1] + this.opMarg[2] + this.opMarg[3]) / 4) * 10) / 10
                    // this.profMarg[4] = Math.round(((this.profMarg[0] + this.profMarg[1] + this.profMarg[2] + this.profMarg[3]) / 4) * 10) / 10
                    // this.sharesGrowth[4] = Math.round(((this.sharesGrowth[1] + this.sharesGrowth[2] + this.sharesGrowth[3]) / 3) * 10) / 10
                    // this.ICtRev[4] = Math.round(((this.ICtRev[0] + this.ICtRev[1] + this.ICtRev[2] + this.ICtRev[3]) / 4) * 10) / 10
                    // this.DtICap[4] = Math.round(((this.DtICap[0] + this.DtICap[1] + this.DtICap[2] + this.DtICap[3]) / 4) * 10) / 10

                    // Calculate averages, excluding non-available data
                    this.revGrowth[4] = Math.round(this.calcAverage([this.revGrowth[1], this.revGrowth[2], this.revGrowth[3]]) * 10) / 10
                    this.opMarg[4] = Math.round(this.calcAverage([this.opMarg[0], this.opMarg[1], this.opMarg[2], this.opMarg[3]]) * 10) / 10
                    this.profMarg[4] = Math.round(this.calcAverage([this.profMarg[0], this.profMarg[1], this.profMarg[2], this.profMarg[3]]) * 10) / 10
                    this.sharesGrowth[4] = Math.round(this.calcAverage([this.sharesGrowth[1], this.sharesGrowth[2], this.sharesGrowth[3]]) * 10) / 10
                    this.ICtRev[4] = Math.round(this.calcAverage([this.ICtRev[0], this.ICtRev[1], this.ICtRev[2], this.ICtRev[3]]) * 10) / 10
                    this.DtICap[4] = Math.round(this.calcAverage([this.DtICap[0], this.DtICap[1], this.DtICap[2], this.DtICap[3]]) * 10) / 10

                    // Replace current values with historical in case that current are missing and historical are available
                    if (!this.historyFilled) { // No need to do it if the data loaded are already the history ones
                        if (this.dataMissing != '' && this.TTMDate == this.revDate[0]) {
                            console.log('Replacing missing data from TTM Income Statement: ', this.dataMissing, this.infoMissing)
                            if (this.dataMissing != '' && this.dataMissing.indexOf('Revenues,') != -1 && this.histRevenues[0] != 0) {
                                this.totalRevenue = this.histRevenues[0]
                                this.dataMissing = this.dataMissing.replace('Revenues,', '')
                            }
                            if (this.dataMissing != '' && this.dataMissing.indexOf('Operating Income,') != -1 && this.histOpIncome[0] != 0) {
                                this.operatingIncome = this.histOpIncome[0]
                                this.dataMissing = this.dataMissing.replace('Operating Income,', '')
                            }
                            if (this.dataMissing != '' && this.dataMissing.indexOf('Net Income,') != -1 && this.histNetIncome[0] != 0) {
                                this.netIncomeCommonShares = this.histNetIncome[0]
                                this.dataMissing = this.dataMissing.replace('Net Income,', '')
                            }
                            if (this.infoMissing != '' && this.infoMissing.indexOf('Gross Profit,') != -1 && this.histGrossProfit != 0) {
                                this.grossProfit = this.histGrossProfit
                                this.infoMissing = this.infoMissing.replace('Gross Profit,', '')
                            }
                            // console.log('this.infoMissing after replacing: ', this.infoMissing)
                            // console.log('this.dataMissing after replacing: ', this.dataMissing)
                            // console.log("replacing -  this.infoMissing == '': ", this.infoMissing == '')
                            // console.log("replacing - this.dataMissing == '': ", this.dataMissing == '')
                        }
                        if (this.dataMissing != '' && this.TTMDate == this.histBsDate) {
                            console.log('Replacing missing data from TTM Balance Sheet: ', this.dataMissing, this.infoMissing)
                            if (this.dataMissing != '' && this.dataMissing.indexOf('Total Assets,') != -1 && this.histTotalAssets[0] != 0) {
                                this.totalAssets = this.histTotalAssets[0]
                                this.dataMissing = this.dataMissing.replace('Total Assets,', '')
                            }
                            if (this.dataMissing != '' && this.dataMissing.indexOf('Current Assets,') != -1 && this.histCurrentAssets[0] != 0) {
                                this.currentAssets = this.histCurrentAssets[0]
                                this.dataMissing = this.dataMissing.replace('Current Assets,', '')
                            }
                            if (this.dataMissing != '' && this.dataMissing.indexOf('Total Liabilities,') != -1 && this.histTotalLiabilities[0] != 0) {
                                this.totalLiabilities = this.histTotalLiabilities[0]
                                this.dataMissing = this.dataMissing.replace('Total Liabilities,', '')
                            }
                            if (this.dataMissing != '' && this.dataMissing.indexOf('Stockholders Equity,') != -1 && this.histStockholderEquity[0] != 0) {
                                this.stockholderEquity = this.histStockholderEquity[0]
                                this.dataMissing = this.dataMissing.replace('Stockholders Equity,', '')
                            }
                            if (this.dataMissing != '' && this.dataMissing.indexOf('Current Liabilities,') != -1 && this.histCurrentLiabilities != 0) {
                                this.currentLiabilities = this.CurrentLiabilities
                                this.dataMissing = this.dataMissing.replace('Current Liabilities,', '')
                            }
                            if (this.infoMissing != '' && this.infoMissing.indexOf('Cash,') != -1 && this.histCash != 0) {
                                this.cash = this.histCash
                                this.infoMissing = this.infoMissing.replace('Cash,', '')
                            }
                        }
                        if (this.depreciationMissing == true && this.TTMDate == this.histCfDate) {
                            console.log('Replacing missing Depreciation from TTM Cash Flow: ', this.depreciationMissing, this.depreciation, this.histDepreciation)
                            if (this.histDepreciation != 0) {
                                this.depreciation = this.histDepreciation
                                this.depreciationMissing = false
                                console.log('Replaced missing Depreciation? if yes, then false: ', this.depreciationMissing)
                            }
                        }
                    }

                    if (this.totalAssets && this.totalLiabilities) {
                        this.totalEquity = this.totalAssets - this.totalLiabilities //NOTICE: below is the Stockholder equity, i.e. the equity in the company, including the shares of minority shareholders in controlled (consolidated) subsidiaries. That could be retrieved (and stored) separately from  balanceSheetHistory/balanceSheetHistoryQuarterly + balanceSheetStatements[i].minorityInterest, but it seems more relevant to use the entire equity. NetIncome is anyway provided for Common stockholders.
                        // For book value, it might be more appropriate to use stockholder's equity, so it's best to keep storing it, and simply calculate total equity from totalAssets-totalLiabilities
                    } else {
                        this.totalEquity = 0
                    }
                    console.log('TTTT Difference between totalEquity and (stockholderEquity+minorityInterest 1: )', this.totalEquity - (this.stockholderEquity + this.minorityInterest))

                    this.marketCap = Math.round(this.sharesOutstanding * this.lastPrice).toLocaleString('en-US')
                    this.PeRatio = (Math.round(this.sharesOutstanding * this.lastPrice / this.netIncomeCommonShares * this.fx * 10) / 10).toLocaleString('en-US') // in parentheses is MarketCap
                    this.grossMargin = (Math.round(this.grossProfit / this.totalRevenue * 100 * 10) / 10).toLocaleString('en-US') + ' %'
                    this.operatingMargin = (Math.round(this.operatingIncome / this.totalRevenue * 100 * 10) / 10).toLocaleString('en-US') + ' %'
                    this.profitMargin = (Math.round(this.netIncomeCommonShares / this.totalRevenue * 100 * 10) / 10).toLocaleString('en-US') + ' %'
                    this.netDebtIndicator = Math.round(this.totalLiabilities - this.currentAssets).toLocaleString('en-US')
                    this.roic = (Math.round(this.netIncomeCommonShares / ((this.totalLiabilities - this.currentAssets) + this.stockholderEquity) * 100 * 10) / 10).toLocaleString('en-US') + ' %' // in parentheses is NetDebtIndicator
                    this.roe = (Math.round(this.netIncomeCommonShares / this.stockholderEquity * 100 * 10) / 10).toLocaleString('en-US') + ' %'
                    this.investedCapToRev = (Math.round((this.totalLiabilities - this.currentAssets + this.totalEquity) / this.totalRevenue * 1000) / 10).toLocaleString('en-US') + ' %'
                    this.netDebtToInvestedCap = (Math.round((this.totalLiabilities - this.currentAssets) / (this.totalLiabilities - this.currentAssets + this.totalEquity) * 1000) / 10).toLocaleString('en-US') + ' %'
                    if (Number(this.netDebtToInvestedCap) < 0) {
                        this.netDebtToInvestedCap = `0`.toLocaleString('en-US') + ' %'
                    }
                    this.netWorkingCap = Math.round(this.currentAssets - this.currentLiabilities).toLocaleString('en-US')
                    this.EbitdaRatio = (Math.round(((this.totalLiabilities - this.currentAssets) + this.totalEquity) / (this.operatingIncome + this.depreciation) * 10) / 10).toLocaleString('en-US') // in parentheses is NetDebtIndicator
                    if (this.stockholderEquity != 0) {
                        this.bookValueFinCurr = Math.round(this.stockholderEquity / this.sharesOutstanding).toFixed(1)
                        this.bookValuePriCurr = Math.round((this.stockholderEquity / this.sharesOutstanding) / this.fx).toFixed(1)
                    } else {
                        this.bookValueFinCurr = Math.round(this.totalEquity / this.sharesOutstanding).toFixed(1)
                        this.bookValuePriCurr = Math.round((this.totalEquity / this.sharesOutstanding) / this.fx).toFixed(1)
                    }
                }
                console.log('this.dataMissing: ', this.dataMissing)
                console.log('this.histMissing: ', this.histMissing)
                console.log('this.depreciationMissing: ', this.depreciationMissing)

                if (this.dataMissing != '' && this.depreciationMissing) {
                    this.dataMissing = this.dataMissing + 'Depreciation,'
                    this.depreciationMissing = false
                }
                if (this.warningMsg == 'Loading data, please wait...') {
                    this.warningMsg = ''
                }
                if (this.dataMissing != '') {
                    this.dontSave = true
                    this.warningMsg = `ATTENTION! Some data for this stock (${this.dataMissing.slice(0, -1)}) are missing and have been set to zero. Those data are needed for the Valuation, therefore it is recommended to retrieve them elsewhere and enter them manually in order to calculate the Valuation. `
                    console.log('this.warningMsg: ', this.warningMsg)
                }
                if (this.warningMsg == '' && this.infoMissing != '') {
                    this.warningMsg = this.warningMsg + `Some data (${this.infoMissing.slice(0, -1)}), useful as indicators but not essential for the calculation, are missing. `
                } else if (this.warningMsg != '' && this.infoMissing != '') {
                    this.warningMsg = this.warningMsg + `Other data (${this.infoMissing.slice(0, -1)}), useful as indicators but not essential for the calculation, are also missing. `
                }
                if (this.warningMsg == '' && this.histMissing != '') {
                    this.dontSave = true
                    this.warningMsg = `Some historical data (${this.histMissing.slice(0, -1)}) are missing. Therefore, some of the pre-filled "Valuation Parameters" should not be trusted. In order to improve the accuracy, those Parameters should be entered manually based on a more complete set of historical data collected from other sources. `
                } else if (this.warningMsg != '' && this.histMissing != '') {
                    this.dontSave = true
                    this.warningMsg = this.warningMsg + `Additionally, some historical data (${this.histMissing.slice(0, -1)}) are missing. Therefore, some of the pre-filled "Valuation Parameters" should not be trusted. In order to improve the accuracy, the Valuation Parameters should be entered manually based on based on a more complete set of historical data collected from other sources. `
                }
                if (this.depreciationMissing && this.warningMsg && this.warningMsg != 'Loading data, please wait...') {
                    this.warningMsg = this.warningMsg + `Depreciation missing from the Financial Data available for this stock. This may not have large effect on the valuation in some methods, however to increase accuracy you may want to retrieve the 12-month Depreciation form other sources and enter below, before calculating the Valuation.`
                } else if (this.depreciationMissing && (!this.warningMsg || this.warningMsg == 'Loading data, please wait...')) {
                    this.warningMsg = `Depreciation missing from the Financial Data available for this stock. This may not have large effect on the valuation in some sectors, however to increase accuracy you may want to retrieve the 12-month Depreciation form other sources and enter below, before calculating the Valuation.`
                }
                if (this.additionalWarning_1 && this.warningMsg && this.warningMsg != 'Loading data, please wait...') {
                    this.warningMsg = this.warningMsg + this.additionalWarning_1
                } else if (this.depreciationMissing && (!this.warningMsg || this.warningMsg == 'Loading data, please wait...')) {
                    this.warningMsg = this.additionalWarning_1
                }
                if (this.historyFilled) {
                    if (!this.warningMsg || this.warningMsg == 'Loading data, please wait...') {
                        this.dataMissing = ' '
                        this.warningMsg = `ATTENTION! The last TTM data were missing or incomplete: data from the last complete financial year have been used instead.`
                    } else if (this.warningMsg && this.warningMsg != 'Loading data, please wait...') {
                        if (this.warningMsg.startsWith('ATTENTION! ')) {
                            this.warningMsg = this.warningMsg.slice('ATTENTION! '.length)
                        }
                        this.warningMsg = `ATTENTION! The last TTM data were missing or incomplete: data from the last complete financial year have been used instead.  ${this.warningMsg}`
                    }
                }
                // this.dontSave = true // ALWAYS COMMENTED OUT, EXCEPT IN DEVELOPMENT!!!
                this.calledByGetFin = false
                this.calledByCheckFin = false
                this.prefillCalledByFunction = true // VERY RECENT 20231106
                await this.prefillParameters()

            } catch (error) {
                this.calledByGetFin = false
                this.calledByCheckFin = false
                this.dontSave = false
                return console.log('error in getFinancials: ', error)
            }
        },
        calcAverage(valueList) {
            // const numericalValues = valueList.filter((val) => !isNaN(val)) // && val >= -0.99)
            const numericalValues = valueList.filter((val) => val !== null && val !== undefined && !isNaN(val))
            if (numericalValues.length === 0) {
                return 0 // Return 0 if there are no numerical values.
            }
            const sum = numericalValues.reduce((acc, val) => acc + val, 0)
            return sum / numericalValues.length

        },
        tryHistoryData() {
            console.log(this.balanceSheet)
            console.log(this.incomeStatement)
            console.log(this.cashFlow)
            console.log('HERE01')
            if (this.balanceSheet.balanceSheetHistory.balanceSheetStatements[0].endDate && this.balanceSheet.balanceSheetHistory.balanceSheetStatements[0].endDate.fmt) {
                this.TTMDate = this.balanceSheet.balanceSheetHistory.balanceSheetStatements[0].endDate.fmt
            } else {
                this.TTMDate = ''
                return false
            }
            if (this.balanceSheet.balanceSheetHistory.balanceSheetStatements[0].cash && this.balanceSheet.balanceSheetHistory.balanceSheetStatements[0].cash.raw) {
                this.cash = (this.balanceSheet.balanceSheetHistory.balanceSheetStatements[0].cash.raw) / 1000000
            } else {
                this.cash = 0
                this.infoMissing = this.infoMissing + 'Cash,'
            }
            if (this.balanceSheet.balanceSheetHistory.balanceSheetStatements[0].totalCurrentAssets && this.balanceSheet.balanceSheetHistory.balanceSheetStatements[0].totalCurrentAssets.raw) {
                this.currentAssets = (this.balanceSheet.balanceSheetHistory.balanceSheetStatements[0].totalCurrentAssets.raw) / 1000000
                // this.dataMissing = this.dataMissing + 'something,'
            } else {
                this.currentAssets = 0
                this.dataMissing = this.dataMissing + 'Current Assets,'
            }
            if (this.balanceSheet.balanceSheetHistory.balanceSheetStatements[0].totalAssets && this.balanceSheet.balanceSheetHistory.balanceSheetStatements[0].totalAssets.raw) {
                this.totalAssets = (this.balanceSheet.balanceSheetHistory.balanceSheetStatements[0].totalAssets.raw) / 1000000
                console.log('NEWTEST0) this.totalAssets:', this.totalAssets)
            } else {
                this.totalAssets = 0
                this.dataMissing = this.dataMissing + 'Total Assets,'
            }
            if (this.balanceSheet.balanceSheetHistory.balanceSheetStatements[0].totalCurrentLiabilities && this.balanceSheet.balanceSheetHistory.balanceSheetStatements[0].totalCurrentLiabilities.raw) {
                this.currentLiabilities = (this.balanceSheet.balanceSheetHistory.balanceSheetStatements[0].totalCurrentLiabilities.raw) / 1000000
            } else {
                this.currentLiabilities = 0
                this.dataMissing = this.dataMissing + 'Current Liabilities,'
            }
            if (this.balanceSheet.balanceSheetHistory.balanceSheetStatements[0].totalLiab && this.balanceSheet.balanceSheetHistory.balanceSheetStatements[0].totalLiab.raw) {
                this.totalLiabilities = (this.balanceSheet.balanceSheetHistory.balanceSheetStatements[0].totalLiab.raw) / 1000000
                console.log('NEWTEST-001) this.totalLiabilities:', this.totalLiabilities)
            } else {
                this.totalLiabilities = 0
                this.dataMissing = this.dataMissing + 'Total Liabilities,'
            }
            if (this.balanceSheet.balanceSheetHistory.balanceSheetStatements[0].totalStockholderEquity && this.balanceSheet.balanceSheetHistory.balanceSheetStatements[0].totalStockholderEquity.raw) {
                this.stockholderEquity = (this.balanceSheet.balanceSheetHistory.balanceSheetStatements[0].totalStockholderEquity.raw) / 1000000
            } else {
                this.stockholderEquity = 0
                this.dataMissing = this.dataMissing + 'Stockholders Equity,' // it is not an essential parameter
            }
            if (this.balanceSheet.balanceSheetHistory.balanceSheetStatements[0].minorityInterest && this.balanceSheet.balanceSheetHistory.balanceSheetStatements[0].minorityInterest.raw) {
                this.minorityInterest = (this.balanceSheet.balanceSheetHistory.balanceSheetStatements[0].minorityInterest.raw) / 1000000
            } else {
                this.minorityInterest = 0
                // this.dataMissing = this.dataMissing + 'Stockholders Equity,' // it is not an essential parameter
            }
            if (this.incomeStatement.incomeStatementHistory.incomeStatementHistory[0].totalRevenue && this.incomeStatement.incomeStatementHistory.incomeStatementHistory[0].totalRevenue.raw) {
                this.totalRevenue = (this.incomeStatement.incomeStatementHistory.incomeStatementHistory[0].totalRevenue.raw) / 1000000
            } else {
                this.totalRevenue = 0
                this.dataMissing = this.dataMissing + 'Revenues,'
            }
            if (this.incomeStatement.incomeStatementHistory.incomeStatementHistory[0].grossProfit && this.incomeStatement.incomeStatementHistory.incomeStatementHistory[0].grossProfit.raw) {
                this.grossProfit = (this.incomeStatement.incomeStatementHistory.incomeStatementHistory[0].grossProfit.raw) / 1000000
            } else {
                this.grossProfit = 0
                this.infoMissing = this.infoMissing + 'Gross Profit,'
            }
            if (this.incomeStatement.incomeStatementHistory.incomeStatementHistory[0].operatingIncome && this.incomeStatement.incomeStatementHistory.incomeStatementHistory[0].operatingIncome.raw) {
                this.operatingIncome = (this.incomeStatement.incomeStatementHistory.incomeStatementHistory[0].operatingIncome.raw) / 1000000
            } else {
                this.operatingIncome = 0
                this.dataMissing = this.dataMissing + 'Operating Income,'
            }
            if (this.incomeStatement.incomeStatementHistory.incomeStatementHistory[0].netIncomeApplicableToCommonShares && this.incomeStatement.incomeStatementHistory.incomeStatementHistory[0].netIncomeApplicableToCommonShares) {
                this.netIncomeCommonShares = (this.incomeStatement.incomeStatementHistory.incomeStatementHistory[0].netIncomeApplicableToCommonShares.raw) / 1000000
            } else {
                this.netIncomeCommonShares = 0
                this.dataMissing = this.dataMissing + 'Net Income,'
            }
            if (this.cashFlow.cashflowStatementHistory.cashflowStatements[0].depreciation && this.cashFlow.cashflowStatementHistory.cashflowStatements[0].depreciation.raw) {
                this.depreciation = (this.cashFlow.cashflowStatementHistory.cashflowStatements[0].depreciation.raw) / 1000000
            } else {
                this.depreciation = 0
                this.depreciationMissing = true
            }
            return true

        },
        fillRight(cat) {
            if (cat == 'rvG') {
                console.log('this.rvG_red:', this.rvG_red)
                this.rvG[5] = (this.rvG[1] * (1 - this.rvG_red)).toFixed(1)
                this.rvG[10] = (this.rvG[5] * (1 - this.rvG_red)).toFixed(1)
            } else if (cat == 'opM') {
                this.opM[5] = (this.opM[1] * (1 - this.opM_red)).toFixed(1)
                this.opM[10] = (this.opM[5] * (1 - this.opM_red)).toFixed(1)
                this.opM[11] = (this.opM[10] * (1 - this.opM_red)).toFixed(1)
            } else if (cat == 'pfM') {
                this.pfM[5] = (this.pfM[1] * (1 - this.pfM_red)).toFixed(1)
                this.pfM[10] = (this.pfM[5] * (1 - this.pfM_red)).toFixed(1)
                this.pfM[11] = (this.pfM[10] * (1 - this.pfM_red)).toFixed(1)
            } else if (cat == 'shD') {
                this.shD[5] = (this.shD[1] * (1 - this.shD_red)).toFixed(1)
                this.shD[10] = (this.shD[5] * (1 - this.shD_red)).toFixed(1)
                this.shD[11] = (this.shD[10] * (1 - this.shD_red)).toFixed(1)
            }
        },
        async prefillParameters() {
            this.cash2 = Number(this.cash).toLocaleString('en-US')
            this.currentAssets2 = Number(this.currentAssets).toLocaleString('en-US')
            this.totalAssets2 = Number(this.totalAssets).toLocaleString('en-US')
            this.currentLiabilities2 = Number(this.currentLiabilities).toLocaleString('en-US')
            this.totalLiabilities2 = Number(this.totalLiabilities).toLocaleString('en-US')
            this.stockholderEquity2 = Number(this.stockholderEquity).toLocaleString('en-US')
            this.totalRevenue2 = Number(this.totalRevenue).toLocaleString('en-US')
            this.grossProfit2 = Number(this.grossProfit).toLocaleString('en-US')
            this.operatingIncome2 = Number(this.operatingIncome).toLocaleString('en-US')
            this.netIncomeCommonShares2 = Number(this.netIncomeCommonShares).toLocaleString('en-US')
            this.depreciation2 = Number(this.depreciation).toLocaleString('en-US')
            this.sharesOutstanding2 = Number(this.sharesOutstanding).toLocaleString('en-US')

            if (this.revGrowth[4] > 0 && this.revGrowth[4] < 100) { // 20231230 If the average yearly growth is more than 100% it probably makes no sense, so better set it to 0
                this.rvG_red = this.revGrowth[4] / 2 / 100
                this.shD_red = this.revGrowth[4] / 2 / 100
            } else {
                this.rvG_red = 0.1 // RESTORE 2024 was this.rvG_red = 0
                this.shD_red = 0
            }

            this.opM_red = this.rvG_red / 2
            this.pfM_red = this.rvG_red / 2
            // this.shD_red = 0

            if (this.revGrowth[4] > 0 && this.revGrowth[4] < 100) { // 20231230 If the average yearly growth is more than 100% it probably makes no sense, so better set it to 0
                this.rvG[1] = this.revGrowth[4] * (1 - this.rvG_red / 2)
            } else {
                this.rvG[1] = 3
            }
            this.opM[1] = this.opMarg[4] * (1 - this.opM_red / 2)
            this.pfM[1] = this.profMarg[4] * (1 - this.pfM_red / 2)
            if (this.sharesGrowth[4] > 0) {
                this.shD[1] = this.sharesGrowth[4] * (1 - this.shD_red / 2)
            } else {
                this.shD[1] = 0
            }

            this.rvG[5] = this.rvG[1] * (1 - this.rvG_red)
            this.opM[5] = this.opM[1] * (1 - this.opM_red)
            this.pfM[5] = this.pfM[1] * (1 - this.pfM_red)
            this.shD[5] = this.shD[1] * (1 - this.shD_red)

            this.rvG[10] = this.rvG[5] * (1 - this.rvG_red)
            this.opM[10] = this.opM[5] * (1 - this.opM_red)
            this.pfM[10] = this.pfM[5] * (1 - this.pfM_red)
            this.shD[10] = this.shD[5] * (1 - this.shD_red)

            // Terminal values
            if (this.revGrowth[4] > 50) {
                this.rvG[11] = 5.5
            } else if (this.revGrowth[4] > 40) {
                this.rvG[11] = 5
            } else if (this.revGrowth[4] > 30) {
                this.rvG[11] = 4.5
            } else if (this.revGrowth[4] > 25) {
                this.rvG[11] = 4
            } else if (this.revGrowth[4] > 20) {
                this.rvG[11] = 3.5
            } else {
                this.rvG[11] = 3
            }
            if ((this.discountRate * 100 - this.rvG[11]) < 1) { // ADDED 20230525 to avoid perpetual growth going to infinity
                this.rvG[11] = this.discountRate * 100 - 1
            }

            this.opM[11] = this.opM[10] * (1 - this.opM_red)
            this.pfM[11] = this.pfM[10] * (1 - this.pfM_red)
            this.shD[11] = this.shD[10] * (1 - this.shD_red)

            // Zeroed all initial share dulution parameters
            this.shD[1] = 0
            this.shD[5] = 0
            this.shD[10] = 0
            this.shD[11] = 0

            for (let i = 2; i < 5; i++) {
                this.rvG[i] = this.rvG[1] - (this.rvG[1] - this.rvG[5]) / 4 * (i - 1)
                this.opM[i] = this.opM[1] - (this.opM[1] - this.opM[5]) / 4 * (i - 1)
                this.pfM[i] = this.pfM[1] - (this.pfM[1] - this.pfM[5]) / 4 * (i - 1)
                this.shD[i] = this.shD[1] - (this.shD[1] - this.shD[5]) / 4 * (i - 1)
            }

            for (let i = 6; i < 10; i++) {
                this.rvG[i] = this.rvG[5] - (this.rvG[5] - this.rvG[10]) / 4 * (i - 5)
                this.opM[i] = this.opM[5] - (this.opM[5] - this.opM[10]) / 4 * (i - 5)
                this.pfM[i] = this.pfM[5] - (this.pfM[5] - this.pfM[10]) / 4 * (i - 5)
                this.shD[i] = this.shD[5] - (this.shD[5] - this.shD[10]) / 4 * (i - 5)
            }

            for (let i = 1; i < 12; i++) {
                this.rvG[i] = (Math.round(this.rvG[i] * 10) / 10).toFixed(1)
                this.opM[i] = (Math.round(this.opM[i] * 10) / 10).toFixed(1)
                this.pfM[i] = (Math.round(this.pfM[i] * 10) / 10).toFixed(1)
                this.shD[i] = (Math.round(this.shD[i] * 10) / 10).toFixed(1)
            }

            ////// NEW   ////////////
            let investedCapToRevFill = (this.totalLiabilities - this.currentAssets + this.totalEquity) / this.totalRevenue * 100
            
            if(investedCapToRevFill > 100) { // RESTORE 2024
                if(this.warningMsg && this.warningMsg != 'Loading data, please wait...') {
                    this.warningMsg = `${this.warningMsg} ATTENTION! Invested Capital is higher than 100% of Revenues, which may not be sustainable. Therefore, you should enter manually the "Reinvested Capital to New Revenues" under "Optional parameters" in the Valuation Parameters section.`
                } else if (!this.warningMsg || this.warningMsg== '')  {
                    this.warningMsg = `ATTENTION! Invested Capital is higher than 100% of Revenues, which may not be sustainable. Therefore, you should enter manually the "Reinvested Capital to New Revenues" under "Optional parameters" in the Valuation Parameters section.`
                }
            }
           
            let netDebtToInvestedCapFill = (this.totalLiabilities - this.currentAssets) / (this.totalLiabilities - this.currentAssets + this.totalEquity) * 100
            if (netDebtToInvestedCapFill < 0) {
                netDebtToInvestedCapFill = 0
            }
            console.log('BBB investedCapToRevFill: ', investedCapToRevFill)
            console.log('BBB netDebtToInvestedCapFill: ', netDebtToInvestedCapFill)
            for (let i = 1; i < 12; i++) {
                this.ICtR[i] = (investedCapToRevFill).toFixed(1)
                this.DtIC[i] = (netDebtToInvestedCapFill).toFixed(1)
                // console.log('BBB this.ICtR[i]: ', i, this.ICtR[i])
                // console.log('BBB this.DtIC[i]: ', i,  this.DtIC[i])
            }
            ////// NEW ////////////

            // for (let i=1; i<12; i++) {
            //     console.log(this.rvG[i])
            //     console.log(this.opM[i])
            //     console.log(this.pfM[i])
            //     console.log(this.shD[i])
            // }

            console.log('this.warningMsg in Fill: ', this.warningMsg)
            console.log('this.depreciationWarn in Fill: ', this.depreciationWarn)
            console.log('this.depreciationMissing in Fill: ', this.depreciationMissing)

            if (!this.dontSave && !this.depreciationMissing && !this.prefillCalledByFunction) { // VERY RECENT 20231106 - added "&& !this.prefillCalledByFunction", check that it has not been called by getFInancials
                this.warningMsg = '' // Hides the warning message if there is one open
            }
            if (this.depreciationWarn) {
                this.warningMsg = `Depreciation missing from the Financial Data available for this stock. This may not have large effect on the valuation in some sectors, however to increase accuracy you may want to retrieve the 12-month Depreciation form other sources and enter below, before calculating the Valuation.`
                this.depreciationWarn = false
            }
            this.showFinancials = true
            if (this.FinancialData == null && this.financialsId == null && !this.dontSave) { // Means that it didn't find in database
                console.log('Going to save brand new Financials')
                await this.saveFinancials()
            } else if (this.FinancialData != null && this.financialsId != null && !this.dontSave) {
                console.log('Going to patch existing Financials')
                await this.saveFinancials(this.financialsId)
            } else if (this.dontSave) {
                console.log('ATTENTION: Some data are missing. Not saving the downloaded Financials.')
                // this.dontSave = false
            }
            console.log('this.warningMsg in Fill: ', this.warningMsg)
            if (this.warningMsg == 'Loading data, please wait...') {
                this.warningMsg = ''
            }
            console.log('this.warningMsg in Fill: ', this.warningMsg)

            this.calculateValue()
            if (this.warningMsg == '') {
                setTimeout(() => {
                    window.scrollTo(0, document.body.scrollHeight)
                }, 0)
            }

        },
        checkNum(event) {
            // if (isNaN(String.fromCharCode(event.which))) {
            //     console.log('Not a number')
            // } else {
            // console.log("It's a number")
            this.validInput(event.target.value)
            this.calculateValue()
            // }
        },
        checkNum2(event, field) {
            // if (isNaN(String.fromCharCode(event.which))) {
            //     console.log('Not a number')
            // } else {
            // console.log("It's a number")
            this.validInput(event.target.value)
            this.fillRight(field)
            this.calculateValue()
            // }
        },
        calculateValue() {
            this.showCalculations = false
            // this.validInput() // maybe redundant

            for (let i = 2; i < 5; i++) { // parameters from years 2, 3, 4
                this.rvG[i] = this.rvG[1] - (this.rvG[1] - this.rvG[5]) / 4 * (i - 1)
                this.opM[i] = this.opM[1] - (this.opM[1] - this.opM[5]) / 4 * (i - 1)
                this.pfM[i] = this.pfM[1] - (this.pfM[1] - this.pfM[5]) / 4 * (i - 1)
                this.shD[i] = this.shD[1] - (this.shD[1] - this.shD[5]) / 4 * (i - 1)
                this.ICtR[i] = this.ICtR[1] - (this.ICtR[1] - this.ICtR[5]) / 4 * (i - 1)
                this.DtIC[i] = this.DtIC[1] - (this.DtIC[1] - this.DtIC[5]) / 4 * (i - 1)
            }

            for (let i = 6; i < 10; i++) { // parameters from years 6, 7, 8, 9
                this.rvG[i] = this.rvG[5] - (this.rvG[5] - this.rvG[10]) / 4 * (i - 5)
                this.opM[i] = this.opM[5] - (this.opM[5] - this.opM[10]) / 4 * (i - 5)
                this.pfM[i] = this.pfM[5] - (this.pfM[5] - this.pfM[10]) / 4 * (i - 5)
                this.shD[i] = this.shD[5] - (this.shD[5] - this.shD[10]) / 4 * (i - 5)
                this.ICtR[i] = this.ICtR[5] - (this.ICtR[5] - this.ICtR[10]) / 4 * (i - 5)
                this.DtIC[i] = this.DtIC[5] - (this.DtIC[5] - this.DtIC[10]) / 4 * (i - 5)
            }

            // this.borrowRate = this.TreasuryYield / 100 + this.borrowRateVsTreasury / 100
            this.borrowRate = this.avBorrowRate / 100
            // this.discountRate = this.TreasuryYield / 100 + this.discountRateVsTreasury / 100
            this.discountRate = this.myDiscountRate / 100

            this.netCurrentAssets = this.currentAssets - this.currentLiabilities
            this.netDebt = this.totalLiabilities - this.currentAssets
            let rev = []
            let sharesOutst = []
            let investedCapital = []
            let invCapToRev = []

            let netDebtToInvCap = []
            let opProfit = []
            let netInc = []
            let nopat = []

            let stockholderEquityToInvCap = []
            let capitalReinv = []
            let Ufcf = []
            let newUfcf = []
            let equityToReturn = 0
            let netDebtAdded = []
            let additionalInterests = []
            let workingCapital = []
            let changeInNWC = []
            let Fcfe = []

            let stockholderEquityReinvested = []
            let newFcfe = []
            let depreciat = []
            let UfcfPerp = 0

            let EbitdaMultValue = 0
            let FcfePerp = 0

            let PEMultValue = 0

            let totD_Ufcf = 0
            let totD_newUfcf = 0
            let totD_Fcfe = 0
            let totD_newFcfe = 0

            let D_Ufcf = []
            let D_newUfcf = []
            let D_Fcfe = []
            let D_newFcfe = []

            rev[0] = this.totalRevenue
            sharesOutst[0] = this.sharesOutstanding
            if (this.netDebt < 0) {
                equityToReturn = this.netDebt * (-1) // If this.netDebt is negative, can be returned to equity holders on year 1. So it is added as a positive to the cash flow of year 1.
            } else {
                equityToReturn = 0
            }
            investedCapital[0] = this.netDebt + this.totalEquity

            invCapToRev[0] = investedCapital[0] / this.totalRevenue
            if (this.netDebt > 0 && investedCapital[0] != 0) {
                netDebtToInvCap[0] = this.netDebt / investedCapital[0]
            } else {
                netDebtToInvCap[0] = 0
            }
            workingCapital[0] = this.currentAssets - this.currentLiabilities

            // Below was just to test that result would be slightly different when amending, because of approximations to second decimal when using input boxes values, even if they are unchanged
            // invCapToRev[0] = Math.round(investedCapital[0] / this.totalRevenue * 100)/100
            // if (this.netDebt > 0 && investedCapital[0] != 0) {
            //     netDebtToInvCap[0] = Math.round(this.netDebt / investedCapital[0] * 100)/100
            // } else {
            //     netDebtToInvCap[0] = 0
            // }

            for (let i = 1; i < 12; i++) {
                rev[i] = rev[i - 1] * (1 + this.rvG[i] / 100)
                opProfit[i] = rev[i] * (this.opM[i] / 100)
                netInc[i] = rev[i] * (this.pfM[i] / 100)
                sharesOutst[i] = sharesOutst[i - 1] * (1 + this.shD[i] / 100)
                nopat[i] = opProfit[i] * (1 - this.taxRate / 100)
                workingCapital[i] = workingCapital[i - 1] * (1 + this.rvG[i] / 100)
                changeInNWC[i] = workingCapital[i] - workingCapital[i - 1]

                // invCapToRev[i] = invCapToRev[i - 1]
                // netDebtToInvCap[i] = netDebtToInvCap[i - 1]
                invCapToRev[i] = this.ICtR[i] / 100
                netDebtToInvCap[i] = this.DtIC[i] / 100
                console.log('AAAAAAAAAAAAAAA  invCapToRev[i]: ', i + ' ' + invCapToRev[i])
                console.log('AAAAAAAAAAAAAAA  netDebtToInvCap[i]: ', i + ' ' + netDebtToInvCap[i])

                console.log('GGGGGGGGGGGGGGG  netDebtToInvCap[i]: ', i + ' ' + netDebtToInvCap[i])
                console.log('GGGGGGGGGGGGGGG  this.stockholderEquity: ', i + ' ' + this.stockholderEquity)
                console.log('GGGGGGGGGGGGGGG  this.totalEquity: ', i + ' ' + this.totalEquity)

                stockholderEquityToInvCap[i] = (1 - netDebtToInvCap[i]) * (this.stockholderEquity / this.totalEquity)
                capitalReinv[i] = (rev[i] - rev[i - 1]) * invCapToRev[i]

                if (i == 11) {
                    capitalReinv[11] = nopat[11] * ((this.rvG[11] / 100) / this.discountRate)
                }

                Ufcf[i] = nopat[i] - changeInNWC[i]
                console.log('Ufcf[i]: ', Ufcf[i])

                newUfcf[i] = nopat[i] - capitalReinv[i]

                if (i == 11) {
                    newUfcf[11] = (nopat[11] - capitalReinv[11]) / (this.discountRate - this.rvG[11] / 100)
                }

                investedCapital[i] = rev[i] * invCapToRev[i]
                stockholderEquityReinvested[i] = capitalReinv[i] * stockholderEquityToInvCap[i]
                additionalInterests[i] = (invCapToRev[i] * rev[i]) * (netDebtToInvCap[i] - Math.round(netDebtToInvCap[0] * 1000) / 1000) * this.borrowRate
                console.log('FFF1: ', netDebtToInvCap[i])
                console.log('FFF2: ', Math.round(netDebtToInvCap[0] * 1000) / 1000)

                if (i == 1) {
                    console.log('netInc[1]', netInc[i])
                    console.log('stockholderEquityReinvested[1]', stockholderEquityReinvested[i])
                    console.log('additionalInterests[1]', additionalInterests[i])
                    console.log('equityToReturn', equityToReturn)
                    console.log('capitalReinv[1]', capitalReinv[i])
                    console.log('stockholderEquityToInvCap[1]', stockholderEquityToInvCap[i])

                    netDebtAdded[i] = (netDebtToInvCap[i] * investedCapital[i]) - (this.netDebt + equityToReturn) // this.netDebt is the current/initial one (i=0). (this.netDebt+equityToReturn) is 0 is the debt was negative, elso it is equal to this.netDebt
                    Fcfe[i] = netInc[i] + netDebtAdded[i] + equityToReturn - changeInNWC[i] - additionalInterests[i]
                    newFcfe[i] = netInc[i] - stockholderEquityReinvested[i] + additionalInterests[i] + equityToReturn
                } else {
                    netDebtAdded[i] = (netDebtToInvCap[i] * investedCapital[i]) - (netDebtToInvCap[i - 1] * investedCapital[i - 1]) // in other years, it is just the debt of this year, less the debt of previous year
                    Fcfe[i] = netInc[i] + netDebtAdded[i] - changeInNWC[i] - additionalInterests[i]
                    newFcfe[i] = netInc[i] - stockholderEquityReinvested[i] - additionalInterests[i]
                }

                // Fcfe[i] = netInc[i] + netDebtAdded[i]
                // stockholderEquityReinvested[i] = capitalReinv[i] * equityToInvCap[i]
                // newFcfe[i] = netInc[i] - stockholderEquityReinvested[i]
                if (i == 11) {
                    newFcfe[i] = (netInc[i] - stockholderEquityReinvested[i] - additionalInterests[i]) / (this.discountRate - this.rvG[11] / 100)
                    console.log('UUU newFcfe[11]: ', newFcfe[i])
                }
                if (i == 1) {
                    depreciat[i] = (this.depreciation / this.totalRevenue) * rev[i]
                } else {
                    depreciat[i] = (depreciat[i - 1] / rev[i - 1]) * rev[i]
                }
                console.log('newFcfe[i]: ', i, newFcfe[i])

            }

            UfcfPerp = Ufcf[10] * (1 + this.rvG[11] / 100) / (this.discountRate - this.rvG[11] / 100)
            EbitdaMultValue = (opProfit[10] + depreciat[10]) * this.terminalEbitdaMult
            if (UfcfPerp > 0) {
                Ufcf[11] = (UfcfPerp + EbitdaMultValue) / 2
            } else {
                Ufcf[11] = EbitdaMultValue
            }

            FcfePerp = Fcfe[10] * (1 + this.rvG[11] / 100) / (this.discountRate - this.rvG[11] / 100)
            PEMultValue = netInc[10] * this.terminalPEMult
            if (FcfePerp > 0) {
                Fcfe[11] = (FcfePerp + PEMultValue) / 2
            } else {
                Fcfe[11] = PEMultValue
            }

            // Calculate present value FCF years 1-10
            for (let i = 1; i < 11; i++) {
                D_Ufcf[i] = Ufcf[i] / ((1 + this.discountRate) ** i)
                D_newUfcf[i] = newUfcf[i] / ((1 + this.discountRate) ** i)
                D_Fcfe[i] = (Fcfe[i] * (sharesOutst[0] / sharesOutst[i])) / ((1 + this.discountRate) ** i)
                D_newFcfe[i] = (newFcfe[i] * (sharesOutst[0] / sharesOutst[i])) / ((1 + this.discountRate) ** i)
                totD_Ufcf = totD_Ufcf + D_Ufcf[i]
                totD_newUfcf = totD_newUfcf + D_newUfcf[i]
                totD_Fcfe = totD_Fcfe + D_Fcfe[i]
                totD_newFcfe = totD_newFcfe + D_newFcfe[i]
            }

            let D_AvTermUfcf = Ufcf[11] / ((1 + this.discountRate) ** 10)
            let D_MultTermUfcf = EbitdaMultValue / ((1 + this.discountRate) ** 10)
            let D_PerpTermNewUfcf = newUfcf[11] / ((1 + this.discountRate) ** 10)
            let D_MultTermNewUfcf = EbitdaMultValue / ((1 + this.discountRate) ** 10)

            let D_AvTermFcfe = (Fcfe[11] * (sharesOutst[0] / sharesOutst[11])) / ((1 + this.discountRate) ** 10)
            let D_MultTermFcfe = (PEMultValue * (sharesOutst[0] / sharesOutst[11])) / ((1 + this.discountRate) ** 10)
            let D_PerpTermNewFcfe = (newFcfe[11] * (sharesOutst[0] / sharesOutst[11])) / ((1 + this.discountRate) ** 10)
            let D_MultTermNewFcfe = (PEMultValue * (sharesOutst[0] / sharesOutst[11])) / ((1 + this.discountRate) ** 10)

            let EV_AvTermUfcf = D_AvTermUfcf + totD_Ufcf
            let EV_MultTermUfcf = D_MultTermUfcf + totD_Ufcf
            let EV_PerpTermNewUfcf = D_PerpTermNewUfcf + totD_newUfcf
            let EV_MultTermNewUfcf = D_MultTermNewUfcf + totD_newUfcf

            let IV_AvTermUfcf = EV_AvTermUfcf - this.netDebt
            let IV_MultTermUfcf = EV_MultTermUfcf - this.netDebt
            let IV_PerpTermNewUfcf = EV_PerpTermNewUfcf - this.netDebt
            let IV_MultTermNewUfcf = EV_MultTermNewUfcf - this.netDebt

            let DCF_AvTermFcfe = D_AvTermFcfe + totD_Fcfe
            let DCF_MultTermFcfe = D_MultTermFcfe + totD_Fcfe
            let DCF_PerpTermNewFcfe = D_PerpTermNewFcfe + totD_newFcfe
            let DCF_MultTermNewFcfe = D_MultTermNewFcfe + totD_newFcfe

            this.SV_AvTermUfcf = IV_AvTermUfcf / this.sharesOutstanding
            this.SV_MultTermUfcf = IV_MultTermUfcf / this.sharesOutstanding
            this.SV_PerpTermNewUfcf = IV_PerpTermNewUfcf / this.sharesOutstanding
            this.SV_MultTermNewUfcf = IV_MultTermNewUfcf / this.sharesOutstanding

            this.SV_AvTermFcfe = DCF_AvTermFcfe / this.sharesOutstanding
            this.SV_MultTermFcfe = DCF_MultTermFcfe / this.sharesOutstanding
            this.SV_PerpTermNewFcfe = DCF_PerpTermNewFcfe / this.sharesOutstanding
            this.SV_MultTermNewFcfe = DCF_MultTermNewFcfe / this.sharesOutstanding

            this.maxSV = (Math.round((Math.max(this.SV_AvTermUfcf, this.SV_MultTermUfcf, this.SV_PerpTermNewUfcf, this.SV_MultTermNewUfcf, this.SV_AvTermFcfe, this.SV_MultTermFcfe, this.SV_PerpTermNewFcfe, this.SV_MultTermNewFcfe)) * 10) / 10).toFixed(1)
            this.minSV = (Math.round((Math.min(this.SV_AvTermUfcf, this.SV_MultTermUfcf, this.SV_PerpTermNewUfcf, this.SV_MultTermNewUfcf, this.SV_AvTermFcfe, this.SV_MultTermFcfe, this.SV_PerpTermNewFcfe, this.SV_MultTermNewFcfe)) * 10) / 10).toFixed(1)
            this.AvUfcfSV = (Math.round(((this.SV_AvTermUfcf + this.SV_MultTermUfcf + this.SV_PerpTermNewUfcf + this.SV_MultTermNewUfcf) / 4) * 10) / 10).toFixed(1)
            this.AvFcFeSV = (Math.round(((this.SV_AvTermFcfe + this.SV_MultTermFcfe + this.SV_PerpTermNewFcfe + this.SV_MultTermNewFcfe) / 4) * 10) / 10).toFixed(1)

            this.AvNewUfcfSV = (Math.round(((this.SV_PerpTermNewUfcf + this.SV_MultTermNewUfcf) / 2) * 10) / 10).toFixed(1)
            this.AvNewFcfeSV = (Math.round(((this.SV_PerpTermNewFcfe + this.SV_MultTermNewFcfe) / 2) * 10) / 10).toFixed(1)

            this.PerpNewUfcf = (Math.round(this.SV_PerpTermNewUfcf * 10) / 10).toFixed(1)
            this.MultNewUfcf = (Math.round(this.SV_MultTermNewUfcf * 10) / 10).toFixed(1)
            this.PerpNewFcfe = (Math.round(this.SV_PerpTermNewFcfe * 10) / 10).toFixed(1)
            this.MultNewFcfe = (Math.round(this.SV_MultTermNewFcfe * 10) / 10).toFixed(1)

            console.log('this.AvNewFcfeSV: ', this.AvNewFcfeSV)

            // this.fairValue = (Math.round(((Number(this.AvNewUfcfSV) + Number(this.AvNewFcfeSV)) / 2) * 10) / 10).toFixed(1)
            // this.fairValueCurr = (Math.round(((Number(this.AvNewUfcfSV) + Number(this.AvNewFcfeSV)) / this.fx / 2) * 10) / 10).toFixed(1)
            this.fairValue = this.AvNewFcfeSV
            this.fairValueCurr = (Math.round((Number(this.AvNewFcfeSV) / this.fx) * 10) / 10).toFixed(1)

            //  fairValueCurr =(Math.round(fairValue/fx*10)/10).toFixed(1) }}

            for (let i = 1; i < 12; i++) {
                console.log('this.rvG ' + i + ' :', this.rvG[i])
                console.log('this.opM ' + i + ' :', this.opM[i])
                console.log('this.pfM ' + i + ' :', this.pfM[i])
                console.log('this.shD ' + i + ' :', this.shD[i])

                console.log('rev ' + i + ' :', rev[i])
                console.log('nopat ' + i + ' :', nopat[i])
                console.log('sharesOutst ' + i + ' :', sharesOutst[i])
                console.log('opProfit ' + i + ' :', opProfit[i])
                console.log('investedCapital ' + i + ' :', investedCapital[i])
                console.log('invCapToRev ' + i + ' :', invCapToRev[i])
                console.log('netDebtToInvCap ' + i + ' :', netDebtToInvCap[i])
                console.log('netInc ' + i + ' :', netInc[i])
                console.log('stockholderEquityToInvCap ' + i + ' :', stockholderEquityToInvCap[i])
                console.log('capitalReinv ' + i + ' :', capitalReinv[i])
                console.log('stockholderEquityReinvested ' + i + ' :', stockholderEquityReinvested[i])
                console.log('depreciat ' + i + ' :', depreciat[i])
                console.log('netDebtAdded[i]: ', i, netDebtAdded[i])
                console.log('additionalInterests[i]: ', i, additionalInterests[i])

                console.log('D_Ufcf ' + i + ' :', D_Ufcf[i])
                console.log('D_newUfcf ' + i + ' :', D_newUfcf[i])
                console.log('D_Fcfe ' + i + ' :', D_Fcfe[i])
                console.log('D_newFcfe ' + i + ' :', D_newFcfe[i])
            }

            console.log('EbitdaMultValue  :', EbitdaMultValue)
            console.log('PEMultValue :', PEMultValue)
            console.log('UfcfPerp :', UfcfPerp)
            console.log('FcfePerp :', FcfePerp)

            console.log('totD_Ufcf :', totD_Ufcf)
            console.log('totD_newUfcf :', totD_newUfcf)
            console.log('totD_Fcfe :', totD_Fcfe)
            console.log('totD_newFcfe :', totD_newFcfe)

            console.log('D_AvTermUfcf  :', D_AvTermUfcf)
            console.log('D_MultTermUfcf :', D_MultTermUfcf)
            console.log('D_PerpTermNewUfcf :', D_PerpTermNewUfcf)
            console.log('D_MultTermNewUfcf :', D_MultTermNewUfcf)
            console.log('D_AvTermFcfe :', D_AvTermFcfe)
            console.log('D_MultTermFcfe :', D_MultTermFcfe)
            console.log('D_PerpTermNewFcfe :', D_PerpTermNewFcfe)
            console.log('D_MultTermNewFcfe :', D_MultTermNewFcfe)

            console.log('EV_AvTermUfcf: ', EV_AvTermUfcf)
            console.log('EV_MultTermUfcf :', EV_MultTermUfcf)
            console.log('EV_PerpTermNewUfcf :', EV_PerpTermNewUfcf)
            console.log('EV_MultTermNewUfcf :', EV_MultTermNewUfcf)
            console.log('IV_AvTermUfcf :', IV_AvTermUfcf)
            console.log('IV_MultTermUfcf :', IV_MultTermUfcf)
            console.log('IV_PerpTermNewUfcf :', IV_PerpTermNewUfcf)
            console.log('EV_MultTermNewUfcf :', EV_MultTermNewUfcf)

            this.showCalculations = true

            // setTimeout(() => {
            //     window.scrollTo(0, document.body.scrollHeight)
            // }, 0)

        },
        onResize() {
            // console.log('document.activeElement in FairValue: ', document.activeElement.id)
            if (document.activeElement.id == 'calculate-button') {
                setTimeout(() => {
                    window.scrollTo(0, document.body.scrollHeight)
                }, 0)
            }
        },
        async validInput(value) {
            try {
                if (value && value != null && value != 0) { // This is not utilized for now, it is supposed to check the validity of the input (any number input). Maybe redundant.
                    // console.log('value: ', value)
                    value = parseFloat(value.replace(/,/g, ''))
                    // console.log('typeof value: ', typeof value)
                }
                if ((this.discountRate * 100 - this.rvG[11]) < 1) { // ADDED 20230525 to avoid perpetual growth going to infinity
                    this.rvG[11] = this.discountRate * 100 - 1
                    await this.setAlertMsg(`The maximum allowed Perpetual Growth is 1% lower than the Discount rate. This is to avoid distorting effects due to the formula for perpetual growth.${'\n\n'}Therefore in this case the perpetual growth has been set to ${this.rvG[11]}%.`)
                }

                if (this.cash != Math.round(parseFloat(this.cash2.replace(/,/g, '')) * 1000) / 1000) {
                    this.cash = parseFloat(this.cash2.replace(/,/g, ''))
                }
                if (this.currentAssets != Math.round(parseFloat(this.currentAssets2.replace(/,/g, '')) * 1000) / 1000) {
                    this.currentAssets = parseFloat(this.currentAssets2.replace(/,/g, ''))
                }
                if (this.totalAssets != Math.round(parseFloat(this.totalAssets2.replace(/,/g, '')) * 1000) / 1000) {
                    this.totalAssets = parseFloat(this.totalAssets2.replace(/,/g, ''))
                }
                if (this.currentLiabilities != Math.round(parseFloat(this.currentLiabilities2.replace(/,/g, '')) * 1000) / 1000) {
                    this.currentLiabilities = parseFloat(this.currentLiabilities2.replace(/,/g, ''))
                }
                if (this.totalLiabilities != Math.round(parseFloat(this.totalLiabilities2.replace(/,/g, '')) * 1000) / 1000) {
                    this.totalLiabilities = parseFloat(this.totalLiabilities2.replace(/,/g, ''))
                }
                if (this.totalRevenue != Math.round(parseFloat(this.totalRevenue2.replace(/,/g, '')) * 1000) / 1000) {
                    this.totalRevenue = parseFloat(this.totalRevenue2.replace(/,/g, ''))
                }
                if (this.grossProfit != Math.round(parseFloat(this.grossProfit2.replace(/,/g, '')) * 1000) / 1000) {
                    this.grossProfit = parseFloat(this.grossProfit2.replace(/,/g, ''))
                }
                if (this.operatingIncome != Math.round(parseFloat(this.operatingIncome2.replace(/,/g, '')) * 1000) / 1000) {
                    this.operatingIncome = parseFloat(this.operatingIncome2.replace(/,/g, ''))
                }
                if (this.netIncomeCommonShares != Math.round(parseFloat(this.netIncomeCommonShares2.replace(/,/g, '')) * 1000) / 1000) {
                    this.netIncomeCommonShares = parseFloat(this.netIncomeCommonShares2.replace(/,/g, ''))
                }
                if (this.depreciation != Math.round(parseFloat(this.depreciation2.replace(/,/g, '')) * 1000) / 1000) {
                    this.depreciation = parseFloat(this.depreciation2.replace(/,/g, ''))
                }
                if (this.stockholderEquity != Math.round(parseFloat(this.stockholderEquity2.replace(/,/g, '')) * 1000) / 1000) {
                    this.stockholderEquity = parseFloat(this.stockholderEquity2.replace(/,/g, ''))
                }
                if (this.sharesOutstanding != Math.round(parseFloat(this.sharesOutstanding2.replace(/,/g, '')) * 1000) / 1000) {
                    this.sharesOutstanding = parseFloat(this.sharesOutstanding2.replace(/,/g, ''))
                }

                this.totalEquity = this.totalAssets - this.totalLiabilities
                this.marketCap = Math.round(this.sharesOutstanding * this.lastPrice).toLocaleString('en-US')
                this.PeRatio = (Math.round(this.sharesOutstanding * this.lastPrice / this.netIncomeCommonShares * this.fx * 10) / 10).toLocaleString('en-US') // in parentheses is MarketCap
                this.grossMargin = (Math.round(this.grossProfit / this.totalRevenue * 100 * 10) / 10).toLocaleString('en-US') + ' %'
                this.operatingMargin = (Math.round(this.operatingIncome / this.totalRevenue * 100 * 10) / 10).toLocaleString('en-US') + ' %'
                this.profitMargin = (Math.round(this.netIncomeCommonShares / this.totalRevenue * 100 * 10) / 10).toLocaleString('en-US') + ' %'
                this.netDebtIndicator = Math.round(this.totalLiabilities - this.currentAssets).toLocaleString('en-US')
                this.roic = (Math.round(this.netIncomeCommonShares / ((this.totalLiabilities - this.currentAssets) + this.stockholderEquity) * 100 * 10) / 10).toLocaleString('en-US') + ' %' // in parentheses is NetDebtIndicator
                this.roe = (Math.round(this.netIncomeCommonShares / this.stockholderEquity * 100 * 10) / 10).toLocaleString('en-US') + ' %'
                this.investedCapToRev = (Math.round((this.totalLiabilities - this.currentAssets + this.totalEquity) / this.totalRevenue * 100) / 100).toLocaleString('en-US') + ' %'
                this.netDebtToInvestedCap = (Math.round((this.totalLiabilities - this.currentAssets) / (this.totalLiabilities - this.currentAssets + this.totalEquity) * 1000) / 10).toLocaleString('en-US') + ' %'
                if (Number(this.netDebtToInvestedCap) < 0) {
                    this.netDebtToInvestedCap = `0`.toLocaleString('en-US') + ' %'
                }
                this.netWorkingCap = Math.round(this.currentAssets - this.currentLiabilities).toLocaleString('en-US')
                this.EbitdaRatio = (Math.round(((this.totalLiabilities - this.currentAssets) + this.totalEquity) / (this.operatingIncome + this.depreciation) * 10) / 10).toLocaleString('en-US') // in parentheses is NetDebtIndicator
                if (this.stockholderEquity != 0) {
                    this.bookValueFinCurr = Math.round(this.stockholderEquity / this.sharesOutstanding).toFixed(1)
                    this.bookValuePriCurr = Math.round((this.stockholderEquity / this.sharesOutstanding) / this.fx).toFixed(1)
                } else {
                    this.bookValueFinCurr = Math.round(this.totalEquity / this.sharesOutstanding).toFixed(1)
                    this.bookValuePriCurr = Math.round((this.totalEquity / this.sharesOutstanding) / this.fx).toFixed(1)
                }
                // alert('Calculating value')
                // this.calculateValue()
            } catch (err) {
                console.log(err)
            }

        },

        async saveFinancials(financId) {
            console.log('this.financialsId: ', this.financialsId)
            console.log('financId: ', financId)
            console.log('financId != null: ', financId != null)
            try {
                let params = {
                    // 'assetname': this.assetname,
                    'count': this.count,
                    'symbol': this.symbol,
                    'shortname': this.shortName,
                    'exchange': this.exchange,
                    'ttmdate': this.TTMDate,
                    'cash': Math.round(this.cash * 100) / 100,
                    'currentassets': Math.round(this.currentAssets * 100) / 100,
                    'totalassets': Math.round(this.totalAssets * 100) / 100,
                    'currentliabilities': Math.round(this.currentLiabilities * 100) / 100,
                    'totalliabilities': Math.round(this.totalLiabilities * 100) / 100,
                    'stockholderequity': Math.round(this.stockholderEquity * 100) / 100,
                    'totalrevenue': Math.round(this.totalRevenue * 100) / 100,
                    'grossprofit': Math.round(this.grossProfit * 100) / 100,
                    'operatingincome': Math.round(this.operatingIncome * 100) / 100,
                    'netincome': Math.round(this.netIncomeCommonShares * 100) / 100,
                    'depreciation': Math.round(this.depreciation * 100) / 100,
                    'sharesoutstanding': Math.round(this.sharesOutstanding * 100) / 100,
                    'fincurrency': this.financialCurrency,
                    'lastcloseprice': Math.round(this.lastPrice * 10000) / 10000,
                    'pricurrency': this.priceCurrency,
                    'histdate1': this.revDate[0],
                    'histrevenues1': Math.round(this.histRevenues[0] * 100) / 100,
                    'histopincome1': Math.round(this.histOpIncome[0] * 100) / 100,
                    'histnetincome1': Math.round(this.histNetIncome[0] * 100) / 100,
                    'histtotalliabilities1': Math.round(this.histTotalLiabilities[0] * 100) / 100,
                    'histtotalassets1': Math.round(this.histTotalAssets[0] * 100) / 100,
                    'histcurrentassets1': Math.round(this.histCurrentAssets[0] * 100) / 100,
                    'histstockholderequity1': Math.round(this.histStockholderEquity[0] * 100) / 100,
                    'histshares1': Math.round(this.histShares[0] * 100) / 100,
                    'histdate2': this.revDate[1],
                    'histrevenues2': Math.round(this.histRevenues[1] * 100) / 100,
                    'histopincome2': Math.round(this.histOpIncome[1] * 100) / 100,
                    'histnetincome2': Math.round(this.histNetIncome[1] * 100) / 100,
                    'histtotalliabilities2': Math.round(this.histTotalLiabilities[1] * 100) / 100,
                    'histtotalassets2': Math.round(this.histTotalAssets[1] * 100) / 100,
                    'histcurrentassets2': Math.round(this.histCurrentAssets[1] * 100) / 100,
                    'histstockholderequity2': Math.round(this.histStockholderEquity[1] * 100) / 100,
                    'histshares2': Math.round(this.histShares[1] * 100) / 100,
                    'histdate3': this.revDate[2],
                    'histrevenues3': Math.round(this.histRevenues[2] * 100) / 100,
                    'histopincome3': Math.round(this.histOpIncome[2] * 100) / 100,
                    'histnetincome3': Math.round(this.histNetIncome[2] * 100) / 100,
                    'histtotalliabilities3': Math.round(this.histTotalLiabilities[2] * 100) / 100,
                    'histtotalassets3': Math.round(this.histTotalAssets[2] * 100) / 100,
                    'histcurrentassets3': Math.round(this.histCurrentAssets[2] * 100) / 100,
                    'histstockholderequity3': Math.round(this.histStockholderEquity[2] * 100) / 100,
                    'histshares3': Math.round(this.histShares[2] * 100) / 100,
                    'histdate4': this.revDate[3],
                    'histrevenues4': Math.round(this.histRevenues[3] * 100) / 100,
                    'histopincome4': Math.round(this.histOpIncome[3] * 100) / 100,
                    'histnetincome4': Math.round(this.histNetIncome[3] * 100) / 100,
                    'histtotalliabilities4': Math.round(this.histTotalLiabilities[3] * 100) / 100,
                    'histtotalassets4': Math.round(this.histTotalAssets[3] * 100) / 100,
                    'histcurrentassets4': Math.round(this.histCurrentAssets[3] * 100) / 100,
                    'histstockholderequity4': Math.round(this.histStockholderEquity[3] * 100) / 100,
                    'histshares4': Math.round(this.histShares[3] * 100) / 100,
                }
                console.log('Financials to save: ', params)
                if (financId != null) {
                    const patchedFinancials = await apiCalls.patchEntry('/financials/' + this.financialsId + '/', params)
                    if (patchedFinancials) {
                        console.log('Patched Financials: ', patchedFinancials)
                        this.financialsId = null
                    } else {
                        console.log('Not managed to patch financials!!!')
                    }

                } else {
                    const savedFinancials = await apiCalls.postEntry('/financials/', params)
                    if (savedFinancials) {
                        console.log('Saved Financials: ', savedFinancials)
                    } else {
                        console.log('Not managed to save financials!!!')
                    }
                }
                this.financialsId = null

            } catch (error) {
                this.financialsId = null
                return console.log('error in saveFinancials: ', error)
            }

        },

        assignYhFinancials(financial_items) {
            console.log('Yho financial_items:', financial_items)
            console.log('Yho financial_items:', financial_items.length)
            if (financial_items) {
                // console.log('HERE')
            }

            let items_list = []
            let items = {}
            let yh = {}
            let yh_data = {}
            yh_data['ttm'] = {}
            yh_data['hist'] = {}
            let annual_periods = 0
            // for (let i=0;i<4;i++) {
            //     yh_data['hist'][i] = {}
            // }

            if (financial_items && financial_items.length > 0) {
                items_list = financial_items.map(item => item.meta.type[0])
                // console.log('items_list', items_list)
                items = items_list.reduce((acc, fin_it, ind) => {
                    acc[fin_it] = ind
                    return acc
                }, {})
                // console.log('items:', items)
                // for (const item_name in items) {
                //     if (Object.prototype.hasOwnProperty.call(items, item_name)) {
                //         const index = items[item_name]
                //         console.log(index + ')', item_name)

                //         if (financial_items[index] && item_name in financial_items[index]) {
                //             if (financial_items[index][item_name].length > 0) {
                //                 for (let period_index = 0; period_index < financial_items[index][item_name].length; period_index++) {
                //                     let fin_it = ''
                //                     if (financial_items[index][item_name][period_index] !== null && 'asOfDate' in financial_items[index][item_name][period_index]) {
                //                         // console.log(financial_items[index][item_name][period_index]['asOfDate'])
                //                         fin_it = fin_it + financial_items[index][item_name][period_index]['asOfDate']
                //                     } else {
                //                         // console.log('No date available for ', item_name, ' period ', period_index)
                //                     }
                //                     if (financial_items[index][item_name][period_index] !== null && 'periodType' in financial_items[index][item_name][period_index]) {
                //                         // console.log(financial_items[index][item_name][period_index]['periodType'])
                //                         fin_it = fin_it + ' ' + financial_items[index][item_name][period_index]['periodType']
                //                     } else {
                //                         // console.log('No duration available for ', item_name, ' period ', period_index)
                //                     }
                //                     if (financial_items[index][item_name][period_index] !== null && 'currencyCode' in financial_items[index][item_name][period_index]) {
                //                         // console.log(financial_items[index][item_name][period_index]['currencyCode'])
                //                         fin_it = fin_it + ' ' + financial_items[index][item_name][period_index]['currencyCode']
                //                     } else {
                //                         // console.log('No currency available for ', item_name, ' period ', period_index)
                //                     }
                //                     if (financial_items[index][item_name][period_index] !== null && 'reportedValue' in financial_items[index][item_name][period_index]) {
                //                         // console.log(financial_items[index][item_name][period_index]['reportedValue']['raw'])
                //                         fin_it = fin_it + ' ' + financial_items[index][item_name][period_index]['reportedValue']['raw']
                //                     } else {
                //                         console.log('No value available for ', item_name, ' period ', period_index, ': ', fin_it)
                //                     }
                //                     if (fin_it.length > 0) {
                //                         console.log(fin_it)
                //                     }
                //                 }
                //             } else {
                //                 console.log('No periods in ', item_name)
                //             }
                //         } else {
                //             console.log('data missing for ', item_name)
                //         }
                //     }
                // }
                // console.log('****************************************************************************************************************')
                // let yh = {}
                let summaryRemark = {}
                let finItemsList = []
                let counter = 0
                let num_periods = 0
                let period_name = ''
                let fin_item_name = ''
                let index = 0
                for (period_name of ['quarterly', 'annual', 'trailing']) {
                    summaryRemark[period_name] = ''
                    yh[period_name] = {}

                    if (period_name == 'quarterly' || period_name == 'annual') {
                        fin_item_name = period_name + 'TotalAssets'
                        finItemsList = ['CashAndCashEquivalents', 'CashFinancial', 'CurrentAssets', 'TotalAssets', 'CurrentLiabilities', 'TotalLiabilitiesNetMinorityInterest', 'StockholdersEquity', 'MinorityInterest', 'TotalRevenue', 'GrossProfit', 'OperatingIncome', 'NetIncome', 'NetIncomeCommonStockholders', 'Depreciation', 'DepreciationAndAmortization', 'BasicAverageShares', 'DilutedAverageShares']
                        // 'EndCashPosition'
                    } else {
                        fin_item_name = period_name + 'TotalRevenue'
                        finItemsList = ['TotalRevenue', 'GrossProfit', 'OperatingIncome', 'NetIncome', 'NetIncomeCommonStockholders', 'Depreciation', 'DepreciationAndAmortization', 'BasicAverageShares', 'DilutedAverageShares']
                    }
                    index = items[fin_item_name]
                    if (items_list.includes(fin_item_name) && financial_items[index][fin_item_name] != null) { // always != null, not !== null, since I don't want undefined to be different than null.
                        counter = 0
                        yh[period_name]['TTMDate'] = {}
                        for (let period_index = 0; period_index < financial_items[index][fin_item_name].length; period_index++) {
                            if (financial_items[index][fin_item_name][period_index] != null && 'asOfDate' in financial_items[index][fin_item_name][period_index] && 'reportedValue' in financial_items[index][fin_item_name][period_index]) {
                                yh[period_name]['TTMDate'][counter] = financial_items[index][fin_item_name][period_index]['asOfDate']
                                counter += 1
                            }
                        }
                    } else {
                        return 'No Date found for ' + period_name + ' data'
                    }
                    // if (yh[period_name]['TTMDate']==''){
                    //     summaryRemark = summaryRemark + 'quarterly TTMDate missing'
                    // }
                    num_periods = counter
                    yh[period_name]['periods'] = num_periods
                    for (let item_descr of finItemsList) {
                        counter = 0
                        let fin_item_name = period_name + item_descr
                        let index = items[fin_item_name]
                        if (items_list.includes(fin_item_name) && financial_items[index][fin_item_name] != null) {
                            yh[period_name][item_descr] = {}
                            for (let count = 0; count < num_periods; count++) {
                                for (let period_index = 0; period_index < financial_items[index][fin_item_name].length; period_index++) {
                                    if (financial_items[index][fin_item_name][period_index] != null && 'asOfDate' in financial_items[index][fin_item_name][period_index] && 'reportedValue' in financial_items[index][fin_item_name][period_index]) {
                                        if (yh[period_name]['TTMDate'][count] == financial_items[index][fin_item_name][period_index]['asOfDate']) {
                                            yh[period_name][item_descr][count] = financial_items[index][fin_item_name][period_index]['reportedValue']['raw']
                                        }
                                    }
                                }
                                if (!(count in yh[period_name][item_descr])) {
                                    if (summaryRemark[period_name] == '') {
                                        summaryRemark[period_name] += `${period_name} missing -> `
                                    } else {
                                        summaryRemark[period_name] += ', '
                                    }
                                    summaryRemark[period_name] += `${item_descr} for ${yh[period_name]['TTMDate'][count]}`

                                    // FOR BS ITEMS CHECK IF AVAILABLE FROM QUARTERLY 
                                }
                            }
                        } else {
                            if (summaryRemark[period_name] == '') {
                                summaryRemark[period_name] += `${period_name} missing -> `
                            } else {
                                summaryRemark[period_name] += ', '
                            }
                            summaryRemark[period_name] += `${item_descr}`
                        }
                    }

                    console.log(`${period_name} -> ${num_periods}`, yh[period_name])
                    if (summaryRemark[period_name] == '') {
                        // console.log(`${period_name} -> All items available`) 
                    } else {
                        // console.log(summaryRemark[period_name]) 
                    }

                }
                const last_trailing_date = yh['trailing']['TTMDate'][yh['trailing']['periods'] - 1]
                const last_quarterly_date = yh['quarterly']['TTMDate'][yh['quarterly']['periods'] - 1]
                const last_annual_date = yh['annual']['TTMDate'][yh['annual']['periods'] - 1]
                const trailing_periods = yh['trailing']['periods']
                const quarterly_periods = yh['quarterly']['periods']
                annual_periods = yh['annual']['periods']
                const isItemsList = ['TotalRevenue', 'GrossProfit', 'OperatingIncome', 'NetIncome', 'NetIncomeCommonStockholders', 'Depreciation', 'DepreciationAndAmortization']
                // let yh_data['ttm'] = {}

                if ((last_quarterly_date > last_trailing_date && quarterly_periods > 3) && last_quarterly_date > last_annual_date) { // only if the last quarterly is more recent than the last annual
                    // console.log('Replacing TTM with sum of last 4 available quarters')
                    yh_data['ttm']['TTMDate'] = yh['quarterly']['TTMDate'][quarterly_periods - 1]
                    for (let item_descr of isItemsList) {
                        // console.log(item_descr)
                        if (item_descr in yh['quarterly'] && yh['quarterly'][item_descr][quarterly_periods - 1] != null && yh['quarterly'][item_descr][quarterly_periods - 2] != null && yh['quarterly'][item_descr][quarterly_periods - 3] != null && yh['quarterly'][item_descr][quarterly_periods - 4] != null) {
                            yh_data['ttm'][item_descr] = yh['quarterly'][item_descr][quarterly_periods - 1] + yh['quarterly'][item_descr][quarterly_periods - 2] + yh['quarterly'][item_descr][quarterly_periods - 3] + yh['quarterly'][item_descr][quarterly_periods - 4]
                        }
                    }
                } else if (last_annual_date == last_trailing_date) { // the preferred option
                    // console.log('Replacing TTM with annual')
                    yh_data['ttm']['TTMDate'] = yh['annual']['TTMDate'][annual_periods - 1]
                    for (let item_descr of finItemsList) {
                        // console.log(item_descr)
                        if (item_descr in yh['annual'] && yh['annual'][item_descr][annual_periods - 1] != null) {
                            yh_data['ttm'][item_descr] = yh['annual'][item_descr][annual_periods - 1]
                        }
                    }
                } else { // if for example last trailing date is after last annual date but there aren't 4 quarters available, then keep the latest trailing data
                    // console.log('Replacing TTM with trailing')
                    yh_data['ttm']['TTMDate'] = yh['trailing']['TTMDate'][trailing_periods - 1]
                    for (let item_descr of finItemsList) {
                        // console.log(item_descr)
                        if (item_descr in yh['trailing'] && yh['trailing'][item_descr][trailing_periods - 1] != null) {
                            yh_data['ttm'][item_descr] = yh['trailing'][item_descr][trailing_periods - 1]
                        }
                    }
                }
                const bsItemsList = ['CashAndCashEquivalents', 'CashFinancial', 'CurrentAssets', 'TotalAssets', 'CurrentLiabilities', 'TotalLiabilitiesNetMinorityInterest', 'StockholdersEquity', 'MinorityInterest', 'BasicAverageShares', 'DilutedAverageShares']
                if (last_quarterly_date > last_annual_date) {
                    for (let item_descr of bsItemsList) {
                        if (item_descr in yh['quarterly'] && yh['quarterly'][item_descr][quarterly_periods - 1] != null) {
                            yh_data['ttm'][item_descr] = yh['quarterly'][item_descr][quarterly_periods - 1]
                        }
                    }
                } else {
                    for (let item_descr of bsItemsList) {
                        if (item_descr in yh['annual'] && yh['annual'][item_descr][annual_periods - 1] != null) {
                            yh_data['ttm'][item_descr] = yh['annual'][item_descr][annual_periods - 1]
                        }
                    }
                }

                for (period_name of ['quarterly', 'annual', 'trailing']) {
                    delete yh[period_name].periods
                }

                for (let item_type in yh['annual']) {
                    yh_data['hist'][item_type] = {}
                    for (let i = 0; i < annual_periods; i++) {
                        yh_data['hist'][item_type][annual_periods - 1 - i] = yh['annual'][item_type][i]
                    }
                }
                yh_data['hist']['periods'] = annual_periods
                console.log('annual_periods', annual_periods)

            }
            // console.log(`TTM data -> `, yh_data['ttm'])
            // console.log('history:', yh_data['hist'])
            return yh_data
        }
    },
    async created() {
        for (let i = 0; i < 12; i++) {
            this.rvG[i] = 0
            this.opM[i] = 0
            this.pfM[i] = 0
            this.shD[i] = 0
            this.ICtR[i] = 0
            this.DtIC[i] = 0
        }

        this.TreasuryYield = Number(this.TreasuryYield).toFixed(2)
        this.taxRate = Number(this.taxRate).toFixed(1)
        this.terminalEbitdaMult = Number(this.terminalEbitdaMult).toFixed(1)
        this.terminalPEMult = Number(this.terminalPEMult).toFixed(1)
        this.borrowRateVsTreasury = Number(this.borrowRateVsTreasury).toFixed(2)
        this.discountRateVsTreasury = Number(this.discountRateVsTreasury).toFixed(2)
        this.avBorrowRate = (Number(this.TreasuryYield) + Number(this.borrowRateVsTreasury)).toFixed(2)
        this.myDiscountRate = (Number(this.TreasuryYield) + Number(this.discountRateVsTreasury)).toFixed(2)
    },
    mounted() {
        window.addEventListener('resize', this.onResize)
    },
    unmounted() {
        window.removeEventListener('resize', this.onResize)
    }
}
</script>

<style scoped>
/* ensures the increment/decrement arrows always display */
/* input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  opacity: 1;
}

[data-input][type="number"]::-webkit-inner-spin-button,
[data-input][type="number"]::-webkit-outer-spin-button {
   -webkit-appearance: textfield;
    -moz-appearance: textfield;
    appearance: textfield;
} */

.main-card-form {
    background-color: #413e3c;
    padding-left: 4px;
    padding-right: 0px;
}

.main-card-results {
    background-color: #413e3c;
    padding-left: 4px;
    padding-right: 4px;
}

.inputs-card {
    background-color: #413e3c;
    padding-top: 5px;
    padding-bottom: 10px;
    padding-left: 7px;
    padding-right: 7px;
}

/* .results-card {
    background-color: #413e3c;
    padding-left: 4px;
    padding-right: 0px;
} */

.bookvalue-card {
    background-color: #413e3c;
    padding-left: 4px;
    padding-right: 0px;
    margin-top: -15px;
    /* margin-bottom: 5px; */
}

.custom-btn-form {
    background-color: #2F5C85 !important;
    color: white !important;
}

.src-btn {
    width: 100%;
    height: 37px;
    font-size: 1rem;
    margin-top: 23px;
    margin-left: -7px;
    padding-left: 0px;
    padding-right: 0px;
}

.get-btn {
    /* width: 70px !important; */
    width: 95%;
    height: 37px;
    font-size: 1rem;
    /* margin-left: -3px; */
    margin-top: 23px;
    padding-left: 4px;
    padding-right: 4px;
}

.get-btn1 {
    /* width: 70px !important; */
    width: 97%;
    height: 37px;
    font-size: 1rem;
    margin-left: -5px;
    margin-top: 23px;
    padding-left: 1px;
    padding-right: 2px;
}

.calc-btn {
    width: 100%;
    height: 37px;
    font-size: 1rem;
    margin-top: 23px;
    padding-left: 10px;
    padding-right: 10px;
}

/* ******************* BTN 3D ******************* */
.btn3d {
    border: 0;
    transition: all 40ms linear;
}

.btn3d.btn-primar {
    box-shadow: 0 0 0 1px #2f5b85a1 inset, 0 0 0 1px rgba(255, 255, 255, 0.15) inset, 0 3px 0 0 #2f5b85a1;
    background-color: #2F5C85;
}

.btn3d.btn-primar:hover {
    box-shadow: 0 0 0 1px #4a95db34 inset, 0 0 0 1px rgba(255, 255, 255, 0.15) inset, 0 3px 0 0 #2f5b85a1;
    background-color: #2F5C85;
}

/* ******************* END BTN 3D ******************* */

.data-row {
    padding: 2px;
    margin: 2px 2px;
}

.input-row {
    background-color: #272522;
    font-weight: bold;
    margin-top: 18px;
    margin-left: 2px;
    margin-right: 2px;
    padding-top: 2px;
    padding-bottom: 0px;
    padding-left: 5px;
    padding-right: 5px;
}

.ticker-row {
    background-color: #272522;
    font-weight: bold;
    margin-top: 18px;
    /* margin-bottom: 2px; */
    margin-left: 2px;
    margin-right: 2px;
    padding-top: 2px;
    padding-bottom: 0px;
    padding-left: 5px;
    padding-right: 5px;
}

.top-ticker-row {
    background-color: #272522;
    /* font-weight: bold; */
    margin-top: 18px;
    /* margin-bottom: 2px; */
    margin-left: 2px;
    margin-right: 2px;
    padding-top: 2px;
    padding-bottom: 0px;
    padding-left: 5px;
    padding-right: 5px;
}

.currency-row {
    background-color: #272522;
    margin-left: 2px;
    margin-right: 2px;
    padding-top: 2px;
    padding-bottom: 0px;
    padding-left: 5px;
    padding-right: 5px;
}

.input-row {
    background-color: #272522;
    font-weight: bold;
    margin-top: 18px;
    margin-bottom: 2px;
    margin-left: 2px;
    margin-right: 2px;
    padding-top: 2px;
    padding-bottom: 0px;
    padding-left: 7px;
    padding-right: 7px;
}

.average-row {
    margin-top: 5px;
    padding-left: 0px;
}

.last-price-row {
    background-color: #272522;
    font-weight: bold;
    margin-top: 2px;
    margin-left: 2px;
    margin-right: 2px;
    padding-top: 2px;
    padding-bottom: 0px;
    padding-left: 5px;
    padding-right: 5px;
    margin-bottom: -5px;
}

.col-item {
    padding-left: 0px;
    padding-right: 0px;
}

.col-data {
    text-align: center;
    padding-left: 0px;
    padding-right: 0px;
}

.col-curr {
    text-align: start;
    padding-left: 0px;
    padding-right: 0px;
}

.col-name {
    text-align: end;
    padding-left: 0px;
    padding-right: 0px;
}

.sep-line {
    background-color: #272522;
    width: 100%;
    padding-top: 6px;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
    margin-right: 0px;
    padding-left: 0px;
    padding-right: 0px;
}

.row-separator1 {
    margin-top: 13px;
    margin-bottom: -15px;

}

.row-separator2 {
    /* margin-top: 15px; */
    margin-bottom: -6px;

}

.sep-line-light {
    background-color: #b2b2b9;
    width: 100%;
    padding-top: 3px;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
    margin-right: 0px;
    padding-left: 0px;
    padding-right: 0px;
}

.col-divider-line1 {
    align-content: center;
    /* margin-left: 5px; */
    margin-top: 10px;
}

.col-divider-line2 {
    align-content: center;
    margin-top: 10px;
}

.col-divider-text {
    align-content: center;
    text-align: center;
    color: #b2b2b9;

}

.search-toggle {
    margin-top: 10px;
    margin-bottom: -12px;
    padding-left: 2px;
    color: #b2b2b9;
}

.comment-loading {
    margin-top: 7px;
    margin-bottom: -13px;
    padding-left: 2px;
    /* color: #e6dddc; */
    color: #faca64e5;
}

.comment-col {
    padding-top: 12px;
}

.col-history {
    text-align: center;
    font-size: 0.9rem;
}

.col-history2 {
    text-align: center;
    font-size: 0.9rem;
}

.col-avg {
    font-weight: bold;
}

.col-avg2 {
    font-weight: bold;
    font-size: 1rem;
    margin-top: -1px;
}

.sep-line-history {
    /* background-color: #62605E !important; */
    background-color: #92908E !important;
    padding-top: 1px !important;
    margin-top: 3px;
    margin-bottom: 3px;
    width: 100%;
    margin-left: 0px;
    box-shadow: 0px 1px #272522;
}

.sep-line-results {
    /* background-color: #62605E !important; */
    background-color: #92908E !important;
    padding-top: 1px !important;
    margin-top: 1px;
    margin-bottom: 1px;
    width: 99%;
    margin-left: 2px;
    box-shadow: 0px 1px #272522;
}

/* .sep-line-results {
    background-color: #272522;
    width: 100%;
    padding-top: 12px;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
    margin-right: 0px;
    padding-left: 0px;
    padding-right: 0px;
} */
</style><style>
.inp-box {
    height: 24px;
    text-align: center;
}

.fin-box {
    width: 90px !important;
    height: 24px;
    text-align: center;
    padding-left: 0px !important;
    padding-right: 0px !important;
    color: #000 !important;
}

.search-warn {
    margin-top: 7px;
    /* color: #faca64; */
    color: #faca64e5;
}

.optional-link1 {
    margin-top: -1px !important;
    font-weight: normal !important;
    color: #828487 !important;
}

.optional-link2 {
    margin-top: -1px !important;
    font-weight: normal !important;
    color: #828487 !important;
}

.netdebt-card {
    margin-bottom: 11px;
}
</style>
